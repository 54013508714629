import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import router from '../routes'
import '../assets/style.css'
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n/config'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../config/theme'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { setCookie } from 'typescript-cookie'

const Default: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const currentUrl = window.location.pathname
    const segments = currentUrl.split('/')
    const locale = segments[0] !== '' ? segments[0] : segments[1]

    if (locale !== 'en' && locale !== 'ka') {
      navigate('/en')
      void i18n.changeLanguage('en')
      setCookie('locale', 'en')
    } else {
      void i18n.changeLanguage(locale)
      setCookie('locale', locale)
    }
    window.scrollTo(0, 0)
  }, [pathname])

  return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <I18nextProvider i18n={i18n}>
                <Header />
                <Routes>
                     <Route path="/:locale">
                        {router.map(route => (
                            <Route
                                key={route.name}
                                path={route.path}
                                element={route.element}
                            />
                        ))}
                     </Route>
                    <Route
                        path=""
                        element={<Navigate to={`/${i18n.language}`} replace />}
                    />
                </Routes>
                <Footer />
            </I18nextProvider>
        </ThemeProvider>
  )
}

export default Default
