import React from 'react'

const ArrowDownGradientIcon: React.FC = () => {
  return (
      <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon" width="16.73" height="10.39" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L8.3635 8.391L14.727 2" stroke="url(#paint0_linear_607_6428)" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
          <defs>
              <linearGradient id="paint0_linear_607_6428" x1="0.701327" y1="5.1955" x2="15.0609" y2="5.1955" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FFDBDE"/>
                  <stop offset="0.546875" stopColor="#F993E6"/>
                  <stop offset="1" stopColor="#BAB4FF"/>
              </linearGradient>
          </defs>
      </svg>
  )
}

export default ArrowDownGradientIcon
