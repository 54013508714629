/**
 *
 * @param career
 */
export const getCareerId = (career: any): string => career?.id

/**
 *
 * @param career
 */
export const getCareerTitle = (career: any): string => career?.title
