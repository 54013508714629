export const Seo = {
  initSeoData: (pageInfo: any): any => {
    if (pageInfo === undefined) {
      return
    }

    Object.keys(pageInfo).map((key: any): any => {
      const value = String(pageInfo[key])

      switch (key) {
        case 'title':
          document.title = `${value}`
          break
        case 'description':
          // @ts-expect-error
          document.getElementsByTagName('meta').description.content = value
          break
        case 'keywords':
          // @ts-expect-error
          document.getElementsByTagName('meta').keywords.content = value
          break
        case 'og_title':
          document.querySelector('meta[property="og:title"]')?.setAttribute('content', value)
          break
        case 'og_description':
          document.querySelector('meta[property="og:description"]')?.setAttribute('content', value)
          break
        case 'image':
          document.querySelector('meta[property="og:image"]')?.setAttribute('content', value)
          break
        case 'locale':
          // document.documentElement.setAttribute('lang', value)
          break
      }

      return key
    })

    document.querySelector('meta[property="og:url"]')?.setAttribute('content', window.location.href)
  }
}
