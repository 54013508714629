import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Modal,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  IconButton
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { styled } from '@mui/material/styles'
import ArrowDownGradientIcon from '../../components/Icon/ArrowDownGradientIcon'
import CircleGradientIcon from '../../components/Icon/CircleGradientIcon'
import CircleCheckedGradientIcon from '../../components/Icon/CircleCheckedGradientIcon'
import { Page } from '../../services/api/page'
import CloseIcon from '../Icon/CloseIcon'
import SimpleBar from 'simplebar-react'
// import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { sizePro } from '../../helpers/PageHelper'
import { setCookie } from 'typescript-cookie'
import { setTokenOutside } from '../../services/api/api'

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    fontSize: sizePro(15),
    padding: '0 6px',
    transform: `translate(${sizePro(23)}, ${sizePro(22)}) scale(1)`,

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      transform: 'translate(18px, 20px) scale(1)'
    }
  },
  '& label.Mui-focused': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(24)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& label.MuiFormLabel-filled': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 0%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(24)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& .MuiOutlinedInput-root': {
    border: 'double 1px transparent',
    backgroundImage:
      'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: `${sizePro(16.5)} ${sizePro(14)}`,
    height: sizePro(64),
    borderRadius: sizePro(20),

    [theme.breakpoints.down('md')]: {
      height: '60px',
      fontSize: '12px',
      padding: '0 10px'
    },

    '& .MuiInputBase-input': {
      fontWeight: 'bold'
    }
  }
}))

const CssSelectControl = styled(FormControl)(({ theme }) => ({
  '&': {
    border: 'double 1px transparent',
    backgroundImage:
      'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: `0 ${sizePro(14)}`,
    height: sizePro(64),
    borderRadius: '20px',

    [theme.breakpoints.down('lg')]: {
      height: '58px'
    }
  },

  '& label': {
    fontSize: sizePro(15),
    color: 'rgba(255, 255, 255, 0.6)',
    padding: `0 ${sizePro(6)}`,
    transform: `translate(${23}, ${22}) scale(1)`,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      transform: 'translate(20px, 19px) scale(1)'
    }
  },

  '& label.Mui-error': {
    fontSize: sizePro(15),
    color: 'rgba(255, 255, 255, 0.6)',
    padding: `0 ${sizePro(6)}`,
    transform: { xs: `translate(${sizePro(24)}, ${sizePro(18)}) scale(1)` },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& label.Mui-focused': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(24)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: sizePro(20),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& label.MuiFormLabel-filled': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(24)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: sizePro(20),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& .MuiInputBase-root': {
    fontSize: sizePro(15),

    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker label': {
    fontSize: sizePro(15),
    color: 'rgba(255, 255, 255, 0.6)',
    padding: `0 ${6}`,
    transform: `translate(${10}, ${22}) scale(1)`,

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      transform: 'translate(6px, -10px) scale(1)'
    }
  },

  '&.datepicker label.Mui-error': {
    fontSize: sizePro(15),
    color: 'rgba(255, 255, 255, 0.6)',
    padding: 0,
    transform: { xs: `translate(${10}, ${26}) scale(1)` },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      transform: 'translate(12px, 18px) scale(1)'
    }
  },

  '&.datepicker label.Mui-focused': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(10)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: sizePro(20),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker label.MuiFormLabel-filled': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(10)}, -9px) scale(0.75)`,
    borderRadius: sizePro(20),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker .MuiInputBase-input': {
    padding: `0 ${sizePro(14)}`
  }
}))

const CssControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&': {
    color: 'white',
    marginLeft: '-9px',

    '& svg': {
      [theme.breakpoints.down('lg')]: {
        height: '24px',
        width: '24px'
      }
    },

    '& .MuiTypography-root': {
      fontSize: i18n.language !== 'en' ? '14px' : sizePro('16'),

      [theme.breakpoints.down('lg')]: {
        fontSize: '12px'
      }
    }
  }
}))

interface ModalProperties {
  isOpen: any
  onClose: any
  onSignIn: any
  onSignUpOk: any
}

interface State {
  phone_number: string
  birth_date: string
  city_id: any
  country_id: any
  email: string
  full_name: string
  gender_id: any
  id_number: any
  password: string
  password_confirmation: string
  terms_conditions: any
  phone_prefix: any
}

interface Options {
  gender: any
  countries: any
  cities: any
}

const hasWindow = typeof window !== 'undefined'
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getWindowDimensions = () => {
  const width = hasWindow ? window.innerWidth : 0
  const height = hasWindow ? window.innerHeight : 0
  return {
    width,
    height
  }
}

const SignUpModal: React.FC<ModalProperties> = ({
  isOpen,
  onSignIn,
  onSignUpOk,
  onClose
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(isOpen)
  const [calendarOpen, setCalendarOpen] = React.useState(false)
  const [errors, setErrors] = React.useState<State>()
  const [fullNameError, setFullNameError] = React.useState(false)
  const [options, setOptions] = React.useState<Options>()
  const [values, setValues] = React.useState<State>({
    phone_number: '',
    birth_date: '',
    city_id: '',
    country_id: 1,
    email: '',
    full_name: '',
    gender_id: '',
    id_number: '',
    password: '',
    password_confirmation: '',
    terms_conditions: false,
    phone_prefix: '995'
  })
  const [birthDate, setBirthDate] = React.useState<any>('')
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  )

  // const mediaQuery = useMediaQuery((theme: any) =>
  //   theme.breakpoints.between('xs', 'lg')
  // )

  const handleChange = (prop: keyof State) => (event: any) => {
    if (/^[A-Za-z0-9\s,.@_-]+$/.test(event.target.value)) {
      setValues({ ...values, [prop]: event.target.value })
    } else if (event.target.value === '') {
      setValues({ ...values, [prop]: '' })
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setOpen(false)
    onClose(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSignIn = () => {
    Page.signIn(values)
      .then((data) => {
        if (data?.data?.access_token as boolean) {
          setTokenOutside(data?.data?.access_token)
          setCookie('token', data?.data?.access_token)
          handleClose()
        }
      })
      .catch((err) => {
        setErrors(err?.response?.data)
        console.log(errors)
      })
    return () => {}
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSignUp = () => {
    const splitedName = values?.full_name?.split(' ')
    setFullNameError(false)

    if (splitedName?.length < 2) {
      setFullNameError(true)
    }

    Page.signUp({
      ...values,
      birth_date: birthDate,
      first_name: splitedName?.[0],
      last_name: splitedName?.[1]
    })
      .then(async (data) => {
        if (data?.code === 200) {
          await handleSignIn()
          handleClose()
          onSignUpOk()
        }
      })
      .catch((err) => {
        setErrors(err?.response?.data?.errors)
      })
    return () => {}
  }

  useEffect(() => {
    if (open as boolean) {
      Page.getSignUpData()
        .then((data) => {
          console.log(data)
          setOptions(data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [open])

  useEffect(() => {
    (document.querySelector('#registerDatePicker') as HTMLInputElement)?.setAttribute('disabled', 'disabled')
    setOpen(isOpen)
  })

  useEffect(() => {
    if (hasWindow) {
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="register-modal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', md: '50%' },
          bottom: { xs: '0', md: 'initial' },
          left: { xs: '0', md: '50%' },
          transform: { xs: 'initial', md: 'translate(-50%, -50%)' },
          width: { xs: '100%', md: sizePro(735) },
          backgroundColor: 'rgba(40, 41, 55, 1)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', md: '20px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '62px', md: '97px' },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: '900',
              fontSize: { xs: '18px', md: '24px' }
            }}
          >
            {t('register.title')}
          </Typography>

          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              top: '27px',
              right: '27px',
              display: { xs: 'none', md: 'block' }
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            padding: {
              xs: '40px 16px 20px',
              md: `${sizePro(41)} ${sizePro(57)} ${sizePro(20)}`
            },
            overflow: { xs: 'scroll', md: 'initial' },
            height: { xs: 'calc(100vh - 210px)', md: 'auto' }
          }}
        >
          <SimpleBar
            style={{
              maxHeight: windowDimensions?.height < 810 ? '400px' : '100%',
              paddingTop: '5px'
            }}
            autoHide={false}
          >
            <Grid container spacing={{ xs: '6px', md: '16px' }}>
              <Grid item xs={12} md={6}>
                <CssTextField
                  label={t('register.email')}
                  value={values.email}
                  onChange={handleChange('email')}
                  fullWidth
                  id="custom-css-outlined-input"
                />
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <CssTextField
                  label={t('register.idNumber')}
                  value={values.id_number}
                  onChange={handleChange('id_number')}
                  fullWidth
                  id="custom-css-outlined-input"
                />
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.id_number}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} paddingTop={'10px !important'}>
                <CssTextField
                  label={t('register.mobileNumber')}
                  value={values.phone_number}
                  onChange={handleChange('phone_number')}
                  fullWidth
                  id="custom-css-outlined-input"
                />
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.phone_number}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} paddingTop={'10px !important'}>
                <CssTextField
                  label={t('register.firstName')}
                  value={values.full_name}
                  onChange={handleChange('full_name')}
                  fullWidth
                  id="custom-css-outlined-input"
                />
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {fullNameError && t('register.fullName.error')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} paddingTop={'10px !important'}>
                <CssSelectControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t('register.gender')}
                  </InputLabel>
                  <Select
                    value={values.gender_id}
                    fullWidth
                    IconComponent={ArrowDownGradientIcon}
                    id="gender"
                    onChange={handleChange('gender_id')}
                  >
                    {options?.gender?.map((item: any) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </CssSelectControl>
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.gender_id}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} paddingTop={'10px !important'}>
                <CssSelectControl className="datepicker" fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label={t('register.birthDate')}
                      value={values.birth_date}
                      inputFormat="DD/MM/YYYY"
                      disableOpenPicker={true}
                      open={calendarOpen}
                      autoFocus={false}
                      onClose={() => {
                        (document.querySelector('#registerDatePicker') as HTMLElement)?.blur()
                        setCalendarOpen(false)
                      }}
                      onChange={(newValue: any) => {
                        setValues({ ...values, birth_date: newValue })
                        setBirthDate(newValue.format('DD/MM/YYYY'))
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          sx={{ height: { xs: '58px', md: '64px' } }}
                          {...params}
                          id="registerDatePicker"
                          InputProps={{
                            onClick: (e) => {
                              setCalendarOpen(true)
                            }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </CssSelectControl>
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.birth_date}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} paddingTop={'10px !important'}>
                <CssTextField
                  label={t('register.password')}
                  value={values.password}
                  onChange={handleChange('password')}
                  type="password"
                  fullWidth
                  id="custom-css-outlined-input"
                />
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.password}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} paddingTop={'10px !important'}>
                <CssTextField
                  label={t('register.repeatPassword')}
                  value={values.password_confirmation}
                  onChange={handleChange('password_confirmation')}
                  type="password"
                  fullWidth
                  id="custom-css-outlined-input"
                />
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.password_confirmation}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} paddingTop={'10px !important'}>
                <CssSelectControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t('register.country')}
                  </InputLabel>
                  <Select
                    value={values.country_id}
                    fullWidth
                    IconComponent={ArrowDownGradientIcon}
                    id="gender"
                    onChange={handleChange('country_id')}
                  >
                    {options?.countries?.map((item: any) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </CssSelectControl>
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.country_id}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} paddingTop={'10px !important'}>
                <CssSelectControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t('register.city')}
                  </InputLabel>
                  <Select
                    value={values.city_id}
                    fullWidth
                    IconComponent={ArrowDownGradientIcon}
                    id="gender"
                    onChange={handleChange('city_id')}
                  >
                    {options?.cities?.map((item: any) => (
                      <MenuItem
                        value={item.value}
                        key={item.value}
                        sx={{
                          transform: 'scale(0.85)'
                        }}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </CssSelectControl>
                <Typography
                  sx={{
                    color: 'rgba(235, 30, 46, 1)',
                    paddingTop: '10px',
                    fontSize: '13px',
                    fontWeight: 'bold'
                  }}
                >
                  {errors?.city_id}
                </Typography>
              </Grid>
            </Grid>

            <CssControlLabel
              control={
                <Checkbox
                  icon={<CircleGradientIcon />}
                  checkedIcon={<CircleCheckedGradientIcon />}
                  onChange={(newValue: any) => {
                    setValues({
                      ...values,
                      terms_conditions: newValue.target.checked
                    })
                  }}
                />
              }
              label={t('register.checkbox')}
              sx={{ marginTop: { xs: '8px', md: '22px' } }}
            />

            <Typography
              sx={{
                color: 'rgba(235, 30, 46, 1)',
                paddingTop: '10px',
                fontSize: '13px',
                fontWeight: 'bold'
              }}
            >
              {errors?.terms_conditions}
            </Typography>

            <Button
              variant="contained"
              fullWidth
              onClick={handleSignUp}
              sx={{
                height: { xs: '58px', md: sizePro(74) },
                marginTop: { xs: '8px', md: '20px' },
                fontWeight: 'bold',
                borderRadius: '20px'
              }}
            >
              {t('register.buttonTitle')}
            </Button>

            <Typography
              sx={{
                color: 'white',
                fontWeight: '700',
                fontSize: { xs: '14px', md: sizePro(16) },
                textAlign: 'center',
                marginTop: '26px',
                marginBottom: '14px'
              }}
            >
              {t('register.footerText')}{' '}
              <Button
                sx={{
                  padding: '0',
                  color: 'rgba(255, 164, 171, 1)',
                  fontSize: { xs: '14px', md: sizePro(16) },
                  fontWeight: '700'
                }}
                onClick={onSignIn}
              >
                {t('login.title')}
              </Button>
            </Typography>
          </SimpleBar>
        </Box>
      </Box>
    </Modal>
  )
}

export default SignUpModal
