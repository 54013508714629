import React from 'react'

interface IconProperties {
  width: number
  height: number
}

const BranchGradientIcon: React.FC<IconProperties> = ({ width, height }) => {
  return (
      <svg width={width} height={height} viewBox="0 0 51 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.8" d="M4.59 34.7735C4.34239 34.7583 4.09513 34.6323 3.90504 34.4456C3.70208 34.2463 3.57 33.9834 3.57 33.725V19.15C3.57 18.8893 3.70406 18.5559 3.90974 18.2866C4.11636 18.016 4.38343 17.825 4.64 17.825H16.63V34.5H4.64H4.59V34.55V34.7735ZM50.05 27.675V25.2V19.15C50.05 17.1965 48.3468 15.525 46.36 15.525H45.85V13.925C45.85 10.5965 43.0268 7.825 39.64 7.825H36.8736C35.1749 3.69551 30.6842 0.95 25.64 0.95C20.5947 0.95 16.1059 3.6962 14.1294 7.55H11.36C7.97324 7.55 5.15 10.3215 5.15 13.65V15.525H4.64C2.65324 15.525 0.95 17.1965 0.95 19.15V33.45C0.95 35.4035 2.65324 37.075 4.64 37.075H5.15V38.95C5.15 42.2785 7.97324 45.05 11.36 45.05H39.92C43.3068 45.05 46.13 42.2785 46.13 38.95V37.075H46.64C47.629 37.075 48.4816 36.7975 49.0877 36.2722C49.6948 35.7461 50.05 34.9755 50.05 34V27.675ZM25.64 3.525C29.2324 3.525 32.5459 5.1383 34.2303 7.825H17.0497C18.7341 5.1383 22.0476 3.525 25.64 3.525ZM7.77 13.65C7.77 11.7535 9.42676 10.125 11.36 10.125H39.92C41.8532 10.125 43.51 11.7535 43.51 13.65V15.525H7.77V13.65ZM32.03 18.1V34.775H18.97V18.1H32.03ZM11.36 42.75C9.42676 42.75 7.77 41.1215 7.77 39.225V37.35H43.51V39.225C43.51 41.1215 41.8532 42.75 39.92 42.75H11.36ZM47.71 33.725C47.71 33.9857 47.5759 34.3191 47.3703 34.5884C47.1636 34.859 46.8966 35.05 46.64 35.05H34.65V18.1H46.64C47.1662 18.1 47.71 18.621 47.71 19.425V33.725Z" fill="url(#paint0_linear_1978_11601)" stroke="url(#paint1_linear_1978_11601)" strokeWidth="0.1"/>
          <defs>
              <linearGradient id="paint0_linear_1978_11601" x1="-4" y1="23" x2="51.2857" y2="23" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#6DFFE7"/>
                  <stop offset="0.546875" stopColor="#6DFFE7"/>
                  <stop offset="1" stopColor="#55BEC0"/>
              </linearGradient>
              <linearGradient id="paint1_linear_1978_11601" x1="-4" y1="23" x2="51.2857" y2="23" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#6DFFE7"/>
                  <stop offset="0.546875" stopColor="#6DFFE7"/>
                  <stop offset="1" stopColor="#55BEC0"/>
              </linearGradient>
          </defs>
      </svg>
  )
}

export default BranchGradientIcon
