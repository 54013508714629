import React, { useEffect, useState } from 'react'
import {
  Container,
  Box,
  Typography,
  Grid,
  CardMedia,
  CardContent,
  Card,
  Button,
  CardActions
} from '@mui/material'
import { Page } from '../services/api/page'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Swiper, SwiperSlide } from 'swiper/react'
import SkeletonLoad from '../components/SkeletonLoad/SkeletonLoad'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'

interface NewsItem {
  id: number
  thumbnail_image_src: string
  title: string
  short_description: string
  slug: string
}

const NewsListView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState<any[]>([])
  const [latest, setLatest] = useState<NewsItem>({
    id: 0,
    short_description: '',
    slug: '',
    thumbnail_image_src: '',
    title: ''
  })
  const [last, setLast] = useState<NewsItem>({
    id: 0,
    short_description: '',
    slug: '',
    thumbnail_image_src: '',
    title: ''
  })
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [isLastSlide, setIsLastSlide] = useState<any>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/news-bg-xs.jpg'
      : mediaQuerySmall
        ? '/static/news-bg-middle.jpg'
        : '/static/blog-bg.jpg'
  )

  useEffect(() => {
    Page.getNewsPage(5)
      .then((data) => {
        setLatest(data?.data?.[0])
        setLast(data?.data?.[4])
        setPage(data?.data?.filter((d: any, i: any) => i > 0 && i < 4))
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])

  return (
    <Box
      sx={{
        paddingTop: { xs: '0', md: '102px' },
        paddingBottom: { xs: '50px', md: '0' }
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '74px'
        }}
      >
        <Typography color="white" fontWeight="bold">
            {t('news.title')}
        </Typography>
      </Box>
        <Container
            maxWidth={false}
            sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '0', md: '40px', lg: 'initial' } }}
            disableGutters={true}
        >
        <Box>
          <Typography
            gutterBottom
            sx={{
              display: { xs: 'none', md: 'inline-block' },
              fontSize: '53px',
              fontWeight: '800',
              backgroundImage: `
                            linear-gradient(90deg, #6DFFE7 -10.2%, #55BEC0 51.5%, #55BEC0 102.62%),
                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                            `,
              backgroundClip: 'text',
              color: 'transparent',
              marginBottom: { md: '30px', lg: '50px' }
            }}
          >
              {t('news.title')}
          </Typography>
        </Box>

        {matches && (
          <>
            <Box marginBottom="40px" paddingX="16px">
              <NavLink to={`/${i18n.language}/blog/${latest.slug}`}>
                <Card
                  sx={{
                    background: 'transparent',
                    maxWidth: '100%',
                    display: { xs: 'block', md: 'flex' },
                    marginBottom: { xs: 0, md: '120px' }
                  }}
                >
                   {loading
                     ? <Box
                  sx={{
                    width: { xs: '100%', md: 'calc(100% - 475px)' },
                    height: { xs: '150px', md: '360px' },
                    borderRadius: '20px',
                    overflow: 'hidden'
                  }}
                  >
                    <SkeletonLoad/>
                    </Box>
                     : <CardMedia
                    component="img"
                    image={latest?.thumbnail_image_src}
                    alt={latest?.title}
                    sx={{
                      width: { xs: '100%', md: 'calc(100% - 475px)' },
                      height: { xs: 'auto', lg: '360px' },
                      borderRadius: '20px',
                      backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                    }}
                  />}
                  <CardContent
                    sx={{
                      width: { xs: '100%', md: '475px' },
                      pl: { xs: '0', md: '46px' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: '#FFEAEB',
                        fontWeight: 'bold',
                        fontSize: { xs: '16px', md: '24px' }
                      }}
                    >
                      {latest?.title?.substring(0, 47)}
                    </Typography>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <Typography
                      variant="body2"
                      color="text.white"
                      sx={{ fontSize: { xs: '12px', md: '16px' }, marginTop: '-8px' }}
                      dangerouslySetInnerHTML={{
                        __html: `${latest?.short_description?.substring(
                          0,
                          171
                        )}`
                      }}
                    />

                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        width: { xs: '100%', md: '191px' },
                        height: '56px',
                        mt: { xs: '6px', md: '47px' },
                        borderRadius: '20px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          backgroundImage: ` 
                                            linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%), 
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF) 
                                          `,
                          backgroundClip: 'text',
                          color: 'transparent',
                          borderBottom: '1px solid #fcc1e1',
                          marginTop: '-4px',
                          height: '21px'
                        }}
                      >
                        {t('news.item.seeMore')}
                      </Typography>
                    </Button>
                  </CardContent>
                </Card>
              </NavLink>
            </Box>

            <Box
              paddingLeft={(isLastSlide as boolean) ? 0 : '16px'}
              paddingRight={!(isLastSlide as boolean) ? 0 : '16px'}
            >
              <Swiper
                onTransitionEnd={(swiper) => setIsLastSlide(swiper.isEnd)}
                slidesPerView={1.3}
                spaceBetween={24}
              >
                {page?.map((item: NewsItem) => (
                  <SwiperSlide key={item?.id}>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <NavLink
                      to={`/${i18n.language}/blog/${item?.slug}`}
                      key={item?.id}
                    >
                      <Card sx={{ background: 'transparent' }}>
                            {loading
                              ? <Box
                        sx={{
                          borderRadius: '20px',
                          height: { xs: '150px', md: '360px' },
                          overflow: 'hidden'
                        }}
                        >
                          <SkeletonLoad/>
                          </Box>
                              : <CardMedia
                          component="img"
                          image={item?.thumbnail_image_src}
                          alt="green iguana"
                          sx={{
                            borderRadius: '20px',
                            height: { xs: 'auto', md: '360px' },
                            backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                          }}
                        />}
                        <CardContent sx={{ px: { xs: '0', md: '18px' }, pb: 0 }}>
                          <Typography
                            component="div"
                            sx={{
                              fontSize: '18px',
                              color: '#FFEAEB',
                              fontWeight: 'bold'
                            }}
                          >
                            {item?.title?.substring(0, 20)}
                          </Typography>
                          {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                          <Typography
                            fontSize="14px"
                            color="text.white"
                            sx={{ marginTop: '14px', height: '86px', overflow: 'hidden' }}
                            dangerouslySetInnerHTML={{
                              __html: item?.short_description?.length > 0
                                ? `${item?.short_description?.substring(
                                0,
                                90
                              )}`
                                : ''
                            }}
                          />
                        </CardContent>

                        <CardActions sx={{ px: { xs: '0', md: '18px' } }}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                              width: { xs: '100%', md: 'auto' },
                              height: '56px',
                              px: '60px',
                              borderRadius: '20px'
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                backgroundImage: `
                                            linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                                backgroundClip: 'text',
                                color: 'transparent',
                                borderBottom: '1px solid #fcc1e1',
                                marginTop: '-4px',
                                height: '21px'
                              }}
                            >
                              {t('news.item.seeMore')}
                            </Typography>
                          </Button>
                        </CardActions>
                      </Card>
                    </NavLink>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>

            <Box marginTop="60px" paddingX="16px">
              <NavLink to={`/${i18n.language}/blog/${last.slug}`}>
                <Card
                  sx={{
                    background: 'transparent',
                    maxWidth: '100%',
                    display: { xs: 'block', md: 'flex' },
                    marginBottom: { xs: 0, md: '120px' }
                  }}
                >
                  {loading
                    ? <Box
                        sx={{
                          width: { xs: '100%', md: 'calc(100% - 475px)' },
                          height: { xs: '150px', md: '360px' },
                          borderRadius: '20px',
                          overflow: 'hidden'
                        }}
                        >
                          <SkeletonLoad/>
                          </Box>
                    : <CardMedia
                              component="img"
                              image={last?.thumbnail_image_src}
                              alt="green iguana"
                              sx={{
                                width: { xs: '100%', md: 'calc(100% - 475px)' },
                                height: { xs: 'auto', md: '360px' },
                                borderRadius: '20px',
                                backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                              }}
                            />}
                  <CardContent
                    sx={{
                      width: { xs: '100%', md: '475px' },
                      pl: { xs: '0', md: '46px' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: '#FFEAEB',
                        fontWeight: 'bold',
                        fontSize: { xs: '16px', md: '24px' }
                      }}
                    >
                      {last?.title?.substring(0, 47)}
                    </Typography>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <Typography
                      variant="body2"
                      color="text.white"
                      sx={{ fontSize: { xs: '12px', md: '16px' }, marginTop: '-8px' }}
                      dangerouslySetInnerHTML={{
                        __html: `${last?.short_description?.substring(
                          0,
                          171
                        )}`
                      }}
                    />

                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        width: { xs: '100%', md: '191px' },
                        height: '56px',
                        mt: { xs: '6px', md: '47px' },
                        borderRadius: '20px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          backgroundImage: ` 
                                            linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%), 
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF) 
                                          `,
                          backgroundClip: 'text',
                          color: 'transparent',
                          borderBottom: '1px solid #fcc1e1',
                          marginTop: '-4px',
                          height: '21px'
                        }}
                      >
                        {t('news.item.seeMore')}
                      </Typography>
                    </Button>
                  </CardContent>
                </Card>
              </NavLink>
            </Box>
          </>
        )}

        <Grid
          container
          spacing={3}
          sx={{
            marginBottom: '0',
            display: { xs: 'none', md: 'flex' }
          }}
        >
          {Boolean(latest?.title) && (
            <Grid item xs={12}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              <NavLink to={`/${i18n.language}/blog/${latest?.slug}`}>
                <Card
                  sx={{
                    background: 'transparent',
                    maxWidth: '100%',
                    display: { xs: 'block', md: 'flex' },
                    marginBottom: { xs: 0, md: '60px' },
                    overflow: 'initial'
                  }}
                >
                  {loading
                    ? <Box
                        sx={{
                          width: { xs: '100%', md: 'calc(100% - 475px)' },
                          height: { xs: '200px', md: '320px' },
                          borderRadius: '50px',
                          overflow: 'hidden'
                        }}
                        >
                          <SkeletonLoad/>
                          </Box>
                    : <CardMedia
                    component="img"
                    image={latest?.thumbnail_image_src}
                    alt={latest?.title}
                    sx={{
                      width: { xs: '100%', md: 'calc(100% - 475px)' },
                      height: { xs: 'auto', md: '320px' },
                      borderRadius: '50px',
                      boxShadow: { md: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)' },
                      backdropFilter: 'blur(44px)',
                      backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                    }}
                  />}
                  <CardContent
                    sx={{
                      width: { xs: '100%', md: '475px' },
                      pl: { xs: '0', md: '46px' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: '#FFEAEB',
                        fontWeight: 'bold',
                        fontSize: { xs: '16px', md: '24px' }
                      }}
                    >
                      {latest?.title?.substring(0, 47)}
                    </Typography>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <Typography
                      variant="body2"
                      color="text.white"
                      sx={{ fontSize: { xs: '12px', md: '14px', marginTop: '-8px' } }}
                      dangerouslySetInnerHTML={{
                        __html: `${latest?.short_description?.substring(
                          0,
                          171
                        )}`
                      }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        width: { xs: '100%', md: '191px' },
                        height: '56px',
                        mt: { xs: '20px', md: '6px' },
                        borderRadius: '20px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          backgroundImage: ` 
                                            linear-gradient(90deg, #FFFFFF -10.2%, #FFFFFF 51.5%, #FFFFFF 102.62%), 
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF) 
                                          `,
                          backgroundClip: 'text',
                          color: 'transparent',
                          borderBottom: '1px solid #FFFFFF',
                          marginTop: '-4px',
                          height: '21px'
                        }}
                      >
                        {t('news.item.seeMore')}
                      </Typography>
                    </Button>
                  </CardContent>
                </Card>
              </NavLink>
            </Grid>
          )}
          {page?.map((item: NewsItem) => (
            <Grid item xs={12} md={4} key={item?.id}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              <NavLink
                to={`/${i18n.language}/blog/${item?.slug}`}
                key={item?.id}
              >
                <Card sx={{ background: 'transparent', overflow: 'initial' }}>
                      {loading
                        ? <Box
                            sx={{
                              borderRadius: '50px',
                              height: { xs: '200px', md: '280px' },
                              overflow: 'hidden'
                            }}
                            >
                              <SkeletonLoad/>
                              </Box>
                        : <CardMedia
                        component="img"
                        image={item?.thumbnail_image_src}
                        alt="green iguana"
                        sx={{
                          borderRadius: '50px',
                          height: { xs: 'auto', md: '280px' },
                          backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                        }}
                      />}
                  <CardContent sx={{ px: { xs: '0', md: '18px' }, pb: { md: 0 } }}>
                    <Typography
                      component="div"
                      sx={{
                        fontSize: '18px',
                        color: '#FFEAEB',
                        fontWeight: 'bold'
                      }}
                    >
                      {item?.title?.substring(0, 34)}
                    </Typography>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                      {((item?.short_description) !== null)
                        ? (
                          <Typography
                              fontSize="14px"
                              color="text.white"
                              sx={{ marginTop: { md: '-10px' }, height: { md: '72px', lg: '54px' } }}
                              dangerouslySetInnerHTML={{
                                __html: `${item?.short_description?.substring(
                                      0,
                                      110
                                  )}`
                              }}
                          />
                          )
                        : (
                          <Typography
                              fontSize="14px"
                              color="text.white"
                              sx={{ marginTop: { md: '-10px' }, height: { md: '88px', lg: i18n.language === 'en' ? '68px' : '90px' } }}
                          />
                          )}
                  </CardContent>

                  <CardActions sx={{ px: { xs: '0', md: '18px' } }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        width: { xs: '100%', md: 'auto' },
                        height: '56px',
                        px: '60px',
                        borderRadius: '20px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          backgroundImage: `
                                            linear-gradient(90deg, #FFFFFF -10.2%, #FFFFFF 51.5%, #FFFFFF 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                          backgroundClip: 'text',
                          color: 'transparent',
                          borderBottom: '1px solid #FFFFFF',
                          marginTop: '-4px',
                          height: '21px'
                        }}
                      >
                        {t('news.item.seeMore')}
                      </Typography>
                    </Button>
                  </CardActions>
                </Card>
              </NavLink>
            </Grid>
          ))}
          {Boolean(last?.title) && (
            <Grid item xs={12} sx={{ marginTop: '60px' }}>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              <NavLink to={`/${i18n.language}/blog/${last?.slug}`}>
                <Card
                  sx={{
                    background: 'transparent',
                    maxWidth: '100%',
                    display: { xs: 'block', md: 'flex' },
                    marginBottom: { xs: 0, md: '120px' },
                    overflow: 'initial'
                  }}
                >
                {loading
                  ? <Box
                      sx={{
                        width: { xs: '100%', md: 'calc(100% - 475px)' },
                        height: { xs: '200px', md: '320px' },
                        borderRadius: '50px',
                        overflow: 'hidden'
                      }}
                      >
                        <SkeletonLoad/>
                        </Box>
                  : <CardMedia
                  component="img"
                  image={last?.thumbnail_image_src}
                  alt="green iguana"
                  sx={{
                    width: { xs: '100%', md: 'calc(100% - 475px)' },
                    height: { xs: 'auto', md: '320px' },
                    borderRadius: '50px',
                    boxShadow: { md: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)' },
                    backdropFilter: 'blur(44px)',
                    backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                  }}
                />}
                  <CardContent
                    sx={{
                      width: { xs: '100%', md: '475px' },
                      pl: { xs: '0', md: '46px' },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: '#FFEAEB',
                        fontWeight: 'bold',
                        fontSize: { xs: '16px', md: '24px' }
                      }}
                    >
                      {last?.title?.substring(0, 47)}
                    </Typography>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <Typography
                      variant="body2"
                      color="text.white"
                      sx={{ fontSize: { xs: '12px', md: '14px', marginTop: '-8px' } }}
                      dangerouslySetInnerHTML={{
                        __html: `${last?.short_description?.substring(
                          0,
                          171
                        )}`
                      }}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        width: { xs: '100%', md: '191px' },
                        height: '56px',
                        mt: { xs: '20px', md: '6px' },
                        borderRadius: '20px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          backgroundImage: ` 
                                            linear-gradient(90deg, #FFFFFF -10.2%, #FFFFFF 51.5%, #FFFFFF 102.62%), 
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF) 
                                          `,
                          backgroundClip: 'text',
                          color: 'transparent',
                          borderBottom: '1px solid #fcc1e1',
                          marginTop: '-4px',
                          height: '21px'
                        }}
                      >
                        {t('news.item.seeMore')}
                      </Typography>
                    </Button>
                  </CardContent>
                </Card>
              </NavLink>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default NewsListView
