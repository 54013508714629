export const PromotionPageSx = {
  // backgroundImage: {
  //   xs: 'url("/static/home-bg-mobile.jpg")',
  //   sm: 'url("/static/promotions-bg.jpg")'
  // },
  // minHeight: { xs: '100vh', sm: 'initial' },
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
  paddingTop: { xs: '0', md: '102px' },
  paddingBottom: { xs: '80px', md: '120px' }
}

export const PromotionsPageHeaderSx = {
  display: { xs: 'none', md: 'block' },
  textAlign: 'center',
  padding: '26px 0 46px'
}

export const PromotionCardSx = {
  background: 'rgba(23, 24, 34, 0.3)',
  height: { xs: 'auto', md: '350px' },
  p: { xs: '16px 16px 0 16px', md: '20px 20px 0 20px' },
  borderRadius: '40px',

  '&:hover': {
    background: 'rgba(23, 24, 34, 0.35)'
  }
}

export const PromotionCardMediaSx = {
  borderRadius: '40px',
  height: { xs: 'auto', md: '200px' }
}

export const PromotionCardContentSx = {
  p: '16px 8px 0',

  '&:last-child': {
    paddingBottom: '0'
  }
}

export const PromotionCardTitleSx = {
  fontSize: '20px',
  fontWeight: 'bold',
  lineHeight: 'normal'
}

export const PromotionCardDescriptionSx = {
  fontSize: { xs: '12px', md: '14px' },
  marginTop: '-4px',
  height: '54px'
}
