import { Container, Typography, Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Page } from '../services/api/page'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ArrowRightIcon from '../components/Icon/ArrowRightIcon'

const LeaderBoardListView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const [page, setPage] = useState<any>({})
  const showStream = Number(id) === 2
  const showStream3 = Number(id) === 3
  const showStream1 = Number(id) === 1

  useEffect(() => {
    Page.getBranchSinglePage(id)
      .then((data) => {
        setPage(data?.branch)
        console.log(data?.branch)
        void Page.fetchPageInfo('leaderboard')
      })
      .catch((err) => {
        console.log(err)
      })
  }, [i18n.language])
  return (
    <Box
      sx={{
        backgroundImage: {
          xs: 'url("/static/home-bg-mobile.jpg")',
          sm: 'url("/static/bg.jpg")'
        },
        minHeight: { xs: 'auto', md: 'initial' },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: { xs: '0', md: '102px' },
        paddingBottom: { xs: '50px', md: '140px' }
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '74px'
        }}
      >
        <Typography color="white" fontWeight="bold">
          {t('stream.jackpot.title')} {page?.title}
        </Typography>
      </Box>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: { md: '1192px', lg: '1280px' },
          paddingX: { xs: '16px', md: '40px', lg: 'initial' }
        }}
        disableGutters={true}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Button href={`/${i18n.language}/streams`} variant="text" sx={{
            lineHeight: 'normal',
            marginTop: '10px',
            width: '40px',
            height: '40px',
            color: '#ffd99c',
            display: 'flex',
            alignItems: 'center',
            transform: 'rotate(180deg)',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}>
            <ArrowRightIcon width={24} height={24} />
          </Button>
          <Typography
            variant="h1"
            display="flex"
            justifyContent="center"
            sx={{
              display: { xs: 'none', md: 'block' },
              fontStyle: 'normal',
              fontSize: '48px',
              fontWeight: '750',
              textAlign: 'center',
              backgroundImage:
                'linear-gradient(90deg, #55BEC0 -34.13%, #FFFFFF 124.6%)',
              backgroundClip: 'text',
              color: 'transparent'
            }}
          >
            {t('stream.jackpot.title')} {page?.title}
          </Typography>
        </Box>

        <Box className="stream-container" sx={{ marginTop: '30px', display: showStream ? 'block' : 'none' }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: '#FFEAEB',
              fontWeight: 'bold',
              fontSize: { xs: '16px', md: '24px' },
              paddingY: '20px'
            }}
          >
              {t('stream.jackpot.egt')}
          </Typography>
          <iframe
            width="100%"
            height="506"
            src="https://webstream.betlive.com/BLC-GLDANI-JACKPOT/embed.html"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
        <Box className="stream-container" sx={{ marginTop: '30px', display: showStream ? 'block' : 'none' }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: '#FFEAEB',
              fontWeight: 'bold',
              fontSize: { xs: '16px', md: '24px' },
              paddingY: '20px'
            }}
          >
              {t('stream.jackpot.cash-connection')}
          </Typography>
          <iframe
            width="100%"
            height="506"
            src="https://webstream.betlive.com/BLC-GLDANI-JACKPOT-02/embed.html"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
        <Box className="stream-container" sx={{ marginTop: '30px', display: showStream3 ? 'block' : 'none' }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: '#FFEAEB',
              fontWeight: 'bold',
              fontSize: { xs: '16px', md: '24px' },
              paddingY: '20px'
            }}
          >
              {t('stream.jackpot.egt')}
          </Typography>
          <iframe
            width="100%"
            height="506"
            src="https://webstream.betlive.com/BLC-VARK-JACKPOT-01/embed.html"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
          <Box className="stream-container" sx={{ marginTop: '30px', display: showStream3 ? 'block' : 'none' }}>
              <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: '#FFEAEB',
                    fontWeight: 'bold',
                    fontSize: { xs: '16px', md: '24px' },
                    paddingY: '20px'
                  }}
              >
                  {t('stream.jackpot.cash-connection')}
              </Typography>
              <iframe
                  width="100%"
                  height="506"
                  src="https://webstream.betlive.com/BLC-VARK-JACKPOT-02/embed.html"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
              />
          </Box>
        <Box className="stream-container" sx={{ marginTop: '30px', display: showStream1 ? 'block' : 'none' }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: '#FFEAEB',
              fontWeight: 'bold',
              fontSize: { xs: '16px', md: '24px' },
              paddingY: '20px'
            }}
          >
              {t('stream.jackpot.egt')}
          </Typography>
          <iframe
            width="100%"
            height="506"
            src="https://webstream.betlive.com/BLC-GAG-JACKPOT-1/embed.html"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
        <Box className="stream-container" sx={{ marginTop: '30px', display: showStream1 ? 'block' : 'none' }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: '#FFEAEB',
              fontWeight: 'bold',
              fontSize: { xs: '16px', md: '24px' },
              paddingY: '20px'
            }}
          >
              {t('stream.jackpot.cash-connection')}
          </Typography>
          <iframe
            width="100%"
            height="506"
            src="https://webstream.betlive.com/BLC-GAG-JACKPOT-2/embed.html"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>

        <Box className="stream-container" sx={{ marginTop: '30px', display: showStream1 ? 'block' : 'none' }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: '#FFEAEB',
              fontWeight: 'bold',
              fontSize: { xs: '16px', md: '24px' },
              paddingY: '20px'
            }}
          >
              {t('stream.jackpot.club')}
          </Typography>
          <iframe
            width="100%"
            height="506"
            src="https://webstream.betlive.com/BLC-GAG-JACKPOT-4/embed.html"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </Container>
    </Box>
  )
}

export default LeaderBoardListView
