/**
 *
 * @param backgroundImage
 * @param backgroundSize
 * @param backgroundRepeat
 */
export const setBodyBackgroundUrl = (
  backgroundImage: string,
  backgroundSize = '100%',
  backgroundRepeat = 'no-repeat'
): void => {
  document.body.style.backgroundImage = `url(${backgroundImage})`
  document.body.style.backgroundSize = backgroundSize
  document.body.style.backgroundRepeat = backgroundRepeat
}
