import { requests } from '../api'
import { Seo } from '../../seo'

export interface PageType {
  data: object
}

export const Page = {
  getCustomer: async (): Promise<any> => {
    const response = await requests.get('customer')

    return {
      customer: response?.data?.customer
    }
  },

  updateCustomer: async (payload: any): Promise<any> => {
    const response = await requests.post('customer/profile', payload)

    return response
  },

  getProfileData: async (): Promise<any> => {
    const response = await requests.get('customer/profile-data')

    return {
      customer: response?.data?.customer,
      options: response?.data?.options
    }
  },

  getPage: async (): Promise<any> => {
    const response = await requests.get('page?modules[]=privacy-policy&page=privacy_policy')
    const page = response?.data?.items?.privacy_policy?.[0]?.fields

    Seo.initSeoData(response?.data?.page_data)

    return {
      title: page?.privacy_policy_title?.value,
      content: page?.privacy_policy_text?.value
    }
  },

  getTermsPage: async (): Promise<any> => {
    const response = await requests.get('page?modules[]=terms-conditions&page=terms_conditions')
    const page = response?.data?.items?.terms_conditions?.[0]?.fields

    Seo.initSeoData(response?.data?.page_data)

    return {
      title: page?.terms_conditions_title?.value,
      content: page?.terms?.value?.[0]?.[1]?.value
    }
  },

  getFaqPage: async (): Promise<any> => {
    const response = await requests.get('page?modules[]=faq&page=faq')

    Seo.initSeoData(response?.data?.page_data)

    return response?.data?.items?.faq
  },

  getCareerPage: async (): Promise<any> => {
    const response = await requests.get('career')

    Seo.initSeoData(response?.data?.page_data)

    return {
      items: response?.data?.items,
      content: response?.data?.content
    }
  },

  getPromotionsPage: async (): Promise<any> => {
    const response = await requests.get('promotion/offer')

    Seo.initSeoData(response?.data?.page_data)

    return response?.data?.offers
  },

  getPromotionPage: async (id: string | undefined): Promise<any> => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const response = await requests.get(`promotion/offer/${id}`)

    Seo.initSeoData(response?.data?.page_data)

    return response?.data?.offer
  },

  getNewsPage: async (items: number): Promise<any> => {
    const response = await requests.get(`blog?per_page=${items}`)

    Seo.initSeoData(response?.data?.page_data)

    return response?.data?.items
  },

  getNewsSinglePage: async (id: string | undefined): Promise<any> => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const response = await requests.get(`blog/${id}`)

    Seo.initSeoData(response?.data?.page_data)

    return response?.data?.item
  },

  getClubCardPage: async (): Promise<any> => {
    const response = await requests.get('page?modules[]=card-terms-conditions&modules[]=card-info&page=club_card')

    Seo.initSeoData(response?.data?.page_data)

    return {
      content: response?.data?.items?.card_info?.[0]?.fields?.card_info_sub_title?.value,
      terms: response?.data?.items?.card_terms_conditions?.[0].fields?.terms?.value,
      title: response?.data?.items?.card_terms_conditions?.[0].fields?.card_terms_conditions_title?.value
    }
  },

  getAboutPage: async (): Promise<any> => {
    const response = await requests.get('page?modules[]=about&modules[]=branches&modules[]=default-contact&page=about')

    Seo.initSeoData(response?.data?.page_data)

    return {
      content: response?.data?.items?.about?.[0]?.fields?.first_description?.value,
      images: response?.data?.items?.about?.[0]?.fields?.images?.value
    }
  },

  getGalleryPage: async (): Promise<any> => {
    const response = await requests.get('page?modules[]=gallery&page=gallery')

    Seo.initSeoData(response?.data?.page_data)

    return response?.data?.items?.gallery?.[0]?.fields?.images?.value
  },

  getHomePage: async (): Promise<any> => {
    const response = await requests.get('page?modules[]=home&modules[]=branches&modules[]=default-contact&page=home')

    Seo.initSeoData(response?.data?.page_data)

    return response?.data
  },

  getOffers: async (): Promise<any> => {
    const response = await requests.get('card')

    Seo.initSeoData(response?.data?.page_data)

    const activatedOffers = response?.data?.offers?.activated !== undefined ? response?.data?.offers?.activated : []
    const notActivatedOffers = (response?.data?.offers?.not_activated) !== undefined ? response?.data?.offers?.not_activated : []

    return {
      offers: [...notActivatedOffers, ...activatedOffers]
    }
  },

  activateOffer: async (offerId: any): Promise<any> => {
    const response = await requests.post('card/offer/activate', {
      offer_id: offerId
    })

    return response
  },

  getBranchListPage: async (): Promise<any> => {
    const response = await requests.get('branch')

    return response?.data
  },

  getBranchSinglePage: async (id: string | undefined): Promise<any> => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const response = await requests.get(`branch/rating?branch_id=${id}`)

    Seo.initSeoData(response?.data?.page_data)

    return {
      items: response?.data?.items,
      branch: response?.data?.branch
    }
  },

  sendPasswordResetVerify: async (data: any): Promise<any> => {
    const response = await requests.post('customer/auth/password-reset/send-verify', data)

    return response
  },

  passwordResetCheckVerifyCode: async (data: any): Promise<any> => {
    const response = await requests.post('customer/auth/password-reset/check-verify', data)

    return response
  },

  passwordResetUpdate: async (data: any): Promise<any> => {
    const response = await requests.post('customer/auth/password-reset/update', data)

    return response
  },

  signIn: async (data: any): Promise<any> => {
    const response = await requests.post('customer/auth/login', data)

    return response
  },

  signUp: async (data: any): Promise<any> => {
    const response = await requests.post('customer/auth/register', data)

    return response
  },

  verifyCode: async (data: any): Promise<any> => {
    const response = await requests.post('customer/auth/verify', data)

    return response
  },

  resendVerifyCode: async (data: any): Promise<any> => {
    const response = await requests.post('customer/auth/verify/resend', data)

    return response
  },

  getSignUpData: async (): Promise<any> => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const reponse = await requests.get('customer/auth/register-data')

    return reponse?.data?.options
  },

  makeSubscribe: async (data: any): Promise<any> => {
    const response = await requests.post('subscribe/active', data)

    return response
  },

  unSubscribe: async (data: any): Promise<any> => {
    const response = await requests.post('subscribe/disable', data)

    return response
  },

  fetchPageInfo: async (path: string): Promise<any> => {
    const response = await requests.get(`page/type?path=${path}`)

    Seo.initSeoData(response)
  },

  fetchCustomerPageInfo: async (): Promise<any> => {
    const response = await requests.get('customer/page-data')

    Seo.initSeoData(response?.data)
  },

  getClubCardData: async (): Promise<any> => {
    const response = await requests.get('card/order')

    return response?.data
  },

  orderClubCardData: async (data: any): Promise<any> => {
    const response = await requests.post('card/order', data)

    return response
  },

  applyCareer: async (data: any): Promise<any> => {
    return await requests.post('career/store', data)
  },

  getAppPage: async (): Promise<any> => {
    const response = await requests.get('page?modules[]=app')

    Seo.initSeoData(response?.data?.page_data)

    return response?.data?.items?.app
  }
}
