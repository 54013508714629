import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Divider,
  Snackbar,
  Button,
  TextField,
  MenuItem,
  IconButton
} from '@mui/material'
import { Page } from '../../services/api/page'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import EditIcon from '../../components/Icon/EditIcon'
import ProfileNav from '../../components/Profile/ProfileNav'
import useMediaQuery from '@mui/material/useMediaQuery'
import { setBodyBackgroundUrl } from '../../helpers/BodyHelper'

const CssTextField = styled(TextField)(({ theme }) => ({
  '&': {
    width: '400px',

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% + 20px)'
    }
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0'
  }
}))

const CssStyledSelect = styled(Select)(({ theme }) => ({
  '&': {
    width: '400px',

    [theme.breakpoints.down('lg')]: {
      width: '100%'
    }
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0'
  },

  '& svg': {
    display: 'none'
  }
}))

const CssStyledButton = styled(Button)(({ theme }) => ({
  '&': {
    marginLeft: 'auto',
    background: 'linear-gradient(268.86deg, #0193FD -34.73%, #00FDC0 111.59%)',
    height: '43px',

    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
      height: '36px',
      minWidth: '56px',
      padding: 0
    },

    '&:hover': {
      background:
        'linear-gradient(268.86deg, rgba(1, 147, 253, 0.8) -34.73%, rgba(0, 253, 192, 0.8) 111.59%)'
    }
  }
}))

const CssStyledSecondButton = styled(Button)(({ theme }) => ({
  '&': {
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
      height: '36px',
      minWidth: '56px',
      padding: 0
    }
  }
}))

const ProfileView: React.FC = () => {
  const { t } = useTranslation()
  const [customer, setCustomer] = useState<any>({})
  const [options, setOptions] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [customerEditData, setCustomerEditData] = useState<any>({
    password: '',
    password_confirmation: '',
    country_id: undefined,
    city_id: undefined,
    email: ''
  })
  const [snackbarItem, setSnackbarItem] = useState({
    status: false,
    type: 'success',
    message: ''
  })
  const [editModes, setEditModes] = useState<any>({
    email: false,
    password: false,
    country: false,
    city: false
  })
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert (
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  // const handleChange =
  //   (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setCustomerEditData({ ...customerEditData, [prop]: event.target.value })
  //   }

  const handleInputChange = (prop: any) => (event: any) => {
    if (/^[A-Za-z0-9\s,.@_-]+$/.test(event.target.value)) {
      setCustomerEditData({ ...customerEditData, [prop]: event.target.value })
    } else if (event.target.value === '') {
      setCustomerEditData({ ...customerEditData, [prop]: '' })
    }
  }

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/profile-bg-mobile.jpg'
      : mediaQuerySmall
        ? '/static/profile-bg-small.jpg'
        : '/static/profile-bg.jpg'
  )

  useEffect(() => {
    fetchCustomer()
    return () => {}
  }, [])

  const fetchCustomer = (): any => {
    Page.getProfileData()
      .then((data) => {
        setCustomer(data?.customer)
        setOptions(data?.options)
        setCustomerEditData(data?.customer)
        void Page.fetchCustomerPageInfo()
      })
      .catch((err) => {
        setSnackbarItem({
          status: true,
          type: 'error',
          message: err.message
        })
      })
  }

  const toggleEditMode = (inputMode: string): any => {
    // eslint-disable-next-line
    setEditModes({ inputMode, ...{ [inputMode]: !editModes[inputMode] } });
    setCustomerEditData({
      ...customerEditData,
      ...{ [inputMode]: customer[inputMode] }
    })
  }

  const updateProfile = (updateData: any): any => {
    setLoading(true)
    Page.updateCustomer({ ...customer, ...updateData })
      .then((data) => {
        fetchCustomer()
        setLoading(false)
        setSnackbarItem({
          status: true,
          type: 'success',
          message: t('account.updateSuccessfully')
        })
      })
      .catch((err) => {
        setLoading(false)
        setSnackbarItem({
          status: true,
          type: 'error',
          message: err?.response?.data?.errors?.[Object.keys(updateData)?.[0]]
        })
        setTimeout(() => {
          setSnackbarItem({
            status: false,
            message: '',
            type: 'error'
          })
        }, 5000)
      })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleCityChange = (event: any) => {
    setCustomerEditData({
      ...customerEditData,
      ...{ city_id: event.target.value }
    })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleCountryChange = (event: any) => {
    setCustomerEditData({
      ...customerEditData,
      ...{ country_id: event.target.value }
    })
  }

  return (
    <Box
        sx={{
          paddingTop: { xs: '0', md: '102px' },
          paddingBottom: { xs: '50px', md: '0' }
        }}
    >
        <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '74px'
            }}
        >
            <Typography color="white" fontWeight="bold">My Account</Typography>
        </Box>
      <Container maxWidth="xl">
        <Snackbar open={snackbarItem.status} autoHideDuration={6000}>
          <Alert
            severity={
              snackbarItem.type !== ''
                ? (snackbarItem.type.toString() as AlertColor)
                : 'success'
            }
            sx={{ width: '100%' }}
          >
            {snackbarItem?.message}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            paddingTop: '60px',
            textAlign: 'center'
          }}
        >
          <Typography
            gutterBottom
            sx={{
              display: 'inline-block',
              fontSize: '64px',
              fontWeight: 'bold',
              color: 'white'
            }}
          >
            {t('account.myAccount')}
          </Typography>
        </Box>

        <ProfileNav active="account" initialSlide={0} />

        <Box
          sx={{
            margin: { xs: '50px auto 0', md: '60px auto' },
            width: { xs: '100%', md: '957px' },
            background:
              'linear-gradient(360deg, rgba(1, 147, 253, 0.1) -16.41%, rgba(0, 253, 192, 0.1) 102.67%)',
            backdropFilter: 'blur(25px)',
            borderRadius: { xs: '20px', md: '50px' }
          }}
        >
          <nav aria-label="main mailbox folders">
            <List disablePadding>
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: { xs: '16px', md: '24px 50px' }
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: '14px',
                    color: 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  {t('profile.fullName')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'rgba(255, 255, 255, 1)'
                  }}
                >
                  {customer?.full_name}
                </Typography>
              </ListItem>
              <Divider color="#00FDC0" sx={{ opacity: '0.3' }} />
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: { xs: '16px', md: '24px 50px' }
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: '14px',
                    color: 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  {t('profile.birthDate')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'rgba(255, 255, 255, 1)'
                  }}
                >
                  {customer?.birth_date_format}
                </Typography>
              </ListItem>
              <Divider color="#00FDC0" sx={{ opacity: '0.3' }} />
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: { xs: '16px', md: '24px 50px' }
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: '14px',
                    color: 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  {t('profile.idNumber')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'rgba(255, 255, 255, 1)'
                  }}
                >
                  {customer?.id_number}
                </Typography>
              </ListItem>
              <Divider color="#00FDC0" sx={{ opacity: '0.3' }} />
              <ListItem
                sx={{
                  background: (editModes.password as boolean)
                    ? 'linear-gradient(90deg, rgba(63, 177, 157, 0.6) -5.56%, rgba(127, 168, 230, 0.6) 124.6%)'
                    : 'transparent',
                  alignItems: 'center',
                  padding: { xs: '16px', md: '24px 50px' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      display: (editModes.password as boolean)
                        ? 'none'
                        : 'block',
                      fontSize: '14px',
                      color: 'rgba(255, 255, 255, 0.6)'
                    }}
                  >
                    {t('profile.password')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: 'rgba(255, 255, 255, 1)'
                    }}
                  >
                    {!(editModes.password as boolean)
                      ? '**************'
                      : (
                      <CssTextField
                        type="password"
                        variant="outlined"
                        onChange={handleInputChange('password')}
                        placeholder={
                          !(editModes.password as boolean) ? '**************' : 'New Password'
                        }
                        value={customerEditData.password}
                        InputProps={{
                          readOnly: !(editModes.password as boolean)
                        }}
                        sx={{ input: { color: 'white' } }}
                      />
                        )}
                    {/* {customer?.email} */}
                  </Typography>
                </Box>

                {(editModes.password as boolean)
                  ? (
                    <Box sx={{ display: 'flex', marginLeft: 'auto' }}><CssStyledButton
                      variant="contained"
                      onClick={() => {
                        updateProfile({ password: customerEditData.password })
                        toggleEditMode('password')
                      }}
                      disabled={loading}
                    >
                      {t('profile.confirm')}
                    </CssStyledButton><CssStyledSecondButton
                      variant="contained"
                      sx={{ marginLeft: '8px' }}
                      onClick={() => toggleEditMode('password')}
                    >
                      {t('profile.close')}
                    </CssStyledSecondButton></Box>
                    )
                  : (
                  <IconButton
                    aria-label="delete"
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => toggleEditMode('password')}
                  >
                    <EditIcon />
                  </IconButton>
                    )}
              </ListItem>
              <Divider color="#00FDC0" sx={{ opacity: '0.3' }} />
              <ListItem
                sx={{
                  background: (editModes.email as boolean)
                    ? 'linear-gradient(90deg, rgba(63, 177, 157, 0.6) -5.56%, rgba(127, 168, 230, 0.6) 124.6%)'
                    : 'transparent',
                  alignItems: 'center',
                  padding: { xs: '16px', md: '24px 50px' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      display: (editModes.email as boolean) ? 'none' : 'block',
                      fontSize: '14px',
                      color: 'rgba(255, 255, 255, 0.6)'
                    }}
                  >
                    {t('profile.email')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: 'rgba(255, 255, 255, 1)'
                    }}
                  >
                    <CssTextField
                      id="email"
                      variant="outlined"
                      value={customerEditData.email}
                      InputProps={{
                        readOnly: !(editModes.email as boolean)
                      }}
                      onChange={handleInputChange('email')}
                    />
                    {/* {customer?.email} */}
                  </Typography>
                </Box>

                {(editModes.email as boolean)
                  ? (
                    <Box sx={{ display: 'flex', marginLeft: 'auto' }}><CssStyledButton
                      variant="contained"
                      onClick={() => {
                        updateProfile({ email: customerEditData.email })
                        toggleEditMode('email')
                      }}
                      disabled={loading}
                    >
                      {t('profile.confirm')}
                    </CssStyledButton>
                      <CssStyledSecondButton
                        variant="contained"
                        size="small"
                      sx={{ marginLeft: '8px' }}
                      onClick={() => toggleEditMode('email')}
                    >
                        {t('profile.close')}
                    </CssStyledSecondButton></Box>
                    )
                  : (
                  <IconButton
                    aria-label="delete"
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => toggleEditMode('email')}
                  >
                    <EditIcon />
                  </IconButton>
                    )}
                {/* {editModes.email === true */}
                {/*  ? ( */}
                {/*  <Container> */}
                {/*    <TextField */}
                {/*      id="email" */}
                {/*      variant="filled" */}
                {/*      value={customerEditData.email} */}
                {/*    /> */}
                {/*    <Button */}
                {/*      onClick={() => */}
                {/*        updateProfile({ email: customerEditData.email }) */}
                {/*      } */}
                {/*      disabled={loading} */}
                {/*    > */}
                {/*      {t('account.confirm')} */}
                {/*    </Button> */}
                {/*  </Container> */}
                {/*    ) */}
                {/*  : ( */}
                {/*      '' */}
                {/*    )} */}
              </ListItem>
              <Divider color="#00FDC0" sx={{ opacity: '0.3' }} />
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: { xs: '16px', md: '24px 50px' }
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: '14px',
                    color: 'rgba(255, 255, 255, 0.6)'
                  }}
                >
                  {t('profile.mobileNumber')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'rgba(255, 255, 255, 1)'
                  }}
                >
                  {customer?.phone_number}
                </Typography>
              </ListItem>
              <Divider color="#00FDC0" sx={{ opacity: '0.3' }} />
              <ListItem
                sx={{
                  background: (editModes.country_id as boolean)
                    ? 'linear-gradient(90deg, rgba(63, 177, 157, 0.6) -5.56%, rgba(127, 168, 230, 0.6) 124.6%)'
                    : 'transparent',
                  alignItems: 'center',
                  padding: { xs: '16px', md: '24px 50px' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      display: (editModes.country_id as boolean)
                        ? 'none'
                        : 'block',
                      fontSize: '14px',
                      color: 'rgba(255, 255, 255, 0.6)'
                    }}
                  >
                    {t('profile.country')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: 'rgba(255, 255, 255, 1)'
                    }}
                  >
                    {(customerEditData?.country_id as boolean) && (
                      <CssStyledSelect
                        value={customerEditData.country_id}
                        inputProps={{
                          readOnly: !(editModes.country_id as boolean)
                        }}
                        onChange={handleCountryChange}
                      >
                        {options?.countries?.map((item: any) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </CssStyledSelect>
                    )}
                  </Typography>
                </Box>

                {(editModes.country_id as boolean)
                  ? (
                    <Box sx={{ display: 'flex', marginLeft: 'auto' }}><CssStyledButton
                      variant="contained"
                      onClick={() => {
                        updateProfile({ country_id: customerEditData.country_id })
                        toggleEditMode('country_id')
                      }}
                      disabled={loading}
                    >
                      {t('profile.confirm')}
                    </CssStyledButton><CssStyledSecondButton
                      variant="contained"
                      sx={{ marginLeft: '8px' }}
                      onClick={() => toggleEditMode('country_id')}
                    >
                      {t('profile.close')}
                    </CssStyledSecondButton></Box>
                    )
                  : (
                  <IconButton
                    aria-label="delete"
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => toggleEditMode('country_id')}
                  >
                    <EditIcon />
                  </IconButton>
                    )}
              </ListItem>
              <Divider color="#00FDC0" sx={{ opacity: '0.3' }} />
              <ListItem
                sx={{
                  background: (editModes.city_id as boolean)
                    ? 'linear-gradient(90deg, rgba(63, 177, 157, 0.6) -5.56%, rgba(127, 168, 230, 0.6) 124.6%)'
                    : 'transparent',
                  alignItems: 'center',
                  padding: { xs: '16px', md: '24px 50px' },
                  borderBottomLeftRadius: '50px',
                  borderBottomRightRadius: '50px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      display: (editModes.city_id as boolean)
                        ? 'none'
                        : 'block',
                      fontSize: '14px',
                      color: 'rgba(255, 255, 255, 0.6)'
                    }}
                  >
                    {t('profile.city')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: 'rgba(255, 255, 255, 1)'
                    }}
                  >
                    {(customerEditData?.city_id as boolean) && (
                      <CssStyledSelect
                        value={customerEditData.city_id}
                        inputProps={{
                          readOnly: !(editModes.city_id as boolean)
                        }}
                        onChange={handleCityChange}
                      >
                        {options?.cities?.map((item: any) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </CssStyledSelect>
                    )}
                  </Typography>
                </Box>

                {(editModes.city_id as boolean)
                  ? (
                    <Box sx={{ display: 'flex', marginLeft: 'auto' }}><CssStyledButton
                      variant="contained"
                      onClick={() => {
                        updateProfile({ city_id: customerEditData.city_id })
                        toggleEditMode('city_id')
                      }}
                      disabled={loading}
                    >
                      {t('profile.confirm')}
                    </CssStyledButton><CssStyledSecondButton
                      variant="contained"
                      sx={{ marginLeft: '8px' }}
                      onClick={() => toggleEditMode('city_id')}
                    >
                      {t('profile.close')}
                    </CssStyledSecondButton></Box>
                    )
                  : (
                  <IconButton
                    aria-label="delete"
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => toggleEditMode('city_id')}
                  >
                    <EditIcon />
                  </IconButton>
                    )}
              </ListItem>
            </List>
          </nav>
        </Box>
      </Container>
    </Box>
  )
}

export default ProfileView
