import React, { useEffect } from 'react'
import { getCookie, removeCookie, setCookie } from 'typescript-cookie'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import {
  Container,
  AppBar,
  Toolbar,
  Box,
  Button,
  Popper,
  ClickAwayListener,
  IconButton,
  Drawer,
  Divider,
  Link, Skeleton
} from '@mui/material'
import { useListener } from 'react-bus'
import LogoIcon from '../../components/Icon/LogoIcon'
import SignInModal from '../../components/Modal/SignInModal'
import { Page } from '../../services/api/page'
import { useTranslation } from 'react-i18next'
import SignUpModal from '../Modal/SignUpModal'
import PasswordResetModal from '../Modal/PasswordResetModal'
import UserIcon from '../Icon/UserIcon'
import { styled } from '@mui/material/styles'
import BalanceCarousel from '../Carousel/Balance/BalanceCarousel'
import EnglishCircleIcon from '../Icon/EnglishCircleIcon'
import GeorgiaCircleIcon from '../Icon/GeorgiaCircleIcon'
import VerifyModal from '../Modal/VerifyModal'
import MenuIcon from '../Icon/MenuIcon'
import FacebookIcon from '../Icon/FacebookIcon'
import LinkedinIcon from '../Icon/LinkedinIcon'
import InstagramIcon from '../Icon/InstagramIcon'
import YoutubeIcon from '../Icon/YoutubeIcon'
import LanguageModal from '../Modal/LanguageModal'
import LogoKaIcon from '../Icon/LogoKaIcon'
import { SkeletonSx } from '../../config/styles/skeleton/SkeletonStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import VerifyButtonModal from '../Modal/VerifyButtonModal'

interface Config {
  promo_url: string
}

interface Customer {
  full_name: string
  first_name: string
  balances: any
  verified_at: any
  config: Config
}

const StyledBox = styled(Box)({
  '&': {
    width: '335px',
    background: 'rgba(40, 41, 55, 1)',
    boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    marginTop: '28px',
    padding: '20px',
    borderRadius: '50px',
    transform: 'scale(0.9)',

    '&::before': {
      content: '""',
      display: 'block',
      background: 'rgba(40, 41, 55, 1)',
      position: 'absolute',
      top: 0,
      left: '50%',
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      transform: 'matrix(0.72, 0.69, -0.7, 0.72, 0, 0) translateX(-50%)'
    },

    '& .list': {
      '& a': {
        '&:hover': {
          backgroundImage:
            'linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%)',
          backgroundClip: 'text',
          color: 'transparent'
        }
      }
    }
  }
})

const StyledLanguageBox = styled(Box)({
  '&': {
    width: '335px',
    border: 'double 1px transparent',
    backgroundImage:
            'linear-gradient(90deg, #2F3953 0%, #2F3953 100.14%),linear-gradient(90deg, #EB1E2E 0%, #5043DA 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    position: 'relative',
    marginTop: '0',
    padding: '20px',
    borderRadius: '50px',
    transform: 'scale(0.9)',

    '&::before': {
      content: '""',
      display: 'none',
      background: 'rgba(40, 41, 55, 1)',
      position: 'absolute',
      top: 0,
      left: '50%',
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      transform: 'matrix(0.72, 0.69, -0.7, 0.72, 0, 0) translateX(-50%)'
    },

    '& .list': {
      '& a': {
        '&:hover': {
          backgroundImage:
                        'linear-gradient(90deg, #EB1E2E  -10.2%, #EB1E2E  51.5%, #EB1E2E  102.62%)',
          backgroundClip: 'text',
          color: 'transparent'
        }
      }
    }
  }
})

const StyledButton = styled(Button)({
  '&': {
    paddingRight: '12px',

    '& .MuiButton-startIcon': {
      paddingLeft: 0
    },

    '&:hover .header-dropdown': {
      display: 'block'
    },

    '& .header-dropdown': {
      display: 'none',
      position: 'absolute',
      top: '100%',
      paddingTop: '10px',
      marginLeft: '-10px',

      '& .MuiButton-startIcon': {
        marginLeft: '-18px'
      }
    }
  }
})

const StyledMobileMenu = styled(Box)({
  '&': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '25px',

    '& a': {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
      fontSize: '14px',
      fontWeight: 'bold',

      '&.active': {
        color: '#F993E6'
      },

      '&.active svg': {
        '& linearGradient': {
          '& > *:first-child': {
            stopColor: '#FFDBDE'
          },

          '& > *:nth-child(2)': {
            stopColor: '#F993E6'
          },

          '& > *:last-child': {
            stopColor: '#BAB4FF'
          }
        }
      }
    }
  }
})

const Header: React.FC = () => {
  const location = useLocation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const matchesSmall = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorLanguageEl, setAnchorLanguageEl] =
    React.useState<null | HTMLElement>(null)
  const [stickyHeader, setStickyHeader] = React.useState(false)
  const [profilePopper, setProfilePopper] = React.useState(false)
  const [languagePopper, setLanguagePopper] = React.useState(false)
  const [signInModal, setSignInModal] = React.useState(false)
  const [signUpModal, setSignUpModal] = React.useState(false)
  const [passwordResetModal, setPasswordResetModal] = React.useState(false)
  const [verifyModal, setVerifyModal] = React.useState(false)
  const [verifyButtonModal, setVerifyButtonModal] = React.useState(false)
  const [languageModal, setLanguageModal] = React.useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [customer, setCustomer] = React.useState<Customer>()
  const loggedIn = getCookie('token') !== undefined
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const promoUrl = `/${i18n.language}/${customer?.config?.promo_url}`

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setSignInModal(false)
    setSignUpModal(false)
    setPasswordResetModal(false)
    setLanguageModal(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleVerifyClose = async () => {
    await Page.getCustomer()
      .then((data) => {
        setCustomer(data?.customer)
      })
      .catch((err) => {
        console.log(err)
      })

    setVerifyModal(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSentVerifyCode = () => {
    setVerifyModal(true)
    setVerifyButtonModal(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOpenSignInModal = () => {
    setMobileOpen(false)
    setSignUpModal(false)
    setPasswordResetModal(false)
    setSignInModal(true)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOpenSignUpModal = () => {
    setMobileOpen(false)
    setSignInModal(false)
    setSignUpModal(true)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOpenPasswordResetModal = () => {
    setSignInModal(false)
    setPasswordResetModal(true)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSignInOk = () => {
    // window.location.reload()
    Page.getCustomer()
      .then((data) => {
        setCustomer(data?.customer)
        if (data?.customer?.verified_at === null) {
          setVerifyButtonModal(true)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSignUpOk = () => {
    setVerifyModal(true)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl != null ? null : event.currentTarget)
    setProfilePopper(!profilePopper)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleLanguageClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLanguageEl(anchorLanguageEl != null ? null : event.currentTarget)
    setLanguagePopper(!languagePopper)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClickAway = (event: any) => {
    setAnchorEl(anchorEl != null ? null : event.currentTarget)
    setAnchorLanguageEl(anchorLanguageEl != null ? null : event.currentTarget)
    setProfilePopper(false)
    setLanguagePopper(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSetLanguage = () => {
    const locale = i18n.language === 'en' ? 'ka' : 'en'
    void i18n.changeLanguage(locale)
    setAnchorLanguageEl(null)
    setLanguagePopper(false)
    setCookie('locale', locale)
    navigate(pathname.replace(i18n.language === 'en' ? 'ka' : 'en', locale))
    // window.location.reload()
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleLogout = () => {
    removeCookie('token')
    navigate(`/${i18n.language}`)
  }

  useListener('onEmitLoginModal', handleOpenSignInModal)

  useEffect(() => {
    if (getCookie('token') !== undefined) {
      Page.getCustomer()
        .then((data) => {
          setCustomer(data?.customer)

          if (data?.customer?.verified_at === null) {
            setVerifyButtonModal(true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      return () => {}
    }
  }, [])

  useEffect(() => {
    setAnchorEl(null)
    setAnchorLanguageEl(null)
    setProfilePopper(false)
    setLanguagePopper(false)
    setMobileOpen(false)
  }, [location])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const isSticky = () => {
    const scrollTop = window.scrollY
    scrollTop >= 110 ? setStickyHeader(true) : setStickyHeader(false)
  }

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  return (
    <>
      <AppBar
        color="transparent"
        position={stickyHeader ? 'fixed' : 'absolute'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: { xs: '74px', md: '99px' },
          borderRadius: 'initial',
          backgroundColor: stickyHeader ? 'rgba(40, 41, 55, 0.5)' : '',
          backdropFilter: stickyHeader ? 'blur(25px)' : '',
          animationName: stickyHeader ? 'header' : '',
          animationDuration: stickyHeader ? '.4s' : ''
        }}
      >
        <Container
          maxWidth={false}
          sx={{ maxWidth: { md: '1112px', lg: '1280px' } }}
          disableGutters={true}
          fixed
        >
          <Toolbar sx={{ paddingX: { md: 0 } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              sx={{ ml: 'auto', display: { xs: 'block', md: 'none' } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>

            <Box component="nav">
              <Drawer
                variant="temporary"
                open={mobileOpen}
                ModalProps={{
                  keepMounted: true
                }}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '& .MuiDrawer-paper': {
                    background: 'rgba(40, 41, 55, 0.7)',
                    boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
                    backdropFilter: 'blur(25px)',
                    boxSizing: 'border-box',
                    width: 'calc(100% - 75px)',
                    px: '16px',
                    borderRadius: '0'
                  }
                }}
                onClose={handleDrawerToggle}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    marginTop: '16px'
                  }}
                >
                  {loggedIn
                    ? (
                    <>
                      <NavLink to={`/${i18n.language}/profile/account`}>
                        <Button
                          variant="outlined"
                          startIcon={<UserIcon />}
                          sx={{ width: '100%', height: '54px' }}
                          onClick={handleProfileClick}
                        >
                          {customer?.full_name}
                        </Button>
                      </NavLink>

                      <NavLink to={`/${i18n.language}/profile/card`}>
                        <Button
                          variant="contained"
                          sx={{ width: '100%', height: '54px' }}
                        >
                          {t('menu.myCard')}
                        </Button>
                      </NavLink>
                    </>
                      )
                    : (
                    <>
                      {/* <Button
                        variant="outlined"
                        sx={{ height: '54px' }}
                        onClick={handleOpenSignInModal}
                      >
                        {t('headerAccount.singIn')}
                      </Button> */}
                      {/* <Button
                        variant="contained"
                        sx={{ height: '54px' }}
                        onClick={handleOpenSignUpModal}
                      >
                        {t('headerAccount.singUp')}
                      </Button> */}
                    </>
                      )}
                </Box>

                <StyledMobileMenu>
                  <NavLink to={`/${i18n.language}/`}>{t('menu.home')}</NavLink>
                  <NavLink to={`/${i18n.language}/about-us`}>
                    {t('menu.about')}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/blog`}>{t('menu.blog')}</NavLink>
                  <NavLink to={`/${i18n.language}/streams`}>
                    {t('menu.streams')}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/gallery`}>
                    {t('menu.gallery')}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/promotions/offers`}>
                    {t('menu.promotions')}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/club-card`}>
                    {t('menu.clubCard')}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/branch/leaderboard`}>
                    {t('menu.leaderboard')}
                  </NavLink>
                </StyledMobileMenu>

                <Divider
                  sx={{
                    marginY: '20px',
                    background: 'rgba(188, 187, 198, 0.4)'
                  }}
                />

                <StyledMobileMenu sx={{ marginTop: '0 !important' }}>
                  <NavLink
                    to={`/${i18n.language}/language`}
                    onClick={(e) => {
                      e.preventDefault()
                      setMobileOpen(false)
                      setLanguageModal(true)
                    }}
                  >
                    {t('menu.language')}
                  </NavLink>
                  <NavLink
                    to={`/${i18n.language}/logout`}
                    onClick={(e) => {
                      e.preventDefault()
                      handleLogout()
                    }}
                  >
                    {t('menu.logout')}
                  </NavLink>
                </StyledMobileMenu>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '34px'
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Link
                      href="https://facebook.com/betlive.club"
                      target="_blank"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '34px',
                        height: '34px',
                        background:
                          'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                        backdropFilter: 'blur(10px)',
                        textDecoration: 'none',
                        borderRadius: '50%'
                      }}
                    >
                      <FacebookIcon />
                    </Link>

                    <Link
                      href="https://www.linkedin.com/company/betlive-club"
                      target="_blank"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '34px',
                        height: '34px',
                        background:
                          'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                        backdropFilter: 'blur(10px)',
                        textDecoration: 'none',
                        borderRadius: '50%'
                      }}
                    >
                      <LinkedinIcon />
                    </Link>

                    <Link
                      href="https://instagram.com/betlive.club?igshid=1rgsxrbl4wlqf"
                      target="_blank"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '34px',
                        height: '34px',
                        background:
                          'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                        backdropFilter: 'blur(10px)',
                        textDecoration: 'none',
                        borderRadius: '50%'
                      }}
                    >
                      <InstagramIcon />
                    </Link>

                    <Link
                      href="#"
                      target="_blank"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '34px',
                        height: '34px',
                        background:
                          'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                        backdropFilter: 'blur(10px)',
                        textDecoration: 'none',
                        borderRadius: '50%'
                      }}
                    >
                      <YoutubeIcon />
                    </Link>
                  </Box>
                </Box>
              </Drawer>
            </Box>

            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
                paddingTop: { lg: '4px' }
              }}
            >
              <NavLink to={`/${i18n.language}`}>
                {i18n.language === 'en' ? <LogoIcon /> : <LogoKaIcon />}
              </NavLink>
            </Box>

            <Box
              className="header-nav"
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                gap: { md: '10px', lg: '30px' },
                paddingLeft: {
                  md: i18n.language === 'en' ? '48px' : '16px',
                  lg: i18n.language === 'en' ? '86px' : '28px'
                }
              }}
            >
              <NavLink to={`/${i18n.language}/about-us`}>
                {t('menu.about')}
              </NavLink>
              <NavLink to={`/${i18n.language}/streams`}>{t('menu.streams')}</NavLink>
              <NavLink to={`/${i18n.language}/gallery`}>
                {t('menu.gallery')}
              </NavLink>
              <NavLink to={`/${i18n.language}/promotions/offers`}>
                {t('menu.promotions')}
              </NavLink>
              <NavLink to={`/${i18n.language}/club-card`}>
                {t('menu.clubCard')}
              </NavLink>
              <NavLink to={`/${i18n.language}/branch/leaderboard`}>
                {t('menu.leaderboard')}
              </NavLink>
            </Box>
            {/* eslint-disable-next-line multiline-ternary */}
            {loggedIn ? (
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexGrow: 0,
                  gap: '10px'
                }}
              >
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                <NavLink to={promoUrl}>
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px',
                      fontSize: { md: '14px' },
                      fontWeight: { md: '400' },
                      paddingX: { md: '20px' }
                    }}
                  >
                    Cashback
                  </Button>
                </NavLink>
                <Box sx={{ display: customer?.verified_at !== null ? 'block' : 'none' }}>
                  <Button
                    variant="outlined"
                    startIcon={<UserIcon />}
                    sx={{
                      height: '40px',
                      fontSize: { md: '14px' },
                      fontWeight: { md: '400' },
                      padding: { md: '0 21px 0 24px' }
                    }}
                    onClick={handleProfileClick}
                  >
                    {((customer?.full_name) != null)
                      ? matchesSmall ? customer?.first_name : customer?.full_name
                      : (
                      <Box display="flex" gap={'4px'}>
                        <Skeleton
                          variant="circular"
                          animation="wave"
                          width="60px"
                          height={14}
                          sx={SkeletonSx}
                        />
                        {!matchesSmall && (
                          <Skeleton
                            variant="circular"
                            animation="wave"
                            width="80px"
                            height={14}
                            sx={SkeletonSx}
                          />
                        )}
                      </Box>
                        )}
                  </Button>

                  <Popper
                    open={profilePopper}
                    anchorEl={anchorEl}
                    sx={{ zIndex: '3' }}
                  >
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <StyledBox>
                        <BalanceCarousel balances={customer?.balances} />

                        <Box
                          className="list"
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            marginTop: '24px',
                            fontSize: '14px',
                            fontWeight: 'bold'
                          }}
                        >
                          <NavLink to={`/${i18n.language}/profile/account`}>
                            Profile
                          </NavLink>
                          <NavLink to={`/${i18n.language}/profile/card`}>
                            My Card
                          </NavLink>
                        </Box>

                        <Button
                          variant="outlined"
                          fullWidth
                          sx={{ marginTop: '30px' }}
                          onClick={handleLogout}
                        >
                          Log out
                        </Button>
                      </StyledBox>
                    </ClickAwayListener>
                  </Popper>
                </Box>

                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  <StyledButton
                    variant="outlined"
                    startIcon={
                      i18n.language === 'en'
                        ? (
                        <EnglishCircleIcon />
                          )
                        : (
                        <GeorgiaCircleIcon />
                          )
                    }
                    sx={{
                      height: '40px',
                      fontSize: { lg: '16px' },
                      fontWeight: { lg: '400' },
                      paddingRight: { lg: '10px' }
                    }}
                    onClick={handleLanguageClick}
                  >
                    {i18n.language === 'en' ? 'ENG' : 'GEO'}
                      <Box className="header-dropdown">
                          <StyledLanguageBox sx={{ width: '96px !important', marginTop: '0 !important', padding: '0 !important' }}>
                              <Box
                                  className="list"
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                  }}
                              >
                                  <Button
                                      fullWidth
                                      startIcon={
                                          i18n.language === 'en'
                                            ? (
                                                  <GeorgiaCircleIcon />
                                              )
                                            : (
                                                  <EnglishCircleIcon />
                                              )
                                      }
                                      sx={{
                                        fontSize: '14px',
                                        color: 'white',
                                        fontWeight: 'bold'
                                      }}
                                      onClick={handleSetLanguage}
                                  >
                                      {i18n.language === 'en' ? 'GEO' : 'ENG'}
                                  </Button>
                              </Box>
                          </StyledLanguageBox>
                      </Box>
                  </StyledButton>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexGrow: 0,
                  gap: '10px'
                }}
              >
                {/* <Button
                  variant="outlined"
                  sx={{
                    height: '40px',
                    fontSize: { md: '14px' },
                    fontWeight: { lg: i18n.language === 'en' ? '400' : '500' },
                    paddingX: { lg: '18px' },
                    fontFeatureSettings: "'case' on"
                  }}
                  onClick={handleOpenSignInModal}
                >
                  {t('headerAccount.signIn')}
                </Button> */}
                {/* <Button
                  variant="contained"
                  sx={{
                    height: '40px',
                    fontSize: { md: '14px' },
                    fontWeight: { lg: i18n.language === 'en' ? '400' : '500' },
                    paddingX: { lg: '20px' },
                    fontFeatureSettings: "'case' on"
                  }}
                  onClick={handleOpenSignUpModal}
                >
                  {t('headerAccount.singUp')}
                </Button> */}

                <Box>
                  <StyledButton
                    variant="outlined"
                    startIcon={
                      i18n.language === 'en'
                        ? (
                        <EnglishCircleIcon />
                          )
                        : (
                        <GeorgiaCircleIcon />
                          )
                    }
                    sx={{
                      height: '40px',
                      fontSize: {
                        md: i18n.language === 'en' ? '16px' : '14px'
                      },
                      fontWeight: { lg: '400' },
                      paddingRight: { lg: '10px' },
                      fontFeatureSettings: "'case' on"
                    }}
                    onClick={handleLanguageClick}
                  >
                    {i18n.language === 'en'
                      ? t('header.englishLocale')
                      : t('header.georgiaLocale')}

                      <Box className="header-dropdown">
                          <StyledLanguageBox sx={{ width: '96px !important', marginTop: '0 !important', padding: '0 !important' }}>
                              <Box
                                  className="list"
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                    fontSize: '14px',
                                    fontWeight: 'bold'
                                  }}
                              >
                                  <Button
                                      fullWidth
                                      startIcon={
                                          i18n.language === 'en'
                                            ? (
                                                  <GeorgiaCircleIcon />
                                              )
                                            : (
                                                  <EnglishCircleIcon />
                                              )
                                      }
                                      sx={{
                                        fontSize: '14px',
                                        color: 'white',
                                        fontWeight: 'bold'
                                      }}
                                      onClick={handleSetLanguage}
                                  >
                                      {i18n.language === 'en' ? 'GEO' : 'ENG'}
                                  </Button>
                              </Box>
                          </StyledLanguageBox>
                      </Box>
                  </StyledButton>
                </Box>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <SignInModal
        isOpen={signInModal}
        onSignInOk={handleSignInOk}
        onSignUp={handleOpenSignUpModal}
        onForgotPassword={handleOpenPasswordResetModal}
        onClose={handleClose}
      />
      <SignUpModal
        isOpen={signUpModal}
        onSignUpOk={handleSignUpOk}
        onSignIn={handleOpenSignInModal}
        onClose={handleClose}
      />
      <PasswordResetModal
        isOpen={passwordResetModal}
        onClose={handleClose}
        onBack={handleOpenSignInModal}
      />
      <VerifyModal isOpen={verifyModal} onClose={handleVerifyClose} />
      <VerifyButtonModal isOpen={verifyButtonModal} onSent={onSentVerifyCode} />
      <LanguageModal isOpen={languageModal} onClose={handleClose} />
    </>
  )
}

export default Header
