import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { removeCookie } from 'typescript-cookie'
import { Swiper, SwiperSlide } from 'swiper/react'
import useMediaQuery from '@mui/material/useMediaQuery'

const DefaultButton = styled(Button)(({ theme }) => ({
  '&': {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    border: 'double 1px transparent',
    backgroundImage:
      'linear-gradient(90deg, #3C4153 0%, #3B3D4D 100.14%),linear-gradient(90deg, #817B92 0%, #817B92 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',

    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    },

    '&:hover': {
      border: 'double 1px transparent',
      backgroundImage:
        'linear-gradient(90deg, #3C4153 0%, #3B3D4D 100.14%),linear-gradient(90deg, #817B92 0%, #817B92 100.14%)'
    },

    '&.active': {
      backgroundImage:
        'linear-gradient(360deg, #2a4d6e -16.41%, #265469 102.67%), linear-gradient(360deg, #0193FD -16.41%, #00FDC0 102.67%)'
    }
  }
}))

interface Properties {
  active: String
  initialSlide: number
}

const ProfileNav: React.FC<Properties> = ({ active, initialSlide }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [isLastSlide, setIsLastSlide] = useState<any>(false)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleLogout = () => {
    removeCookie('token')
    navigate(`/${i18n.language}`)
  }

  return (
      <>
        <Box display={{ xs: 'none', md: 'flex' }} justifyContent="center" gap="14px" marginTop="30px">
          <NavLink to={`/${i18n.language}/profile/account`}>
            <DefaultButton
                className={active === 'account' ? 'active' : ''}
                variant="outlined"
            >
              {t('account.profile')}
            </DefaultButton>
          </NavLink>
          <NavLink to={`/${i18n.language}/profile/card`}>
            <DefaultButton
                className={active === 'card' ? 'active' : ''}
                variant="outlined"
            >
              {t('account.myCard')}
            </DefaultButton>
          </NavLink>
          <NavLink to={`/${i18n.language}/profile/offers`}>
            <DefaultButton
                className={active === 'offers' ? 'active' : ''}
                variant="outlined"
            >
              {t('account.activeOffers')}
            </DefaultButton>
          </NavLink>
          <DefaultButton variant="outlined" onClick={handleLogout}>{t('account.signOut')}</DefaultButton>
        </Box>
        {matches && (
            <Box
                margin="0 -16px"
                paddingLeft={(isLastSlide as boolean) ? 0 : '16px'}
                paddingRight={!(isLastSlide as boolean) ? 0 : '16px'}
            >
            <Swiper slidesPerView={2.2} spaceBetween={10} initialSlide={initialSlide} onTransitionEnd={(swiper) => setIsLastSlide(swiper.isEnd)}>
              <SwiperSlide>
                <NavLink to={`/${i18n.language}/profile/account`}>
                  <DefaultButton
                      sx={{ width: '100%' }}
                      className={active === 'account' ? 'active' : ''}
                      variant="outlined"
                  >
                    {t('account.profile')}
                  </DefaultButton>
                </NavLink>
              </SwiperSlide>

              <SwiperSlide>
                <NavLink to={`/${i18n.language}/profile/card`}>
                  <DefaultButton
                      sx={{ width: '100%' }}
                      className={active === 'card' ? 'active' : ''}
                      variant="outlined"
                  >
                    {t('account.myCard')}
                  </DefaultButton>
                </NavLink>
              </SwiperSlide>

              <SwiperSlide>
                <NavLink to={`/${i18n.language}/profile/offers`}>
                  <DefaultButton
                      sx={{ width: '100%' }}
                      className={active === 'offers' ? 'active' : ''}
                      variant="outlined"
                  >
                    {t('account.activeOffers')}
                  </DefaultButton>
                </NavLink>
              </SwiperSlide>

              <SwiperSlide>
                <NavLink to={`/${i18n.language}/profile/offers`}>
                  <DefaultButton variant="outlined" sx={{ width: '100%' }} onClick={handleLogout}>{t('account.signOut')}</DefaultButton>
                </NavLink>
              </SwiperSlide>
            </Swiper>
            </Box>
        )}
      </>
  )
}

export default ProfileNav
