import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Modal, Snackbar, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Page } from '../../services/api/page'
import { useTranslation } from 'react-i18next'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import CloseIcon from '../Icon/CloseIcon'
import BackIcon from '../Icon/BackIcon'
import { setCookie } from 'typescript-cookie'

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    fontSize: '15px',
    padding: '0 6px',
    transform: 'translate(24px, 26px) scale(1)',

    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      transform: 'translate(18px, 20px) scale(1)'
    }
  },
  '& label.Mui-focused': {
    fontSize: '15px',
    background:
        'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },
  '& label.MuiFormLabel-filled': {
    fontSize: '15px',
    background:
        'linear-gradient(360deg, rgba(40,41,55,1) 0%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },
  '& .MuiOutlinedInput-root': {
    border: 'double 1px transparent',
    backgroundImage:
        'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '16.5px 14px',
    height: '74px',
    borderRadius: '20px',

    [theme.breakpoints.down('lg')]: {
      height: '58px',
      fontSize: '12px',
      padding: '0 10px'
    },

    '& .MuiInputBase-input': {
      fontWeight: 'bold'
    }
  }
}))

interface ModalProperties {
  isOpen: any
  onClose: any
  onBack: any
}

interface State {
  phone_number: string
  email: string
  phone_prefix: string
  code: string
  password: string
  password_confirmation: string
}

type PasswordResetMode = 'userInformation' | 'verifyCode' | 'fillNewPassword'

const PasswordResetModal: React.FC<ModalProperties> = ({ isOpen, onClose, onBack }) => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = React.useState(isOpen)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [values, setValues] = React.useState<State>({
    phone_number: '',
    email: '',
    phone_prefix: '995',
    code: '',
    password: '',
    password_confirmation: ''
  })
  const [snackbarItem, setSnackbarItem] = useState({
    status: false,
    type: 'success',
    message: ''
  })
  const [mode, setMode] = React.useState<PasswordResetMode>('userInformation')

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert (
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  const resetValues = (): any => {
    setValues({
      phone_number: '',
      email: '',
      phone_prefix: '995',
      code: '',
      password: '',
      password_confirmation: ''
    })

    setMode('userInformation')

    setSnackbarItem({
      status: false,
      type: 'success',
      message: ''
    })
  }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    resetValues()
    handleChangeMode('userInformation')
    setOpen(false)
    onClose(false)
  }

  const handleChangeMode = (mode: PasswordResetMode): any => {
    setMode(mode)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSignIn = () => {
    Page.signIn({
      phone_number: values.phone_number,
      password: values.password
    })
      .then((data) => {
        if (data?.data?.access_token as boolean) {
          setCookie('token', data?.data?.access_token)
          handleClose()
        }
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSendVerifyCode = () => {
    setLoading(true)
    Page.sendPasswordResetVerify(values)
      .then((data) => {
        setSnackbarItem({
          status: false,
          type: 'success',
          message: data.message
        })
        handleChangeMode('verifyCode')
      })
      .catch((err) => {
        setSnackbarItem({
          status: false,
          type: 'error',
          message: err?.response?.data?.message
        })
        setError(err?.response?.data?.message)
      })
    setLoading(false)
    return () => {}
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleCheckVerifyCode = () => {
    setLoading(true)
    Page.passwordResetCheckVerifyCode(values)
      .then((data) => {
        setSnackbarItem({
          status: false,
          type: 'success',
          message: data.message
        })
        handleChangeMode('fillNewPassword')
      })
      .catch((err) => {
        setSnackbarItem({
          status: false,
          type: 'error',
          message: err?.response?.data?.message
        })
        setError(err?.response?.data?.message)
      })
    setLoading(false)
    return () => {}
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handlePasswordUpdate = () => {
    setLoading(true)
    Page.passwordResetUpdate(values)
      .then((data) => {
        setSnackbarItem({
          status: true,
          type: 'success',
          message: data.message
        })
        setTimeout(async () => {
          await handleSignIn()
          resetValues()
          handleClose()
          setLoading(false)
        }, 3000)
      })
      .catch((err) => {
        setLoading(false)
        setSnackbarItem({
          status: true,
          type: 'error',
          message: err?.response?.data?.message
        })
      })
    return () => {}
  }

  useEffect(() => {
    setOpen(isOpen)
  })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', md: '50%' },
          bottom: { xs: '0', md: 'initial' },
          left: { xs: '0', md: '50%' },
          transform: { xs: 'initial', md: 'translate(-50%, -50%) scale(0.85)' },
          width: { xs: '100%', md: '735px' },
          backgroundColor: 'rgba(40, 41, 55, 1)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', md: '20px' }
        }}
      >
        <Snackbar open={snackbarItem.status} autoHideDuration={6000}>
          <Alert
            severity={
              snackbarItem.type !== ''
                ? (snackbarItem.type.toString() as AlertColor)
                : 'success'
            }
            sx={{ width: '100%' }}
          >
            {snackbarItem?.message}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '62px', md: '99px' },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <IconButton
              aria-label="close"
              sx={{ position: 'absolute', top: { xs: '8px', md: '27px' }, left: { xs: '5px', md: '27px' }, transform: { xs: 'scale(0.8)', md: 'initial' } }}
              onClick={() => {
                resetValues()
                onBack()
              }}
          >
            <BackIcon />
          </IconButton>

          <Typography
            sx={{ color: 'white', fontWeight: '900', fontSize: { xs: '16px', md: i18n.language === 'en' ? '24px' : '20px' } }}
          >
            {t('passwordReset.title')}
          </Typography>

          <IconButton
              aria-label="close"
              sx={{ position: 'absolute', top: '27px', right: '27px', display: { xs: 'none', md: 'block' } }}
              onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ padding: { xs: '52px 52px 0', md: '52px 52px 0 52px' } }}>
          <Typography
            sx={{
              color: 'rgba(235, 30, 46, 1)',
              position: 'relative',
              top: '-30px',
              fontSize: '13px',
              fontWeight: 'bold'
            }}
          >
            {error}
          </Typography>

          <CssTextField
            label={t('passwordReset.phoneNumber')}
            value={values.phone_number}
            disabled={mode !== 'userInformation'}
            onChange={handleChange('phone_number')}
            fullWidth
            id="custom-css-outlined-input"
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'rgba(255, 255, 255, 0.6)'
              }
            }}/>
          {mode === 'userInformation'
            ? (
              <><Button
                variant="contained"
                fullWidth
                disabled={(values.email === '' && values.phone_number === '') || loading}
                onClick={handleSendVerifyCode}
                sx={{
                  height: {
                    xs: '58px',
                    md: '74px'
                  },
                  marginY: {
                    xs: '24px',
                    md: '33px'
                  },
                  fontWeight: 'bold',
                  borderRadius: '20px'
                }}
              >
                {t('passwordReset.sendVerifyCode')}
              </Button></>)
            : ''}
        </Box>

        {mode !== 'userInformation'
          ? (
          <Box sx={{ padding: mode === 'verifyCode' ? '16px 52px 20px' : 0 }}>
            {mode === 'verifyCode'
              ? (
                <><CssTextField
                  label={t('passwordReset.verifyCode')}
                  value={values.code}
                  disabled={mode !== 'verifyCode'}
                  onChange={handleChange('code')}
                  fullWidth
                  id="custom-css-outlined-input"/><Button
                  variant="contained"
                  fullWidth
                  disabled={values.code === '' || loading}
                  onClick={handleCheckVerifyCode}
                  sx={{
                    height: '74px',
                    marginTop: '40px',
                    fontWeight: 'bold',
                    borderRadius: '20px'
                  }}
                >
                  {t('passwordReset.checkVerifyCode')}
                </Button></>)
              : ''}
          </Box>
            )
          : ''}

        {mode === 'fillNewPassword'
          ? (
            <Box sx={{ padding: '16px 52px 20px' }}>
              <CssTextField
                type="password"
                label={t('passwordReset.password')}
                value={values.password}
                disabled={loading}
                onChange={handleChange('password')}
                fullWidth
                id="custom-css-outlined-input"
              />

              <CssTextField
                type="password"
                label={t('passwordReset.passwordConfirmation')}
                value={values.password_confirmation}
                disabled={loading}
                onChange={handleChange('password_confirmation')}
                fullWidth
                id="custom-css-outlined-input"
                sx={{
                  marginTop: '16px'
                }}
              />

              <Button
                  variant="contained"
                  fullWidth
                  disabled={values.password === '' || values.password_confirmation === '' || loading}
                  onClick={handlePasswordUpdate}
                  sx={{
                    height: '74px',
                    marginTop: '40px',
                    fontWeight: 'bold',
                    borderRadius: '20px'
                  }}
                >
                  {t('passwordReset.passwordUpdateAction')}
                </Button>
            </Box>
            )
          : ''}

      </Box>
    </Modal>
  )
}

export default PasswordResetModal
