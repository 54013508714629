import React from 'react'

const PromoCodeIcon: React.FC = () => {
  return (
      <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="35" cy="35" r="33.75" stroke="white" strokeWidth="2.5"/>
          <path d="M46.8743 35.7918C46.8743 33.6068 48.6477 31.8335 50.8327 31.8335V30.2502C50.8327 23.9168 49.2493 22.3335 42.916 22.3335H27.0827C20.7493 22.3335 19.166 23.9168 19.166 30.2502V31.0418C21.351 31.0418 23.1243 32.8152 23.1243 35.0002C23.1243 37.1852 21.351 38.9585 19.166 38.9585V39.7502C19.166 46.0835 20.7493 47.6668 27.0827 47.6668H42.916C49.2493 47.6668 50.8327 46.0835 50.8327 39.7502C48.6477 39.7502 46.8743 37.9768 46.8743 35.7918Z" stroke="white" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M30.25 39.354L39.75 29.854" stroke="white" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M39.742 39.3542H39.7562" stroke="white" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M30.242 30.6457H30.2562" stroke="white" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

  )
}

export default PromoCodeIcon
