import React from 'react'

const ArrowDownGradientIcon: React.FC = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8">
              <g filter="url(#filter0_b_615_20361)">
                  <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="url(#paint0_linear_615_20361)"/>
              </g>
          </g>
          <path d="M9 16.3621L12.269 19.5714L21 11" stroke="url(#paint1_linear_615_20361)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <defs>
              <filter id="filter0_b_615_20361" x="-79" y="-79" width="188" height="188" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="39.5"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_615_20361"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_615_20361" result="shape"/>
              </filter>
              <linearGradient id="paint0_linear_615_20361" x1="-3.06122" y1="15" x2="30.7872" y2="15" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FFA4AB"/>
                  <stop offset="0.546875" stopColor="#E05EC9"/>
                  <stop offset="1" stopColor="#9E95FF"/>
              </linearGradient>
              <linearGradient id="paint1_linear_615_20361" x1="7.77551" y1="15.2857" x2="21.3149" y2="15.2857" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#FFDBDE"/>
                  <stop offset="0.546875" stopColor="#F993E6"/>
                  <stop offset="1" stopColor="#BAB4FF"/>
              </linearGradient>
          </defs>
      </svg>

  )
}

export default ArrowDownGradientIcon
