import React, { useState } from 'react'
import {
  Box,
  Typography,
  Container,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  Link,
  Snackbar
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Page } from '../../services/api/page'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import FacebookIcon from '../Icon/FacebookIcon'
import LinkedinIcon from '../Icon/LinkedinIcon'
import InstagramIcon from '../Icon/InstagramIcon'
import YoutubeIcon from '../Icon/YoutubeIcon'
import FooterMobile from './FooterMobile'
import LogoSecondaryIcon from '../Icon/LogoSecondaryIcon'
import UnsubscribeModal from '../Modal/UnSubscribeModal'

const StyledBox = styled(Box)(({ theme }) => ({
  '&': {
    width: '100%',
    height: 246,
    background: 'rgba(40, 41, 55, 0.5)',
    backdropFilter: 'blur(37.5px)',
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      height: '215px'
    },

    [theme.breakpoints.down('md')]: {
      height: '171px'
    },

    '&::before': {
      content: '""',
      display: 'block',
      width: '697px',
      height: '246px',
      background: 'url("/static/footer-left.svg") no-repeat',
      backgroundSize: '100% 100%',
      position: 'absolute',
      top: '0',
      left: '0',

      [theme.breakpoints.down('xl')]: {
        width: '365px',
        height: '215px',
        background: 'url("/static/footer-left-small.svg") no-repeat',
        top: '0',
        left: '0'
      },

      [theme.breakpoints.down('md')]: {
        width: '120px',
        height: '171px',
        background: 'url("/static/footer-left-mobile.svg") no-repeat',
        top: '0',
        left: '0'
      }
    },

    '&::after': {
      content: '""',
      display: 'block',
      width: '678px',
      height: '246px',
      background: 'url("/static/footer-right.svg") no-repeat',
      backgroundSize: '100% 100%',
      position: 'absolute',
      top: '0',
      right: '0',

      [theme.breakpoints.down('xl')]: {
        width: '344px',
        height: '215px',
        background: 'url("/static/footer-right-small.svg") no-repeat',
        top: '0'
      },

      [theme.breakpoints.down('md')]: {
        width: '164px',
        height: '171px',
        background: 'url("/static/footer-right-mobile.svg") no-repeat',
        top: '0'
      }
    }
  }
}))

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [subscribeForm, setSubscribeForm] = useState<any>({
    email: ''
  })
  const [unsubscribeModal, setUnsubscribeModal] = React.useState(false)
  const [snackbarItem, setSnackbarItem] = useState({
    status: false,
    type: 'success',
    message: ''
  })
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setUnsubscribeModal(false)
  }

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert (
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  const makeSubscribe = (): any => {
    setLoading(true)
    Page.makeSubscribe(subscribeForm)
      .then((data) => {
        setSnackbarItem({
          status: true,
          type: 'success',
          message: data.message
        })
      })
      .catch((err) => {
        setSnackbarItem({
          status: true,
          type: 'error',
          message: err?.response?.data?.errors?.email
        })

        setTimeout(() => {
          setSnackbarItem({
            status: false,
            message: '',
            type: 'error'
          })
        }, 5000)
      })
    setLoading(false)
  }

  const handleCityChange = (event: any): any => {
    if (/^[A-Za-z0-9\s,.@_-]+$/.test(event.target.value)) {
      setSubscribeForm({
        email: event.target.value
      })
    } else if (event.target.value === '') {
      setSubscribeForm({
        email: ''
      })
    }
  }

  return (
    <>
      <Snackbar open={snackbarItem.status} autoHideDuration={6000}>
        <Alert
          severity={
            snackbarItem.type !== ''
              ? (snackbarItem.type.toString() as AlertColor)
              : 'success'
          }
          sx={{ width: '100%' }}
        >
          {snackbarItem?.message}
        </Alert>
      </Snackbar>
      <StyledBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: '900',
              fontSize: { xs: '16px', md: '24px' },
              textTransform: 'uppercase',
              marginTop: { xs: '11px', md: '-30px', lg: 'initial' },
              paddingY: { xs: '12px', md: '59px' },
              letterSpacing: '1px'
            }}
          >
              {t('subscribe.title')}
          </Typography>
          <FormControl
            variant="filled"
            sx={{
              color: 'white',
              border: 'double 1px transparent',
              backgroundImage:
                'linear-gradient(90deg, #3f3f50 0%, #35425c 100.14%), linear-gradient(90deg, rgba(255, 219, 222, 1) 0%, rgba(186, 180, 255, 1) 100.14%)',
              backgroundOrigin: 'border-box',
              backgroundClip: 'padding-box, border-box',
              width: { xs: 'calc(100% - 32px)', md: '440px' },
              marginTop: { xs: '14px', md: '-18px' },
              height: { xs: '48px', md: '60px' },
              zIndex: '2',
              borderRadius: { xs: '16px', md: '20px' }
            }}
          >
            <OutlinedInput
              id="outlined-adornment-password"
              placeholder={t('subscribe.email')}
              disabled={loading}
              value={subscribeForm.email}
              onChange={handleCityChange}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={subscribeForm.email === '' || loading}
                    onClick={() => makeSubscribe()}
                    sx={{
                      width: { xs: '144px', md: '170px' },
                      height: { xs: '40px', md: '52px' },
                      color: 'white !important',
                      fontWeight: 'bold',
                      borderRadius: { xs: '12px', md: '18px' }
                    }}
                  >
                    {t('subscribe')}
                  </Button>
                </InputAdornment>
              }
              label="Password"
              sx={{
                paddingLeft: '9px',
                paddingRight: '3px'
              }}
            />
          </FormControl>
        </Box>
      </StyledBox>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          width: '100%',
          height: 131,
          backgroundColor: 'rgba(23, 24, 34, 0.8)'
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            maxWidth: { md: '1112px', lg: '1280px' },
            height: '131px'
          }}
          disableGutters={true}
          fixed
        >
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              gap: { md: i18n.language === 'en' ? '82px' : '34px', lg: '82px' },
              height: '100%',
              fontWeight: '500'
            }}
          >
            <Box sx={{ marginTop: '3px' }}>
              <NavLink to={`/${i18n.language}`}>
                <LogoSecondaryIcon />
              </NavLink>
              <Typography
                color="white"
                marginTop="14px"
                display="flex"
                alignItems="center"
                gap="10px"
                fontWeight="500"
              >
                <Box
                  sx={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: '16px',
                    border: 'double 1px transparent',
                    backgroundImage:
                      'linear-gradient(90deg, #2E1B25 0%, #1F1E36 100.14%),linear-gradient(90deg, #EB1E2E 0%, #5043DA 100.14%)',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'padding-box, border-box'
                  }}
                >
                  +25
                </Box>
                {t('footer.rightReserved')}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                marginLeft: { md: i18n.language === 'en' ? '60px' : '5px', lg: i18n.language === 'en' ? '60px' : '0' },
                marginTop: { lg: '6px' },
                fontWeight: i18n.language === 'ka' ? '400' : '',
                fontFeatureSettings: "'case' on"
              }}
            >
              <NavLink to={`/${i18n.language}/about-us`}>
                {t('menu.about')}
              </NavLink>
              <NavLink to={`/${i18n.language}/careers`}>
                {t('menu.jobs')}
              </NavLink>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                marginTop: { lg: '6px' },
                fontWeight: i18n.language === 'ka' ? '400' : '',
                fontFeatureSettings: "'case' on"
              }}
            >
              <NavLink to={`/${i18n.language}/terms-conditions`}>
                {t('footer.termsConditions')}
              </NavLink>
              <NavLink to={`/${i18n.language}/faq`}>{t('footer.faq')}</NavLink>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                marginTop: { lg: '6px' },
                fontWeight: i18n.language === 'ka' ? '400' : '',
                fontFeatureSettings: "'case' on"
              }}
            >
              <NavLink to={`/${i18n.language}/privacy`}>
                {t('footer.privacyPolicy')}
              </NavLink>

              <NavLink to="#" onClick={() => setUnsubscribeModal(true)}>{t('footer.unsubscribe')}</NavLink>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                marginTop: { lg: '6px' },
                fontWeight: i18n.language === 'ka' ? '400' : '',
                fontFeatureSettings: "'case' on"
              }}
            >
              <NavLink to={`/${i18n.language}/jobs`}>
                {t('menu.career')}
              </NavLink>

              <NavLink to={`/${i18n.language}/blog`}>
                {t('menu.blog')}
              </NavLink>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '26px',
                marginTop: '-1px',
                marginLeft: 'auto',
                fontFeatureSettings: "'case' on"
              }}
            >
              <Typography color="white" fontSize="18px" fontWeight="500">
                Follow us
              </Typography>

              <Box
                sx={{ display: 'flex', gap: '7px', marginTop: { lg: '-14px' } }}
              >
                <Link
                  href="https://facebook.com/betlive.club"
                  target="_blank"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '32px',
                    height: '32px',
                    background:
                      'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                    backdropFilter: 'blur(10px)',
                    textDecoration: 'none',
                    borderRadius: '50%'
                  }}
                >
                  <FacebookIcon />
                </Link>

                <Link
                  href="https://www.linkedin.com/company/betlive-club"
                  target="_blank"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '32px',
                    height: '32px',
                    background:
                      'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                    backdropFilter: 'blur(10px)',
                    textDecoration: 'none',
                    borderRadius: '50%'
                  }}
                >
                  <LinkedinIcon />
                </Link>

                <Link
                  href="https://instagram.com/betlive.club?igshid=1rgsxrbl4wlqf"
                  target="_blank"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '32px',
                    height: '32px',
                    background:
                      'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                    backdropFilter: 'blur(10px)',
                    textDecoration: 'none',
                    borderRadius: '50%'
                  }}
                >
                  <InstagramIcon />
                </Link>

                <Link
                  href="#"
                  target="_blank"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '32px',
                    height: '32px',
                    background:
                      'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                    backdropFilter: 'blur(10px)',
                    textDecoration: 'none',
                    borderRadius: '50%'
                  }}
                >
                  <YoutubeIcon />
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <FooterMobile />

        <UnsubscribeModal isOpen={unsubscribeModal} onClose={handleClose} />
    </>
  )
}

export default Footer
