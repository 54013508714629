import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  CardActions,
  Button
} from '@mui/material'
import HeroCarousel from '../components/Carousel/Hero/HeroCarousel'
import BranchIcon from '../components/Icon/BranchIcon'
import PhoneIcon from '../components/Icon/PhoneIcon'
import MessageIcon from '../components/Icon/MessageIcon'
import { Page } from '../services/api/page'
import BranchGradientIcon from '../components/Icon/BranchGradientIcon'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'

const AboutView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const mapRef = useRef<null | HTMLImageElement>(null)
  const [page, setPage] = useState<any>({})
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))
  const matchesXs = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  useEffect(() => {
    Page.getAboutPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [])

  useEffect(() => {
    Page.getAboutPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])
  return (
    <Box
      sx={{
        backgroundImage: {
          xs: 'url("/static/about-mobile.jpg")',
          md: 'url("/static/about-md.jpg")',
          lg: 'url("/static/about-xl.jpg")',
          xl: 'url("/static/about-xl.jpg")'
        },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: { xs: '0', md: '102px' }
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '140px'
        }}
      >
        <Typography color="white" fontSize="16px" fontWeight="700">
          {t('page.about.title')}
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%'
        }}
      >
        <Box
          sx={{
            background: 'rgba(38, 44, 60, 0.5)',
            backdropFilter: 'blur(15.5109px)'
          }}
        >
          <Container
            maxWidth={false}
            sx={{ maxWidth: { md: '1192px', lg: '1280px' } }}
            disableGutters={true}
          >
            <Box
              height={{ xs: 'auto', md: '425px', lg: '485px' }}
              sx={{
                display: { xs: 'block', md: 'flex' },
                padding: { xs: '16px', md: '40px', lg: '40px 0 0 0' },
                paddingBottom: { xs: '16px', md: '16px', lg: '109px' },
                marginBottom: { xs: '0', md: '0px' },
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{
                  fontWeight: '500',
                  lineHeight: i18n.language === 'en' ? '100%' : '107%',
                  display: { xs: 'none', md: 'inline-block' },
                  width: { md: '285px', lg: '330px' },
                  fontSize: { md: i18n.language === 'en' ? '76px' : '68px', lg: i18n.language === 'en' ? '90px' : '64px' },
                  fontFeatureSettings: "'case' on",
                  backgroundImage:
                    'linear-gradient(150.67deg, #FFA4AB 6.58%, #E05EC9 160.16%)',
                  backgroundClip: 'text',
                  color: 'transparent',
                  position: 'relative',
                  top: { md: '68px', lg: '80px' }
                }}
                dangerouslySetInnerHTML={{ __html: t('about.heading') }}
              />

              <Box
                sx={{
                  width: { xs: '100%', md: '806px', lg: '937px' },
                  height: { xs: 'auto', md: '370px', lg: '430px' },
                  borderRadius: '45px',
                  overflow: 'hidden',
                  backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                }}
              >
                <HeroCarousel name="full_src" items={page.images} />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          paddingTop: { xs: '30.44px', md: '50px', lg: '54px' },
          paddingBottom: { xs: '60.56px', md: '70px', lg: '90px' }
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            maxWidth: { md: '1192px', lg: '1280px' },
            paddingX: { xs: '16px', md: '40px', lg: '0px' }
          }}
          disableGutters={true}
        >
          <Typography
            dangerouslySetInnerHTML={{ __html: page?.content }}
            sx={{
              fontFamily: 'Helvetica',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '24px',
              color: '#FFF'
            }}
          />
        </Container>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        paddingY={{ xs: '44px', lg: '50px' }}
        paddingX={{ xs: '16px', md: '0' }}
        sx={{
          background: 'rgba(38, 44, 60, 0.5)',
          backdropFilter: 'blur(15.5109px)'
        }}
      >
        <Container maxWidth="lg" disableGutters fixed>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: { xs: '12px', md: '16px' },
              paddingTop: { xs: '0', md: '0' },
              color: 'white'
            }}
          >
            <BranchIcon
              width={matchesXs ? 18 : 30}
              height={matchesXs ? 16 : 26}
            />
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: { xs: '16px', md: '30px', lg: '40px' },
                alignSelf: 'center',
                borderColor: 'rgba(255, 255, 255, 0.5)'
              }}
            />
            <Typography
              sx={{
                fontSize: { xs: 16, md: 24, lg: 32 },
                fontWeight: 'bold'
              }}
              color="white"
            >
              {t('home.contactCardTitle')}
            </Typography>
          </Box>
          <Grid
            container
            spacing={{ xs: '16px', md: '20px', lg: '24px' }}
            sx={{
              marginTop: { xs: '12px', md: '24px', lg: '38px' },
              paddingX: { xs: '0', md: '86px' }
            }}
          >
            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  p: { xs: '12px', md: 0, lg: '20px 0 0' },
                  height: { xs: '194px', md: '200px', lg: '226px' },
                  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  border: 'double 1px transparent',
                  backgroundImage: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, #6DFFE7 -10.2%,
                                #6DFFE7 51.5%, #6DFFE7 102.62%)
                          `,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  borderRadius: { md: '16px', lg: '20px' }
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BranchGradientIcon
                    width={matchesXs ? 44 : matches ? 38 : 49}
                    height={matchesXs ? 40 : 44}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: { xs: 16, md: 20 },
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                    color="white"
                  >
                    {t('branch.saburtaloTitle')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      paddingX: { md: '20px', lg: 0 },
                      textAlign: 'center'
                    }}
                    color="rgba(255, 255, 255, 0.5)"
                    dangerouslySetInnerHTML={{
                      __html: t('branch.saburtaloAddress')
                    }}
                    gutterBottom
                  ></Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="secondary"
                    href="https://goo.gl/maps/JvFkVzGNAQH1izwaA"
                    target="_blank"
                    sx={{
                      height: '0',
                      px: '60px',
                      borderRadius: '20px',
                      margin: {
                        xs:
                          i18n.language === 'en'
                            ? '5px auto 0'
                            : '-15px auto 0',
                        md: '-9px auto 0',
                        lg:
                          i18n.language === 'en'
                            ? '11px auto 0'
                            : '-13px auto 0'
                      },
                      padding: '0'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '14px',
                          lg: i18n.language === 'en' ? '16px' : '14px'
                        },
                        fontWeight: i18n.language === 'en' ? 'bold' : '500',
                        backgroundImage: `
                                            linear-gradient(90deg, #FFFFFF -10.2%, #FFFFFF 51.5%, #FFFFFF 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                        backgroundClip: 'text',
                        color: 'transparent',
                        borderBottom: '1px solid #FFFFFF',
                        marginTop: '-4px',
                        height: {
                          xs: '18px',
                          lg: i18n.language === 'en' ? '21px' : '19px'
                        },
                        fontFeatureSettings: "'case' on"
                      }}
                    >
                      {t('branch.seeLocation')}
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  p: { xs: '12px', md: 0, lg: '20px 0 0' },
                  height: { xs: '194px', md: '200px', lg: '226px' },
                  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  border: 'double 1px transparent',
                  backgroundImage: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, #6DFFE7 -10.2%,
                                #6DFFE7 51.5%, #6DFFE7 102.62%)
                          `,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  borderRadius: { md: '16px', lg: '20px' }
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BranchGradientIcon
                    width={matchesXs ? 44 : matches ? 38 : 49}
                    height={matchesXs ? 40 : 44}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: { xs: 16, md: 20 },
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                    color="white"
                  >
                    {t('branch.gldaniTitle')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      paddingX: { md: '10px', lg: 0 },
                      textAlign: 'center'
                    }}
                    color="rgba(255, 255, 255, 0.5)"
                    gutterBottom
                  >
                    {t('branch.gldaniAddress')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="secondary"
                    href="https://goo.gl/maps/XTLAsKwTPpGfn2DS8"
                    target="_blank"
                    sx={{
                      height: '0',
                      px: '60px',
                      borderRadius: '20px',
                      margin: {
                        xs: '5px auto 0',
                        md: '12px auto 0',
                        lg: '11px auto 0'
                      },
                      padding: '0'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '14px',
                          lg: i18n.language === 'en' ? '16px' : '14px'
                        },
                        fontWeight: i18n.language === 'en' ? 'bold' : '500',
                        backgroundImage: `
                                            linear-gradient(90deg, #FFFFFF -10.2%, #FFFFFF 51.5%, #FFFFFF 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                        backgroundClip: 'text',
                        color: 'transparent',
                        borderBottom: '1px solid #FFFFFF',
                        marginTop: '-4px',
                        height: {
                          xs: '18px',
                          lg: i18n.language === 'en' ? '21px' : '19px'
                        },
                        fontFeatureSettings: "'case' on"
                      }}
                    >
                      {t('branch.seeLocation')}
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  p: { xs: '12px', md: 0, lg: '20px 0 0' },
                  height: { xs: '194px', md: '200px', lg: '226px' },
                  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  border: 'double 1px transparent',
                  backgroundImage: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, #6DFFE7 -10.2%,
                              #6DFFE7 51.5%, #6DFFE7 102.62%)
                          `,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  borderRadius: { md: '16px', lg: '20px' }
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BranchGradientIcon
                    width={matchesXs ? 44 : matches ? 38 : 49}
                    height={matchesXs ? 40 : 44}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: { xs: 16, md: 20 },
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                    color="white"
                  >
                    {t('branch.varketiliTitle')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      paddingX: { md: '10px', lg: 0 },
                      textAlign: 'center'
                    }}
                    color="rgba(255, 255, 255, 0.5)"
                    gutterBottom
                  >
                    {t('branch.varketiliText')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="secondary"
                    href="https://goo.gl/maps/gccz6Crit3PHdYgx6"
                    target="_blank"
                    sx={{
                      height: '0',
                      px: '60px',
                      borderRadius: '20px',
                      margin: {
                        xs: '5px auto 0',
                        md: '12px auto 0',
                        lg: '11px auto 0'
                      },
                      padding: '0'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '14px',
                          lg: i18n.language === 'en' ? '16px' : '14px'
                        },
                        fontWeight: i18n.language === 'en' ? 'bold' : '500',
                        backgroundImage: `
                                            linear-gradient(90deg, #FFFFFF -10.2%, #FFFFFF 51.5%, #FFFFFF 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                        backgroundClip: 'text',
                        color: 'transparent',
                        borderBottom: '1px solid #FFFFFF',
                        marginTop: '-4px',
                        height: {
                          xs: '18px',
                          lg: i18n.language === 'en' ? '21px' : '19px'
                        },
                        fontFeatureSettings: "'case' on"
                      }}
                    >
                      {t('branch.seeLocation')}
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  p: { xs: '12px', md: 0, lg: '20px 0 0' },
                  height: { xs: '194px', md: '200px', lg: '226px' },
                  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  border: 'double 1px transparent',
                  backgroundImage: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, #6DFFE7 -17.2%,
                              #6DFFE7 51.5%, #55BEC0 102.62%)
                          `,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  borderRadius: { md: '16px', lg: '20px' }
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BranchGradientIcon
                    width={matchesXs ? 44 : matches ? 38 : 49}
                    height={matchesXs ? 40 : 44}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: { xs: 16, md: 20 },
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                    color="white"
                  >
                    {t('branch.batumiTitle')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      paddingX: { md: '10px', lg: 0 },
                      textAlign: 'center'
                    }}
                    color="rgba(255, 255, 255, 0.5)"
                    gutterBottom
                  >
                    {t('branch.batumiText')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="secondary"
                    href="https://maps.app.goo.gl/ugpUhqM7LprHsEU1A"
                    target="_blank"
                    sx={{
                      height: '0',
                      px: '60px',
                      borderRadius: '20px',
                      margin: {
                        xs: '5px auto 0',
                        md: '12px auto 0',
                        lg: '11px auto 0'
                      },
                      padding: '0'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '14px',
                          lg: i18n.language === 'en' ? '16px' : '14px'
                        },
                        fontWeight: i18n.language === 'en' ? 'bold' : '500',
                        backgroundImage: `
                                            linear-gradient(90deg, #6DFFE7 -10.2%, #55BEC0 51.5%, #55BEC0 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                        backgroundClip: 'text',
                        color: 'transparent',
                        borderBottom: '1px solid #6DFFE7',
                        marginTop: '-4px',
                        height: {
                          xs: '18px',
                          lg: i18n.language === 'en' ? '21px' : '19px'
                        },
                        fontFeatureSettings: "'case' on"
                      }}
                    >
                      {t('branch.seeLocation')}
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'center',
              gap: '42px',
              marginTop: { md: '50px', lg: '70px' },
              marginBottom: { xs: '18px', md: '24px', lg: '30px' }
            }}
          >
            <Box
              sx={{
                mt: { xs: '24px', md: 'initial' },
                display: 'flex',
                alignItems: 'center',
                gap: { xs: '8px', lg: '16px' },
                fontSize: { xs: '16px', md: '20px' },
                color: 'white',
                fontWeight: '700',
                textDecoration: { xs: 'underline', md: 'initial' },
                justifyContent: { xs: 'center', md: 'initial' },
                height: { xs: '64px', md: 'auto' },
                boxShadow: {
                  xs: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  md: 'initial'
                },
                border: { xs: 'double 1px transparent', md: 'initial' },
                backgroundImage: {
                  xs: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, rgba(235, 30, 46, 0.6) -10.2%,
                              rgba(155, 50, 136, 0.6) 51.5%, rgba(80, 67, 218, 0.6) 102.62%)
                          `,
                  md: 'initial'
                },
                backgroundOrigin: { xs: 'border-box', md: 'initial' },
                backgroundClip: {
                  xs: 'padding-box, border-box',
                  md: 'initial'
                },
                borderRadius: { xs: '20px', md: 'initial' }
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '16px',
                  border: 'double 1px transparent',
                  backgroundImage:
                  'linear-gradient(90deg, #40354A 0%, #32395A 100.14%),linear-gradient(90deg, #6DFFE7 0%, #55BEC0 100.14%)',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  transform: { xs: 'scale(0.6)', md: 'initial' }
                }}
              >
                <PhoneIcon />
              </Box>
              +995 (32) 2 40 23 23
            </Box>

            <Box
              sx={{
                mt: { xs: '12px', md: 'initial' },
                display: 'flex',
                alignItems: 'center',
                gap: { xs: '8px', lg: '16px' },
                fontSize: { xs: '16px', md: '20px' },
                color: 'white',
                fontWeight: '700',
                textDecoration: { xs: 'underline', md: 'initial' },
                justifyContent: { xs: 'center', md: 'initial' },
                height: { xs: '64px', md: 'auto' },
                boxShadow: {
                  xs: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  md: 'initial'
                },
                border: { xs: 'double 1px transparent', md: 'initial' },
                backgroundImage: {
                  xs: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, rgba(235, 30, 46, 0.6) -10.2%,
                              rgba(155, 50, 136, 0.6) 51.5%, rgba(80, 67, 218, 0.6) 102.62%)
                          `,
                  md: 'initial'
                },
                backgroundOrigin: { xs: 'border-box', md: 'initial' },
                backgroundClip: {
                  xs: 'padding-box, border-box',
                  md: 'initial'
                },
                borderRadius: { xs: '20px', md: 'initial' }
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '16px',
                  border: 'double 1px transparent',
                  backgroundImage:
                  'linear-gradient(90deg, #40354A 0%, #32395A 100.14%),linear-gradient(90deg, #6DFFE7 0%, #55BEC0 100.14%)',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  transform: { xs: 'scale(0.6)', md: 'initial' }
                }}
              >
                <MessageIcon />
              </Box>
              Info@betlive.club
            </Box>
          </Box>
        </Container>
      </Box>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: { md: '1192px', lg: '1280px' },
          paddingX: { xs: '16px', md: '40px', lg: '0px' }
        }}
        disableGutters={true}
      >
        <Box
          display='none'
          sx={{
            paddingTop: { xs: '50px', md: '80px', lg: '120px' },
            paddingBottom: { xs: '61px', md: '90px' },
            img: {
              content: {
                xs: 'url("/static/map-xl.png")',
                md: 'url("/static/map-xl.png")',
                xl: 'url("/static/map-xl.png")'
              },
              width: '100%'
            }
          }}
        >
          <img ref={mapRef} />
        </Box>
      </Container>
    </Box>
  )
}

export default AboutView
