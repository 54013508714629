import React, { useState, useEffect } from 'react'
import { Navigation, Pagination, Keyboard, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './hero-carousel.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SkeletonLoad from '../../SkeletonLoad/SkeletonLoad'
import { useBus } from 'react-bus'
import { NavLink } from 'react-router-dom'

interface CarouselProperties {
  items?: any
  breakpoints?: any
  pagination?: any
  name: string
  retrieveChange?: string
  loop?: boolean
  autoPlay?: object
  link?: number
  keyboard?: boolean
}

const HeroCarousel: React.FC<CarouselProperties> = ({ items, breakpoints, pagination = { clickable: true }, link, name, loop, autoPlay, retrieveChange, keyboard = true }) => {
  const bus = useBus()
  const [loading, setLoading] = useState<boolean>(true)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onChangeSlide = (event: any) => {
    if (retrieveChange != null) {
      bus.emit(retrieveChange, event)
    }
  }

  const checkVideo = (data: string): boolean => {
    return data?.includes('.mp4')
  }

  useEffect(() => {
    if (items !== undefined && items.length > 0 && loading) {
      setLoading(false)
    }
  }, [loading, items])
  return (
    <>
    { loading
      ? <SkeletonLoad/>
      : <Swiper
      modules={[Navigation, Pagination, Keyboard, Autoplay]}
      className="hero-carousel"
      initialSlide={0}
      navigation
      pagination={pagination}
      keyboard={keyboard}
      loop={loop}
      autoplay={autoPlay}
      breakpoints={breakpoints}
      onSlideChange={onChangeSlide}
      onSwiper={(swiper) => console.log(swiper)}
      lazy={true}
    >
      { items?.map((item: any, index: number) => (
        <SwiperSlide key={index}>
          <NavLink to={link !== undefined ? item?.fields?.[link]?.value : ''}>
            {/* eslint-disable-next-line multiline-ternary */}
            {!checkVideo(item?.full_src?.[name]) ? (
              <LazyLoadImage
                src={item?.full_src?.[name]}
                className="hero-carousel__image"
                // placeholderSrc='/static/bronze.svg'
              />
            ) : (
              <video width="100%" height="100%" autoPlay muted loop>
                <source src="https://new-cms.betlive.club/storage/gallery_image/2047/cGLverxOqrdFucblGRFTqBUWylWXsgrCJ8kVSufN.mp4" type="video/mp4" />
              </video>
            )}
          </NavLink>
        </SwiperSlide>
      )) }
    </Swiper>}
  </>
  )
}

export default HeroCarousel
