import React from 'react'

const YoutubeIcon: React.FC = () => {
  return (
      <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.38686 0.200082C4.58034 0.252119 2.93251 0.380476 2.41561 0.588623C1.94728 0.779425 1.55527 1.13674 1.32284 1.57732C1.02103 2.14973 0.861446 3.40555 0.823286 5.50436C0.795533 7.17995 0.88573 8.70289 1.07653 9.6673C1.28121 10.7184 1.84668 11.3186 2.8215 11.5233C3.70959 11.7106 6.06512 11.8216 9.15611 11.8182C12.313 11.8182 14.561 11.7141 15.5046 11.5233C15.8133 11.4608 16.2505 11.2423 16.4794 11.0411C16.7188 10.8295 16.9616 10.4652 17.0622 10.1669C17.3398 9.34815 17.5097 6.96486 17.4334 4.98746C17.3293 2.30237 17.1108 1.45937 16.3719 0.900844C16.0319 0.644129 15.796 0.550463 15.2687 0.456797C14.6165 0.345785 13.0485 0.24865 11.3937 0.213959C9.45792 0.175798 8.7641 0.172329 7.38686 0.200082ZM9.62444 4.75156C10.7935 5.42457 11.7475 5.99004 11.7475 6.00739C11.7441 6.03514 7.52909 8.4878 7.48399 8.4878C7.48052 8.4878 7.47358 7.37075 7.47358 6.00739C7.47358 4.64402 7.48052 3.52697 7.48746 3.52697C7.4944 3.52697 8.45534 4.07856 9.62444 4.75156Z" fill="#282937"/>
      </svg>

  )
}

export default YoutubeIcon
