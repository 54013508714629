import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Modal, Snackbar, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Page } from '../../services/api/page'
import { useTranslation } from 'react-i18next'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'
import CloseIcon from '../Icon/CloseIcon'

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    fontSize: '15px',
    padding: '0 6px',
    transform: 'translate(24px, 26px) scale(1)',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      transform: 'translate(18px, 20px) scale(1)'
    }
  },
  '& label.Mui-focused': {
    fontSize: '15px',
    backgroundColor: 'rgba(40, 41, 55, 1)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& label.MuiFormLabel-filled': {
    fontSize: '15px',
    backgroundColor: 'rgba(40, 41, 55, 1)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& .MuiOutlinedInput-root': {
    border: 'double 1px transparent',
    backgroundImage:
      'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '16.5px 14px',
    height: '74px',
    borderRadius: '20px',

    [theme.breakpoints.down('md')]: {
      height: '60px',
      fontSize: '12px',
      padding: '0 10px'
    },

    '& .MuiInputBase-input': {
      fontWeight: 'bold'
    }
  }
}))

interface ModalProperties {
  isOpen: any
  onClose: any
}

interface State {
  email: string
}

const UnsubscribeModal: React.FC<ModalProperties> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(isOpen)
  const [values, setValues] = React.useState<State>({
    email: ''
  })
  const [snackbarItem, setSnackbarItem] = useState({
    status: false,
    type: 'success',
    message: ''
  })
  const [loading, setLoading] = React.useState(false)

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert (
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues({ ...values, [prop]: event.target.value })
        }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setOpen(false)
    onClose(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleUnsubscribe = () => {
    setLoading(true)
    Page.unSubscribe(values)
      .then((data) => {
        setSnackbarItem({
          status: true,
          type: 'success',
          message: data.message
        })
        setTimeout(() => {
          setValues({
            email: ''
          })
          setLoading(false)
          handleClose()
        }, 2000)
      })
      .catch((err) => {
        setLoading(false)
        setSnackbarItem({
          status: true,
          type: 'error',
          message: err?.message
        })
      })
    return () => {}
  }

  useEffect(() => {
    setOpen(isOpen)
  })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', md: '50%' },
          bottom: { xs: '0', md: 'initial' },
          left: { xs: '0', md: '50%' },
          transform: { xs: 'initial', md: 'translate(-50%, -50%) scale(0.85)' },
          width: { xs: '100%', md: '735px' },
          backgroundColor: 'rgba(40, 41, 55, 1)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', md: '20px' }
        }}
      >
        <Snackbar open={snackbarItem.status} autoHideDuration={6000}>
          <Alert
            severity={
              snackbarItem.type !== ''
                ? (snackbarItem.type.toString() as AlertColor)
                : 'success'
            }
            sx={{ width: '100%' }}
          >
            {snackbarItem?.message}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '63px', md: '98px' },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <Typography
            sx={{ color: 'white', fontWeight: '900', fontSize: '24px' }}
          >
            {t('unsubscribe.title')}
          </Typography>
            <IconButton
                aria-label="close"
                sx={{ position: 'absolute', top: '27px', right: '27px', display: { xs: 'none', md: 'block' } }}
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>

        </Box>

        <Box sx={{ padding: { xs: '40px 16px 50px', md: '53px 52px 52px' } }}>
          <CssTextField
            label={t('unsubscribe.email')}
            disabled={loading}
            value={values.email}
            onChange={handleChange('email')}
            fullWidth
            id="custom-css-outlined-input"
          />

          <Button
            variant="contained"
            fullWidth
            disabled={loading || values.email === ''}
            onClick={handleUnsubscribe}
            sx={{
              height: { xs: '58px', md: '74px' },
              marginTop: { xs: '24px', md: '23px' },
              fontWeight: 'bold',
              borderRadius: '20px'
            }}
          >
            {t('unsubscribe.action')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default UnsubscribeModal
