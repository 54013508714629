import React, { useEffect, useState } from 'react'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'
import {
  getPromotionId,
  getPromotionImage,
  getPromotionShortDescription,
  getPromotionTitle,
  getPromotionTo
} from '../helpers/PromotionsHelper'
import { Container, Box, Grid } from '@mui/material'
import { Page } from '../services/api/page'
import PromotionCard from '../components/Promotion/PromotionCard'
import {
  PromotionPageSx,
  PromotionsPageHeaderSx
} from '../config/styles/promotion/PromotionStyles'
import PageHeaderMobile from '../components/Page/PageHeaderMobile'
import PageHeader from '../components/Page/PageHeader'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
// import PromotionCardSkeleton from '../components/Promotion/PromotionCardSkeleton'

const PromotionsListView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState<any[]>([])
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/promotions-bg-xs.jpg'
      : mediaQuerySmall
        ? '/static/promotions-bg-xs.jpg'
        : '/static/promotions-bg.jpg'
  )

  useEffect(() => {
    Page.getPromotionsPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])

  return (
    <Box sx={PromotionPageSx}>
      <PageHeaderMobile title={t('promotions.title')} />
      <Container
          maxWidth={false}
          sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: 'initial' } }}
          disableGutters={true}
      >
        <PageHeader title={t('promotions.title')} sx={PromotionsPageHeaderSx} />

        <Grid container spacing={3}>
          {page?.length > 0
            ? page?.map((item) => (
                <Grid item md={4} xs={12} key={getPromotionId(item)}>
                  <PromotionCard
                    to={getPromotionTo(item)}
                    image={getPromotionImage(item)}
                    title={getPromotionTitle(item)}
                    description={getPromotionShortDescription(item)}
                  />
                </Grid>
            ))
            : [...Array(6)].map((item) => (
                <Grid item md={4} sm={6} xs={12} key={item}>
                </Grid>
              ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default PromotionsListView
