import React, { ReactElement } from 'react'
import { PageHeaderInterface } from '../../interfaces/page/PageHeaderInterface'
import { Box, Typography } from '@mui/material'
import { PageHeaderSx } from '../../config/styles/page/PageStyles'

/**
 *
 * @param title
 * @param sx
 * @constructor
 */
const PageHeader = ({ title, sx }: PageHeaderInterface): ReactElement => {
  return (
    <Box sx={sx}>
      <Typography variant="h1" sx={PageHeaderSx}>
        {title}
      </Typography>
    </Box>
  )
}

export default PageHeader
