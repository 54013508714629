import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './SkeletonLoad.css'

const SkeletonLoad: React.FC = () => {
  return (
    <>
    <Skeleton count={1} width={'100%'} height={'100%'} className='skeleton-load '/>
    </>
  )
}

export default SkeletonLoad
