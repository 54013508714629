import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Divider,
  Grid
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import LeaderBordMiniCard from '../components/LeaderBoardMiniCard'
import { useParams } from 'react-router-dom'
import { Page } from '../services/api/page'
import { useTranslation } from 'react-i18next'

const LeaderBoardSingle: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const [rewarded, setRewarded] = useState<any[]>([])
  const [page, setPage] = useState<any[]>([])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getPosition = (number: number) => {
    return ++number
  }

  useEffect(() => {
    Page.getBranchSinglePage(id)
      .then((data) => {
        setPage(data?.items?.filter((item: any, index: any) => index > 2))
        setRewarded(data?.items?.filter((item: any, index: any) => index <= 2))
        void Page.fetchPageInfo('leaderboard')
      })
      .catch((err) => {
        console.log(err)
      })
  }, [i18n.language])

  return (
      <Box
          sx={{
            background: 'url("/static/bg.jpg") no-repeat',
            backgroundSize: 'cover',
            padding: '102px 0'
          }}
      >
          <Container
              maxWidth={false}
              sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
              disableGutters={true}
          >
      <Typography
        variant="h1"
        display="flex"
        justifyContent="center"
        sx={{
          fontStyle: 'normal',
          fontSize: '48px',
          fontWeight: '750',
          background:
            'linear-gradient(131.25deg, #6DFFE7 11.5%, #FFFFFF 124.9%)',
          webkitBackgroundClip: 'text',
          webkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          textFillColor: 'transparent',
          mb: '120px',
          mt: '50px'
        }}
      >
          {t('leaderboard.title')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          // paddingLeft: { xs: '100vw' },
          width: { xs: '100%' },
          overflow: { xs: 'auto' },
          justifyContent: 'space-around'
        }}
      >
          {rewarded?.map((item, index) => (
              <LeaderBordMiniCard index={index} item={item} key={index} />
          ))}
        {/* <LeaderBordMiniCard bColor="gold" color="gold" /> */}
        {/* <LeaderBordMiniCard bColor="silver" color="silver" /> */}
        {/* <LeaderBordMiniCard color="" /> */}
      </Box>

      <List
        sx={{
          width: '100%',
          bgcolor: 'rgba(23, 24, 34, 0.3)',
          marginY: '85px',
          padding: '0',
          borderRadius: '50px'
        }}
      >
        {page?.map((item, index) => (
          <>
            <ListItem alignItems="center" sx={{ padding: '16px' }}>
              <Typography
                sx={{
                  width: '74px',
                  fontSize: '40px',
                  fontWeight: '750',
                  color: 'white'
                }}
              >
                  {getPosition(3 + index)}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  fontWeight: 'bold'
                }}
              >
                  <Grid container spacing={2}>
                      <Grid item xs={4}>
                          <Box sx={{ color: 'white', textAlign: 'center' }}>{item?.user_name}</Box>
                      </Grid>
                      <Grid item xs={4}>
                          <Box sx={{ color: 'white', textAlign: 'center' }}>{item?.bonus}</Box>
                      </Grid>
                      <Grid item xs={4}>
                          <Box sx={{ color: 'white', textAlign: 'right' }}>{item?.duration}</Box>
                      </Grid>
                  </Grid>
              </Box>
            </ListItem>
            {index !== (page?.length - 1) && (
            <Divider
              variant="inset"
              component="li"
              sx={{ borderColor: 'rgba(188, 187, 198, 0.4)', marginLeft: '86px' }}
            />
            )}
          </>
        ))}
      </List>
    </Container>
      </Box>
  )
}

export default LeaderBoardSingle
