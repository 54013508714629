import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'

interface CardItemProperties {
  user_name: String
  bonus: String
  duration: String
}

interface CardProperties {
  item: CardItemProperties
  index: Number
}

const LeaderBordMiniCard: React.FC<CardProperties> = ({ item, index }) => {
  const star = index === 0
    ? '/static/gold.svg'
    : index === 1
      ? '/static/silver.svg'
      : '/static/bronze.svg'

  return (
    <>
      <Card
        sx={{
          background: 'rgba(23, 24, 34, 0.3)',
          width: 350,
          paddingBottom: 'unset !important',
          p: 5,
          margin: { lg: 8, xs: 2 },
          marginTop: { lg: 8, xs: 9 },
          marginLeft: { lg: 8, xs: index === 0 ? 70 : 2 },
          position: 'relative',
          overflow: 'unset',
          borderRadius: '50px',
          border: 'double 1px transparent',
          backgroundImage: index === 0
            ? `linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(131.25deg, #FFD99C 11.5%, #DE753D 124.9%)`
            : index === 1
              ? `linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(131.25deg, rgba(255, 255, 255, 1) 11.5%, rgba(80, 80, 80, 1) 124.9%)`
              : `linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(131.25deg, rgba(250, 222, 207, 1) 11.5%, rgba(144, 90, 77, 1) 124.9%)`,
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box'
        }}
      >
        <Typography
          component="div"
          width={120}
          height={120}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            left: '115px',
            top: '-60px',
            border: 'double 1px transparent',
            backgroundImage: index === 0
              ? `linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(131.25deg, #FFD99C 11.5%, #DE753D 124.9%)`
              : index === 1
                ? `linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(131.25deg, rgba(255, 255, 255, 1) 11.5%, rgba(80, 80, 80, 1) 124.9%)`
                : `linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(131.25deg, rgba(250, 222, 207, 1) 11.5%, rgba(144, 90, 77, 1) 124.9%)`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
            zIndex: 2

          }}
        >
          <img src={star} height={60} alt="ss"/>
        </Typography>
        <Typography
          component="div"
          width={120}
          height={120}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            left: '115px',
            top: '-60px',
            background: '#2e2f3d',
            filter: 'drop-shadow(0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
            backdropFilter: 'blur(44px)',
            zIndex: 0

          }}
        />
        <CardContent
          sx={{
            p: '60px 0 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            mb="40px"
            sx={{
              fontWeight: '500',
              lineHeight: '24px',
              color: '#fff'
            }}
          >
              {item?.user_name}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 750,
              fontSize: '64px',
              background:
                index === 0
                  ? 'linear-gradient(131.25deg, #FFD99C 11.5%, #DE753D 124.9%)'
                  : index === 1
                    ? 'linear-gradient(119.96deg, #FFFFFF 10.15%, #777777 115.08%)'
                    : 'linear-gradient(131.25deg, #FADECF 11.5%, #905A4D 124.9%)',
              webkitBackgroundClip: 'text',
              webkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              textFillColor: 'transparent'
            }}
          >
              {item?.bonus}
          </Typography>
          <Typography
            variant="body1"
            pb="12px"
            sx={{
              color: '#fff',
              fontWeight: 500
            }}
          >
              {item?.duration}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

export default LeaderBordMiniCard
