import React, { useEffect, useState } from 'react'
import { Container, Box } from '@mui/material'
import TermsHeadingIcon from '../components/Icon/TermsHeadingIcon'
import { Page } from '../services/api/page'
import PageContent from '../components/Page/PageContent'
import { getPageContent } from '../helpers/PageHelper'
import PageHeaderMobile from '../components/Page/PageHeaderMobile'
import { PageHeroTermsTitleSx, TermsPageSx } from '../config/styles/page/PageStyles'
import PageHero from '../components/Page/PageHero'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'
import useMediaQuery from '@mui/material/useMediaQuery'

const TermsView: React.FC = () => {
  const [page, setPage] = useState({
    title: '',
    content: ''
  })
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/terms-bg-mobile.jpg'
      : mediaQuerySmall
        ? '/static/terms-bg-small.jpg'
        : '/static/terms-bg.jpg'
  )

  useEffect(() => {
    Page.getTermsPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [])

  return (
    <Box sx={TermsPageSx}>
      <PageHeaderMobile title="Terms & Conditions" />
      <Container
        maxWidth={false}
        sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
        disableGutters={true}
        >
        <PageHero
          title="Terms & Conditions"
          icon={<TermsHeadingIcon width={399} height={399} />}
          typographySx={PageHeroTermsTitleSx}
        />

        <PageContent content={getPageContent(page)} />
      </Container>
    </Box>
  )
}

export default TermsView
