import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const PageHeaderSx = {
  display: { xs: 'none', md: 'inline-block' },
  fontSize: '53px',
  fontWeight: '800',
  backgroundImage: 'linear-gradient(90deg, #E2F2FF -34.13%, #0085FF 124.6%)',
  backgroundClip: 'text',
  color: 'transparent'
}

export const PageHeaderMobileSx = {
  display: { xs: 'flex', md: 'none' },
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '74px'
}

export const TermsPageSx = {
  // backgroundImage: {
  //   xs: 'url("/static/home-bg-mobile.jpg")',
  //   sm: 'url("/static/promotions-bg.jpg")'
  // },
  // minHeight: { xs: '100vh', sm: 'initial' },
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
  paddingTop: { xs: '0', md: '102px' },
  paddingBottom: { xs: '50px', md: '120px' }
}

export const PageContentPaperSx = {
  p: { xs: 0, md: '16px 34px' },
  marginTop: { xs: '30px', md: '60px' },
  background: { xs: 'transparent', md: 'rgba(23, 24, 34, 0.1)' }
}

export const PageContentTypographySx = {
  fontSize: { xs: '14px', md: '16px' }
}

export const PageHeroSx = {
  position: 'relative',
  width: '100%',
  marginTop: { xs: 0, md: '23px' },
  padding: { xs: '0 34px', md: '0 88px' },
  height: { md: '485px', xs: '200px' },
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(38, 44, 60, 0.5)',
  backgroundFilter: 'blur(25px)',
  borderRadius: { xs: '40px', md: '50px' }
}

export const PageHeroBoxSx = {
  gap: { xs: '24px', md: '70px' }
}

export const PageHeroTermsTitleSx = {
  display: 'inline-block',
  width: '70%',
  fontSize: {
    xs: '32px',
    md: '90px',
    lg: '96px'
  },
  fontWeight: 'bold',
  backgroundImage:
      'linear-gradient(360deg, #0193FD -16.41%, #00FDC0 102.67%)',
  backgroundClip: 'text',
  color: 'transparent'
}

export const PageHeroPrivacyTitleSx = {
  display: 'inline-block',
  width: '42%',
  fontSize: {
    xs: '32px',
    md: '90px',
    lg: '96px'
  },
  fontWeight: 'bold',
  backgroundImage:
      'linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%)',
  backgroundClip: 'text',
  color: 'transparent'
}

export const PageHeroIcon = styled(Box)(({ theme }) => ({
  '&': {
    '& svg': {
      width: '359px',
      height: '359px',

      [theme.breakpoints.down('lg')]: {
        width: '259px',
        height: '259px'
      },

      [theme.breakpoints.down('md')]: {
        width: '83px',
        height: '83px'
      }
    }
  }
}))
