import React from 'react'

interface IconProperties {
  width: number
  height: number
}

const PrivacyHeadingIcon: React.FC<IconProperties> = ({ width, height }) => {
  return (
      <svg width={width} height={height} viewBox="0 0 399 399" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M174.396 37.0738L91.4375 68.3288C72.3187 75.4776 56.6912 98.0876 56.6912 118.37V241.894C56.6912 261.511 69.6587 287.28 85.4525 299.084L156.94 352.45C180.381 370.073 218.951 370.073 242.392 352.45L313.88 299.084C329.674 287.28 342.641 261.511 342.641 241.894V118.37C342.641 97.9213 327.014 75.3113 307.895 68.1626L224.936 37.0738C210.805 31.9201 188.195 31.9201 174.396 37.0738Z" stroke="url(#paint0_linear_1079_5546)" strokeWidth="20" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M150.456 197.339L177.222 224.105L248.71 152.618" stroke="url(#paint1_linear_1079_5546)" strokeWidth="20" strokeLinecap="round" strokeLinejoin="round"/>
          <defs>
              <linearGradient id="paint0_linear_1079_5546" x1="27.5127" y1="199.438" x2="350.144" y2="199.438" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F993E6"/>
                  <stop offset="1" stopColor="#BAB4FF"/>
              </linearGradient>
              <linearGradient id="paint1_linear_1079_5546" x1="140.43" y1="188.361" x2="251.288" y2="188.361" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F993E6"/>
                  <stop offset="1" stopColor="#BAB4FF"/>
              </linearGradient>
          </defs>
      </svg>
  )
}

export default PrivacyHeadingIcon
