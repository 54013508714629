import React from 'react'

interface IconProperties {
  width: number
  height: number
}

const BranchIcon: React.FC<IconProperties> = ({ width, height }) => {
  return (
      <svg color="#55BEC0" width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.4429 8.40039L29.042 16.9996L20.4429 25.5987" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M4.95996 17H28.8025" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

  )
}

export default BranchIcon
