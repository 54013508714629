import { ThemeOptions } from '@mui/material/styles/createTheme'
import { createTheme } from '@mui/material/styles'

export const theme: ThemeOptions = createTheme({
  typography: {
    fontFamily: ['Helvetica Neue LT GEO', 'sans-serif'].join(',')
  },
  palette: {
    background: {
      paper: '#2E2F3D',
      default: '#2E2F3D'
    }
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none'
  ],
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 1212,
      lg: 1367,
      xl: 1600
      // xs: 1381,
      // sm: 1381,
      // md: 1381,
      // lg: 1381,
      // xl: 1381
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body { line-height: 1 }
        @font-face {
          font-family: 'Helvetica Neue LT GEO';
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-65Medium.eot');
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-65Medium.eot?#iefix') format('embedded-opentype'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-65Medium.woff2') format('woff2'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-65Medium.woff') format('woff'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-65Medium.ttf') format('truetype');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        
        @font-face {
          font-family: 'Helvetica Neue LT GEO';
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-Bold.eot');
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-Bold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-Bold.woff2') format('woff2'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-Bold.woff') format('woff'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-Bold.ttf') format('truetype'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-Bold.svg#HelveticaNeueLTGEOW82-75Bd') format('svg');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }
        
        @font-face {
          font-family: 'Helvetica Neue LT GEO';
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-55Roman.eot');
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-55Roman.eot?#iefix') format('embedded-opentype'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-55Roman.woff2') format('woff2'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-55Roman.woff') format('woff'),
          url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-55Roman.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
        
        @font-face {
          font-family: 'Helvetica Neue LT GEO';
          src: url('/HelveticaNeue/HelveticaNeueLTGEO-75Bold.eot');
          src: url('/HelveticaNeue/HelveticaNeueLTGEO-75Bold.eot?#iefix') format('embedded-opentype'),
              url('/HelveticaNeue/HelveticaNeueLTGEO-75Bold.woff2') format('woff2'),
              url('/HelveticaNeue/HelveticaNeueLTGEO-75Bold.woff') format('woff'),
              url('/HelveticaNeue/HelveticaNeueLTGEO-75Bold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Helvetica Neue LT GEO';
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-85Heavy.eot');
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-85Heavy.eot?#iefix') format('embedded-opentype'),
              url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-85Heavy.woff2') format('woff2'),
              url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-85Heavy.woff') format('woff'),
              url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-85Heavy.ttf') format('truetype');
          font-weight: 900;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Helvetica Neue LT GEO';
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-95Black.eot');
          src: url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-95Black.eot?#iefix') format('embedded-opentype'),
              url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-95Black.woff2') format('woff2'),
              url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-95Black.woff') format('woff'),
              url('/fonts/HelveticaNeue/HelveticaNeueLTGEO-95Black.ttf') format('truetype');
          font-weight: 900;
          font-style: normal;
          font-display: swap;
      }
      `
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '102px'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: '44px',
          fontSize: '16px',
          textTransform: 'inherit',
          padding: '0 22px',
          borderRadius: '50px'
        },

        sizeLarge: {
          lineHeight: '80px',
          fontSize: '24px',
          fontWeight: '750',
          padding: '0 56px'
        },

        containedPrimary: {
          background: 'linear-gradient(90deg, #6DFFE7 -13%, #55BEC0 108%)',
          '&:hover': {
            background: 'linear-gradient(90deg, #55BEC0 -13%, #55BEC0 108%)'
          }
        },

        outlined: {
          color: 'white',
          border: 'double 1px transparent',
          backgroundImage:
            'linear-gradient(90deg, #2F3953 0%, #2F3953 100.14%),linear-gradient(90deg, #55BEC0 0%, #55BEC0 100.14%)',
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          '&:hover': {
            border: 'double 1px transparent',
            backgroundImage:
              'linear-gradient(90deg, #55BEC0 -13%, #55BEC0 108%)'
          }
        },

        outlinedSecondary: {
          color: 'white',
          border: 'double 1px transparent',
          backgroundImage:
            'linear-gradient(90deg, #624257 0%, #5d4059 100.14%), linear-gradient(90deg, #FFA4AB 0%, #E05EC9 100.14%)',
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          '&:hover': {
            border: 'double 1px transparent',
            backgroundImage:
              'linear-gradient(90deg, #624257 0%, #5d4059 100.14%), linear-gradient(90deg, #FFA4AB 0%, #E05EC9 100.14%)'
          }
        },

        outlinedInfo: {
          color: 'white',
          border: 'double 1px transparent',
          backgroundImage:
            'linear-gradient(90deg, #294868 0%, #263747 100.14%), linear-gradient(90deg, rgba(144, 206, 255, 1) 0%, rgba(0, 71, 255, 1) 100.14%)',
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          '&:hover': {
            border: 'double 1px transparent',
            backgroundImage:
              'linear-gradient(90deg, #294868 0%, #294868 100.14%), linear-gradient(90deg, rgba(144, 206, 255, 1) 0%, rgba(0, 71, 255, 1) 100.14%)'
          }
        },

        outlinedWarning: {
          color: 'white',
          border: 'double 1px transparent',
          backgroundImage:
            'linear-gradient(90deg, #3f3a40 0%, #3d373c 100.14%), linear-gradient(90deg, rgba(255, 217, 156, 1) 0%, rgba(222, 117, 61, 1) 100.14%)',
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          '&:hover': {
            border: 'double 1px transparent',
            backgroundImage:
              'linear-gradient(90deg, #3f3a40 0%, #3d373c 100.14%), linear-gradient(90deg, rgba(255, 217, 156, 1) 0%, rgba(222, 117, 61, 1) 100.14%)'
          }
        },

        startIcon: {
          marginLeft: '-19px',
          marginRight: '6px',
          paddingLeft: '14px',
          paddingRight: '0'
        }
      }
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: 'rgba(42, 43, 54, 0.6)',

          '&:last-child': {
            paddingBottom: 0
          }
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: 'rgba(23, 24, 34, 0.1)',
          borderRadius: '20px'
        }
      }
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          color: 'white',
          border: 'double 1px transparent',
          backgroundImage:
            'linear-gradient(90deg, #2a274a 0%, #343061 100.14%),linear-gradient(90deg, rgba(144, 206, 255, 1) 0%, rgba(0, 71, 255, 1) 100.14%)',
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          margin: '0 0 24px !important',
          borderRadius: '20px',

          '&:last-of-type': {
            margin: '0 0 24px !important',
            borderRadius: '20px'
          },

          '&::before': {
            display: 'none'
          }
        },

        expanded: {
          margin: '0'
        }
      }
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '74px',
          padding: '0 16px'
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
          marginBottom: '14px',
          padding: '0 32px',
          textTransform: 'initial',

          '&.Mui-selected': {
            background:
              'linear-gradient(90deg, rgba(249, 147, 230, 0.1) -10.2%, rgba(186, 180, 255, 0.1) 102.62%)',
            color: 'white'
          },

          '&:hover': {
            background:
              'linear-gradient(90deg, rgba(249, 147, 230, 0.05) -10.2%, rgba(186, 180, 255, 0.05) 102.62%)'
          },

          '&:first-child': {
            background: 'transparent',
            cursor: 'default'
          }
        }
      }
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          background:
            'linear-gradient(150.67deg, #FFA4AB 6.58%, #E05EC9 160.16%)',
          width: '6px',
          left: 0,
          borderRadius: '0px 20px 20px 0px'
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: 'transparent',
          outline: 'none',

          '&:hover': {
            borderColor: 'transparent',
            outline: 'none'
          }
        }
      }
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.6)',

          '&.Mui-focused': {
            fontSize: '16px',
            color: 'rgba(255, 255, 255, 0.6)'
          }
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'white',
          borderColor: 'transparent !important',
          outline: 'none',
          height: '100%',

          '&:hover': {
            borderColor: 'transparent !important',
            outline: 'none'
          }
        },

        input: {
          borderColor: 'transparent !important',
          outline: 'none',

          '&:hover': {
            borderColor: 'transparent !important',
            outline: 'none'
          }
        },

        notchedOutline: {
          borderColor: 'transparent !important',

          '&:focus': {
            borderColor: 'transparent !important',
            outline: 'none'
          },

          '&:hover': {
            borderColor: 'transparent !important',
            outline: 'none'
          }
        }
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: 'linear-gradient(90deg, #EB1E2E 0%, #5043DA 100.14%)',
          padding: '6px',
          borderRadius: '20px',

          '& svg': {
            borderRadius: '12px'
          },

          '& [data-popper-placement*="bottom"] .MuiTooltip-arrow': {
            marginTop: '0 !important'
          }
        },

        arrow: {
          left: '50% !important',
          bottom: '0 !important',
          color: 'transparent',
          marginTop: '0 !important',
          background: 'linear-gradient(90deg, #983E80 0%, #8C3F8D 100.14%)',
          transform: 'matrix(0.72, 0.69, -0.7, 0.72, 0, 0)  translateX(-50%) !important'
        }
      }
    },

    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            border: 'double 1px transparent',
            backgroundImage:
              'linear-gradient(90deg, rgba(23, 24, 34, 1) 0%, rgba(23, 24, 34, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 1) 0%, rgba(80, 67, 218, 1) 100.14%)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
            width: '305px',
            marginTop: '16px',
            marginLeft: '4px',
            transform: 'scale(0.85) !important'
          },

          '& .MuiList-root': {
            padding: 0
          },

          '& .MuiButtonBase-root': {
            position: 'relative',
            height: '58px',
            fontWeight: 'bold',
            padding: '0 30px',

            '&::after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: '-1px',
              left: 0,
              background:
                'linear-gradient(90deg, #FFA4AB -10.2%, #E05EC9 51.5%, #9E95FF 102.62%)',
              width: '100%',
              height: '1px'
            },

            '&:last-child::after': {
              display: 'none'
            }
          }
        }
      }
    }
  }
})
