import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Snackbar,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Page } from '../../services/api/page'
import { useTranslation } from 'react-i18next'
// import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Select from '@mui/material/Select'
import CloseIcon from '../Icon/CloseIcon'
import { sizePro } from '../../helpers/PageHelper'
import ArrowDownGradientIcon from '../Icon/ArrowDownGradientIcon'
import { AlertColor } from '@mui/material/Alert'

const CssSelectControl = styled(FormControl)(({ theme }) => ({
  '&': {
    border: 'double 1px transparent',
    backgroundImage:
      'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: `0 ${sizePro(14)}`,
    height: sizePro(64),
    borderRadius: '16px',

    [theme.breakpoints.down('lg')]: {
      height: sizePro(64)
    }
  },

  '& label': {
    fontSize: sizePro(15),
    color: 'rgba(255, 255, 255, 0.6)',
    padding: `0 ${sizePro(6)}`,
    transform: `translate(${23}, ${22}) scale(1)`,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      transform: 'translate(20px, 19px) scale(1)'
    }
  },

  '& label.Mui-error': {
    fontSize: sizePro(15),
    color: 'rgba(255, 255, 255, 0.6)',
    padding: `0 ${sizePro(6)}`,
    transform: { xs: `translate(${sizePro(24)}, ${sizePro(18)}) scale(1)` },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& label.Mui-focused': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(24)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: sizePro(20),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& label.MuiFormLabel-filled': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(24)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: sizePro(20),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& .MuiInputBase-root': {
    fontSize: sizePro(15),

    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker label': {
    fontSize: sizePro(15),
    color: 'rgba(255, 255, 255, 0.6)',
    padding: `0 ${6}`,
    transform: `translate(${10}, ${22}) scale(1)`,

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      transform: 'translate(6px, -10px) scale(1)'
    }
  },

  '&.datepicker label.Mui-error': {
    fontSize: sizePro(15),
    color: 'rgba(255, 255, 255, 0.6)',
    padding: 0,
    transform: { xs: `translate(${10}, ${26}) scale(1)` },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      transform: 'translate(12px, 18px) scale(1)'
    }
  },

  '&.datepicker label.Mui-focused': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(10)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: sizePro(20),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker label.MuiFormLabel-filled': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(10)}, -9px) scale(0.75)`,
    borderRadius: sizePro(20),
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker .MuiInputBase-input': {
    padding: `0 ${sizePro(14)}`
  }
}))

interface ModalProperties {
  isOpen: any
  onClose: any
}

interface State {
  branch_id: string
  address: string
}

const GetClubCardModal: React.FC<ModalProperties> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(isOpen)
  const [possibleOrder, setPossibleOrder] = React.useState(false)
  const [options, setOptions] = React.useState({
    branches: [],
    country: []
  })
  const [values, setValues] = React.useState<State>({
    branch_id: '',
    address: 'address'
  })
  const [snackbarItem, setSnackbarItem] = useState({
    status: false,
    type: 'success',
    message: ''
  })
  const [loading, setLoading] = React.useState(false)

  // const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert (
  //   props,
  //   ref
  // ) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  // })

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleBranchChange = (event: any) => {
    setValues({
      ...values,
      ...{ branch_id: event.target.value }
    })
  }

  // const handleChange =
  //   (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setValues({ ...values, [prop]: event.target.value })
  //   }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setOpen(false)
    onClose(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleGetCardData = () => {
    setLoading(true)
    Page.getClubCardData()
      .then((data) => {
        setOptions(data?.options)
        setPossibleOrder(true)
      })
      .catch((err) => {
        setPossibleOrder(false)
        setSnackbarItem({
          status: false,
          type: 'error',
          message: err?.response?.data?.message
        })
      })
    setLoading(false)
    return () => {}
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOrderCard = () => {
    setLoading(true)
    console.log(values)
    Page.orderClubCardData(values)
      .then((data) => {
        setTimeout(() => {
          setSnackbarItem({
            status: true,
            type: 'success',
            message: data?.message
          })
          setValues({
            branch_id: '',
            address: ''
          })
        }, 2000)
      })
      .catch((err) => {
        console.log(err, 'dsadsa')
        setLoading(false)
        setSnackbarItem({
          status: true,
          type: 'error',
          message: err?.response?.data?.message
        })
      })
    return () => {}
  }

  useEffect(() => {
    setOpen(isOpen)
  })

  useEffect(() => {
    handleGetCardData()
  }, [])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box
          sx={{
            position: 'absolute',
            top: { xs: 'initial', md: '50%' },
            bottom: { xs: '0', md: 'initial' },
            left: { xs: '0', md: '50%' },
            transform: { xs: 'initial', md: 'translate(-50%, -50%) scale(0.85)' },
            width: { xs: '100%', md: '735px' },
            backgroundColor: 'rgba(40, 41, 55, 1)',
            boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
            border: 'none',
            outline: 'none',
            borderRadius: { xs: '20px 20px 0 0', md: '20px' }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '97px',
              borderBottom: '1px solid #FFA4AB'
            }}
          >
            <Typography
              sx={{ color: 'white', fontWeight: '900', fontSize: { xs: '18px', md: '24px' } }}
            >
              {t('getClubCard.title')}
            </Typography>

            <IconButton
              aria-label="close"
              sx={{ position: 'absolute', top: '27px', right: '27px', display: { xs: 'none', md: 'block' } }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {possibleOrder
            ? (
              <Box sx={{ padding: { xs: '40px 16px 20px', md: '52px 52px 20px' } }}>
                <CssSelectControl
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('getClubCard.chooseBranch')}
                  </InputLabel>
                  <Select
                    value={values.branch_id}
                    fullWidth
                    IconComponent={ArrowDownGradientIcon}
                    id="gender"
                    onChange={handleBranchChange}
                  >
                    {options?.branches?.map((item: any) => (
                      <MenuItem
                        value={item.value}
                        key={item.value}
                        sx={{
                          fontSize: '13px'
                        }}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </CssSelectControl>

                {/* <CssTextField */}
                {/*   label={t('getClubCard.address')} */}
                {/*   disabled={loading} */}
                {/*   value={values.address} */}
                {/*   onChange={handleChange('address')} */}
                {/*   fullWidth */}
                {/*   id="custom-css-outlined-input" */}
                {/* /> */}

                <Button
                  variant="contained"
                  fullWidth
                  disabled={loading || values.branch_id === ''}
                  onClick={handleOrderCard}
                  sx={{
                    height: '74px',
                    marginTop: '40px',
                    fontWeight: 'bold',
                    borderRadius: '20px'
                  }}
                >
                  {t('getClubCard.order')}
                </Button>
              </Box>
              )
            : <Box sx={{ padding: { xs: '40px 16px 20px', md: '40px 52px' } }}>
              <Typography textAlign="center" color="white" fontWeight="bold">{snackbarItem?.message}</Typography>
              {/* <Typography */}
              {/*  sx={{ color: 'red', fontWeight: '900', fontSize: '24px' }} */}
              {/* > */}
              {/*  {t('getClubCard.youCanNotOrderACard')} */}
              {/* </Typography> */}
            </Box>}

        </Box>

        {<Snackbar open={snackbarItem.status} autoHideDuration={6000}>
          <Alert
            severity={
              snackbarItem.type !== ''
                ? (snackbarItem.type.toString() as AlertColor)
                : 'success'
            }
            sx={{ width: '100%' }}
          >
            {snackbarItem?.message}
          </Alert>
        </Snackbar>}
      </>
    </Modal>
  )
}

export default GetClubCardModal
