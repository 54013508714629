import React, { ReactElement, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { PromotionCardInterface } from '../../interfaces/promotion/PromotionCardInterface'
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material'
import {
  PromotionCardSx,
  PromotionCardMediaSx,
  PromotionCardContentSx,
  PromotionCardTitleSx,
  PromotionCardDescriptionSx
} from '../../config/styles/promotion/PromotionStyles'
import SkeletonLoad from '../SkeletonLoad/SkeletonLoad'

/**
 *
 * @param to
 * @param image
 * @param title
 * @param description
 * @constructor
 */
const PromotionCard = ({
  to,
  image,
  title,
  description
}: PromotionCardInterface): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    if (image !== undefined && loading) {
      setLoading(false)
    }
  }, [loading])
  return (
    <NavLink to={to}>
      <Card sx={PromotionCardSx}>
                    {loading
                      ? <Box
                  sx={{
                    borderRadius: '40px',
                    height: { xs: '180px', md: '200px' },
                    overflow: 'hidden'
                  }}
                  >
                    <SkeletonLoad/>
                    </Box>
                      : <CardMedia
                     component="img"
                     image={image}
                     alt={title}
                     sx={PromotionCardMediaSx}
                   />}
        <CardContent sx={PromotionCardContentSx}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={PromotionCardTitleSx}
          >
            {title}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: description }}
            sx={PromotionCardDescriptionSx}
          />
        </CardContent>
      </Card>
    </NavLink>
  )
}

export default PromotionCard
