import React from 'react'

const LogoSecondaryIcon: React.FC = () => {
  return (
    <svg width="180" height="70" id="a4b62fea-3298-45aa-8bf6-577026928c60" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -10 191.32 129.15">
    <defs>
        <style>{`
            .b1082647-6a8e-43a1-b032-1791c29b2b13 {
                fill: #1c2341;
            }

            .a7e6a46a-5d38-4849-866b-069b05f94704 {
                fill: #6dffe7;
            }
        `}
        </style>
    </defs>
    <g>
        <circle className="b1082647-6a8e-43a1-b032-1791c29b2b13" cx="131.75" cy="59.57" r="59.57"/>
        <g>
        <path className="a7e6a46a-5d38-4849-866b-069b05f94704" d="M23.35,99.5l.74-.52,1-3.18-.4-.52H7.05l2-6.2H30.36a1.3,1.3,0,0,1,.89.37,2.73,2.73,0,0,1,.66.92,3.94,3.94,0,0,1,.33,1.22,3.48,3.48,0,0,1-.11,1.28l-2.2,6.79c-.18.49-.31.88-.41,1.18a6.46,6.46,0,0,1-.28.73A4.73,4.73,0,0,1,29,102a1.21,1.21,0,0,1-.25.3l-.08,0a.92.92,0,0,0,.14.39,5.19,5.19,0,0,1,.24.59c.07.21.12.42.18.65a1.14,1.14,0,0,1,0,.58l-2.45,7.57a5.41,5.41,0,0,1-.7,1.27,7.27,7.27,0,0,1-1.13,1.22,6.55,6.55,0,0,1-1.28.93,2.59,2.59,0,0,1-1.16.36H.34L5.67,99.49ZM8.48,109.74H20.87l.73-.52,1-3.18-.21-.34H9.76Z" transform="translate(-0.34 -36.43)"/>
        <path className="a7e6a46a-5d38-4849-866b-069b05f94704" d="M27.35,115.93l8.74-26.85H58.42l-2,6.2H40.14L38.77,99.5h8.56l-2,6.2H36.75l-1.29,4H51.72l-2,6.19Z" transform="translate(-0.34 -36.43)"/>
        <path className="a7e6a46a-5d38-4849-866b-069b05f94704" d="M59.55,115.93l6.71-20.65H58.21l2-6.2H82.56l-2,6.2H72.32l-6.71,20.65Z" transform="translate(-0.34 -36.43)"/>
        </g>
        <g>
        <path className="a7e6a46a-5d38-4849-866b-069b05f94704" d="M95.35,98.78l-1.11.81L92.54,105l.67.88h31.51l-3.47,10.63H83.54a2.33,2.33,0,0,1-1.59-.62,4.7,4.7,0,0,1-1.14-1.59,6.4,6.4,0,0,1-.55-2.1,4.91,4.91,0,0,1,.25-2.18L84.8,96.79c1-3.2,2.44-5.44,4.2-6.72a9,9,0,0,1,5.25-1.92h25l1-.82,1.85-5.68-.67-.58H89.88l3.48-10.63H131a2.35,2.35,0,0,1,1.55.62,4.44,4.44,0,0,1,1.18,1.63,6.62,6.62,0,0,1,.55,2.14,5.55,5.55,0,0,1-.19,2.18l-4.28,13.15c-1,3.2-2.46,5.43-4.25,6.71a9,9,0,0,1-5.2,1.91Z" transform="translate(-0.34 -36.43)"/>
        <path className="a7e6a46a-5d38-4849-866b-069b05f94704" d="M136.91,98.78a6,6,0,0,1-2.33-.45,3.39,3.39,0,0,1-1.67-1.48,5.66,5.66,0,0,1-.62-2.65,12.27,12.27,0,0,1,.77-4.06l6.42-19.7H179L175.5,81.07H146.41l-2.06,6.28.58.81H170a5.64,5.64,0,0,1,2.29.45,3.51,3.51,0,0,1,1.66,1.48,5,5,0,0,1,.62,2.66,14.44,14.44,0,0,1-.73,4L169.54,110a8.19,8.19,0,0,1-1.22,2.18,14.16,14.16,0,0,1-1.92,2.1,12.25,12.25,0,0,1-2.19,1.59,4.12,4.12,0,0,1-2,.62H124.44l3.47-10.63h31.6l1.18-.88,1.69-5.38-.45-.82Z" transform="translate(-0.34 -36.43)"/>
        </g>
        <polygon className="a7e6a46a-5d38-4849-866b-069b05f94704" points="8.73 52.66 0 79.51 8.14 73.31 14.73 52.66 8.73 52.66"/>
    </g>
</svg>
  )
}

export default LogoSecondaryIcon
