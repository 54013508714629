import React, { useEffect } from 'react'
import { setCookie } from 'typescript-cookie'
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Page } from '../../services/api/page'
import CloseIcon from '../Icon/CloseIcon'
import { useTranslation } from 'react-i18next'
import { sizePro } from '../../helpers/PageHelper'
import { setTokenOutside } from '../../services/api/api'

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    fontSize: sizePro(15),
    padding: '0 6px',
    transform: `translate(${sizePro(24)}, ${sizePro(26)}) scale(1)`,

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      transform: 'translate(18px, 20px) scale(1)'
    }
  },
  '& label.Mui-focused': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(24)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& label.MuiFormLabel-filled': {
    fontSize: sizePro(15),
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 0%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: `translate(${sizePro(24)}, ${sizePro(-9)}) scale(0.75)`,
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& .MuiOutlinedInput-root': {
    border: 'double 1px transparent',
    backgroundImage:
      'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '16.5px 14px',
    height: sizePro(74),
    borderRadius: sizePro(20),

    [theme.breakpoints.down('md')]: {
      height: '60px',
      fontSize: '12px',
      padding: '0 10px'
    },

    '& .MuiInputBase-input': {
      fontWeight: 'bold'
    }
  }
}))

interface ModalProperties {
  isOpen: any
  onClose: any
  onSignUp: any
  onForgotPassword: any
  onSignInOk: any
}

interface State {
  phone_number: string
  password: string
}

const SignInModal: React.FC<ModalProperties> = ({
  isOpen,
  onForgotPassword,
  onSignUp,
  onSignInOk,
  onClose
}) => {
  const [open, setOpen] = React.useState(isOpen)
  const { t } = useTranslation()
  // const [showPassword, setShowPassword] = React.useState(false)
  const [errors, setErrors] = React.useState({
    errors: {
      phone_number: '',
      password: ''
    },
    message: ''
  })
  const [values, setValues] = React.useState<State>({
    phone_number: '',
    password: ''
  })

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setOpen(false)
    onClose(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSignIn = () => {
    Page.signIn(values)
      .then((data) => {
        if (data?.data?.access_token as boolean) {
          setTokenOutside(data?.data?.access_token)
          setCookie('token', data?.data?.access_token)
          onSignInOk()
          handleClose()
        }
      })
      .catch((err) => {
        setErrors(err?.response?.data)
        console.log(errors)
      })
    return () => {}
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword)
  // }

  useEffect(() => {
    setOpen(isOpen)
  })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', md: '50%' },
          bottom: { xs: '0', md: 'initial' },
          left: { xs: '0', md: '50%' },
          transform: { xs: 'initial', md: 'translate(-50%, -50%)' },
          width: { xs: '100%', md: sizePro(735) },
          backgroundColor: 'rgba(40, 41, 55, 1)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', md: '20px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '65px', md: sizePro(98) },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: '900',
              fontSize: { xs: '18px', md: sizePro(24) }
            }}
          >
              {t('login.title')}
          </Typography>

          <IconButton
            aria-label="close"
            sx={{ position: 'absolute', top: sizePro(27), right: sizePro(27), display: { xs: 'none', md: 'block' } }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ padding: { xs: '42px 16px 20px', md: `${sizePro(50)} ${sizePro(52)} ${sizePro(20)}` } }}>
            {((errors?.message) !== '') && (
                <Typography
                    sx={{
                      color: 'rgba(235, 30, 46, 1)',
                      paddingBottom: sizePro(24),
                      fontSize: sizePro(13),
                      fontWeight: 'bold'
                    }}
                >
                    {errors?.message}
                </Typography>
            )}
          <CssTextField
            label={t('login.phoneNumber')}
            value={values.phone_number}
            onChange={handleChange('phone_number')}
            fullWidth
            id="custom-css-outlined-input"
          />
          <Typography
            sx={{
              color: 'rgba(235, 30, 46, 1)',
              paddingTop: '10px',
              fontSize: sizePro(13),
              fontWeight: 'bold'
            }}
          >
            {errors?.errors?.phone_number}
          </Typography>
          <Box position="relative">
            <CssTextField
              label={t('login.password')}
              value={values.password}
              onChange={handleChange('password')}
              type="password"
              fullWidth
              id="custom-css-outlined-input"
              sx={{ marginTop: { xs: '15px', md: '11px' } }}
            />

            {/* <IconButton */}
            {/*  aria-label="close" */}
            {/*  sx={{ position: 'absolute', top: '27px', right: '27px' }} */}
            {/*  onClick={handleClickShowPassword} */}
            {/* > */}
            {/*  <CloseIcon /> */}
            {/* </IconButton> */}
          </Box>
          <Typography
            sx={{
              color: 'rgba(235, 30, 46, 1)',
              paddingTop: '10px',
              fontSize: '13px',
              fontWeight: 'bold'
            }}
          >
            {errors?.errors?.password}
          </Typography>

          <Button
            sx={{
              display: 'table',
              padding: '0',
              color: 'white',
              fontSize: { xs: '14px', lg: sizePro(16) },
              fontWeight: '700',
              margin: { xs: '4px auto', md: '5px auto 0' }
            }}
            onClick={onForgotPassword}
          >
              {t('login.passwordReset')}
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={handleSignIn}
            sx={{
              height: { xs: '58px', md: sizePro(74) },
              marginTop: { xs: '23px', md: '24px' },
              fontWeight: 'bold',
              borderRadius: '20px'
            }}
          >
              {t('login.buttonTitle')}
          </Button>

          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
              fontWeight: '700',
              gap: '3px',
              fontSize: { xs: '14px', md: sizePro(16) },
              textAlign: 'center',
              paddingTop: { xs: '38px', md: '20px' },
              paddingBottom: { xs: '27px', md: '0' }
            }}
          >
              {t('login.footerText')}
            <Button
              sx={{
                padding: '0',
                height: '0',
                color: 'rgba(255, 164, 171, 1)',
                fontSize: { xs: '14px', md: sizePro(16) },
                fontWeight: '700'
              }}
              onClick={onSignUp}
            >
                {t('headerAccount.singUp')}
            </Button>
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

export default SignInModal
