import React from 'react'

const GeorgiaCircleIcon: React.FC = () => {
  return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="white"/>
          <path d="M31.8646 13.9131H18.0871L18.087 0.135438C17.4038 0.0465 16.7073 0 16 0C15.2927 0 14.5962 0.0465 13.9131 0.135438V13.9129L0.135438 13.913C0.0465 14.5962 0 15.2927 0 16C0 16.7074 0.0465 17.4038 0.135438 18.0869H13.9129L13.913 31.8646C14.5962 31.9535 15.2927 32 16 32C16.7073 32 17.4038 31.9536 18.0869 31.8646V18.0871L31.8646 18.087C31.9535 17.4038 32 16.7074 32 16C32 15.2927 31.9535 14.5962 31.8646 13.9131Z" fill="#D80027"/>
          <path d="M9.56356 7.46687V5.3335H7.43025V7.46687H5.29688V9.60018H7.43025V11.7335H9.56356V9.60018H11.6969V7.46687H9.56356Z" fill="#D80027"/>
          <path d="M25.2331 7.65047V5.51709H23.0998V7.65047H20.9665V9.78378H23.0998V11.9171H25.2331V9.78378H27.3665V7.65047H25.2331Z" fill="#D80027"/>
          <path d="M9.56356 22.3999V20.2666H7.43025V22.3999H5.29688V24.5332H7.43025V26.6666H9.56356V24.5332H11.6969V22.3999H9.56356Z" fill="#D80027"/>
          <path d="M25.2331 22.5835V20.4502H23.0998V22.5835H20.9665V24.7168H23.0998V26.8502H25.2331V24.7168H27.3665V22.5835H25.2331Z" fill="#D80027"/>
      </svg>
  )
}

export default GeorgiaCircleIcon
