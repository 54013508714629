import { Container, Stack, Typography, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Page } from '../services/api/page'
import { useTranslation } from 'react-i18next'
import StreamCard from '../components/StreamCard'

const LeaderBoardListView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState<any[]>([])

  useEffect(() => {
    Page.getBranchListPage()
      .then((data) => {
        setPage(data)
        void Page.fetchPageInfo('leaderboard')
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])
  return (
      <Box
          sx={{
            backgroundImage: {
              xs: 'url("/static/home-bg-mobile.jpg")',
              sm: 'url("/static/bg.jpg")'
            },
            minHeight: { xs: '100vh', md: 'initial' },
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            paddingTop: { xs: '0', md: '102px' },
            paddingBottom: { xs: '50px', md: '140px' }
          }}
      >
          <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '74px'
              }}
          >
              <Typography color="white" fontWeight="bold">{t('streams.title')}</Typography>
          </Box>
          <Container
              maxWidth={false}
              sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
              disableGutters={true}
          >
      <Stack mt={{ xs: 0, md: '50px' }} mb={{ xs: 0, md: 0 }} spacing={{ xs: '20px', md: '40px' }}>
        <Typography
          variant="h1"
          display="flex"
          justifyContent="center"
          sx={{
            display: { xs: 'none', md: 'block' },
            fontStyle: 'normal',
            fontSize: '48px',
            fontWeight: '750',
            textAlign: 'center',
            backgroundImage: 'linear-gradient(90deg, #55BEC0 -34.13%, #FFFFFF 124.6%)',
            backgroundClip: 'text',
            color: 'transparent'
          }}
          mb={7}
        >
            {t('streams.title')}
        </Typography>
          {page?.map((item: any, index) => (
        <StreamCard type="" item={item} index={index} key={item?.id}/>
          ))}
      </Stack>
    </Container>
      </Box>
  )
}

export default LeaderBoardListView
