import React from 'react'
import {
  Box,
  Typography,
  Container,
  Link
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FacebookIcon from '../Icon/FacebookIcon'
import LinkedinIcon from '../Icon/LinkedinIcon'
import InstagramIcon from '../Icon/InstagramIcon'
import YoutubeIcon from '../Icon/YoutubeIcon'

const FooterMobile: React.FC = () => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          width: '100%',
          paddingTop: '18px',
          backgroundColor: 'rgba(23, 24, 34, 0.8)'
        }}
      >
        <Container sx={{ height: '100%', paddingX: '16px !important' }}>
          <Box
            sx={{
              display: { xs: 'flex', lg: 'none' },
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '100%',
              fontSize: '14px'
            }}
          >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', fontFeatureSettings: "'case' on" }}>
                  <NavLink to={`/${i18n.language}/about-us`}>
                      {t('menu.about')}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/careers`}>
                      {t('menu.career')}
                  </NavLink>
                  <NavLink to={`/${i18n.language}/terms-conditions`}>
                      {t('footer.termsConditions')}
                  </NavLink>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', fontFeatureSettings: "'case' on" }}>
                  <NavLink to={`/${i18n.language}/faq`}>{t('footer.faq')}</NavLink>
                  <NavLink to={`/${i18n.language}/privacy`}>
                      {t('footer.privacyPolicy')}
                  </NavLink>

                  <NavLink to="#">{t('footer.unsubscribe')}</NavLink>
              </Box>

            <Box
              sx={{
                display: 'none',
                flexDirection: 'column',
                gap: '40px',
                marginTop: '10px',
                marginLeft: 'auto'
              }}
            >
              <Typography color="white" fontSize="16px">
                Follow us
              </Typography>
            </Box>
          </Box>
        </Container>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '38px 16px'
          }}>
              <Box>
                  <Typography color="white" fontSize="14px" display="flex" alignItems="center" gap="12px">
                      <Box sx={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '12px',
                        border: 'double 1px transparent',
                        backgroundImage:
                              'linear-gradient(90deg, #2E1B25 0%, #1F1E36 100.14%),linear-gradient(90deg, #EB1E2E 0%, #5043DA 100.14%)',
                        backgroundOrigin: 'border-box',
                        backgroundClip: 'padding-box, border-box'
                      }}>
                          +25
                      </Box>
                      {t('footer.rightReserved')}
                  </Typography>
              </Box>

              <Box sx={{ display: 'flex', gap: '10px' }}>
                  <Link
                      href="https://facebook.com/betlive.club"
                      target="_blank"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '28px',
                        height: '28px',
                        background:
                              'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                        backdropFilter: 'blur(10px)',
                        textDecoration: 'none',
                        borderRadius: '50%'
                      }}
                  >
                      <FacebookIcon />
                  </Link>

                  <Link
                      href="https://www.linkedin.com/company/betlive-club"
                      target="_blank"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '28px',
                        height: '28px',
                        background:
                              'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                        backdropFilter: 'blur(10px)',
                        textDecoration: 'none',
                        borderRadius: '50%'
                      }}
                  >
                      <LinkedinIcon />
                  </Link>

                  <Link
                      href="https://instagram.com/betlive.club?igshid=1rgsxrbl4wlqf"
                      target="_blank"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '28px',
                        height: '28px',
                        background:
                              'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                        backdropFilter: 'blur(10px)',
                        textDecoration: 'none',
                        borderRadius: '50%'
                      }}
                  >
                      <InstagramIcon />
                  </Link>

                  <Link
                      href="#"
                      target="_blank"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '28px',
                        height: '28px',
                        background:
                              'linear-gradient(58.81deg, #FFDBDE -4.9%, #BAB4FF 107.67%)',
                        backdropFilter: 'blur(10px)',
                        textDecoration: 'none',
                        borderRadius: '50%'
                      }}
                  >
                      <YoutubeIcon />
                  </Link>
              </Box>
          </Box>
      </Box>
    </>
  )
}

export default FooterMobile
