import React from 'react'

interface IconProperties {
  width: string | number
  height: string | number
}

const BranchIcon: React.FC<IconProperties> = ({ width, height }) => {
  return (
    <svg
      style={{ zIndex: 2 }}
      width={width}
      height={height}
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.17857 21.1272C3.03902 21.1132 2.90011 21.0422 2.79171 20.9385C2.67023 20.8222 2.59286 20.6704 2.59286 20.5234V11.8281C2.59286 11.6787 2.67231 11.4842 2.7965 11.3258C2.92145 11.1663 3.08 11.0578 3.22857 11.0578H10.55V20.9656H3.22857H3.17857V21.0156V21.1272ZM31.05 16.9141V15.4375V11.8281C31.05 10.65 29.997 9.64531 28.7714 9.64531H28.4786V8.71094C28.4786 6.71253 26.7398 5.05156 24.6571 5.05156H22.976C21.9284 2.5857 19.1748 0.95 16.0857 0.95C12.9955 0.95 10.2437 2.58648 9.02694 4.8875H7.34286C5.26019 4.8875 3.52143 6.54846 3.52143 8.54688V9.64531H3.22857C2.00305 9.64531 0.95 10.65 0.95 11.8281V20.3594C0.95 21.5375 2.00305 22.5422 3.22857 22.5422H3.52143V23.6406C3.52143 25.639 5.26019 27.3 7.34286 27.3H24.8286C26.9112 27.3 28.65 25.639 28.65 23.6406V22.5422H28.9429C29.5515 22.5422 30.0782 22.3758 30.4537 22.0587C30.8301 21.7408 31.05 21.2752 31.05 20.6875V16.9141ZM16.0857 2.52656C18.266 2.52656 20.2756 3.47444 21.3086 5.05156H10.8629C11.8959 3.47444 13.9054 2.52656 16.0857 2.52656ZM5.16429 8.54688C5.16429 7.4281 6.16838 6.46406 7.34286 6.46406H24.8286C26.003 6.46406 27.0071 7.4281 27.0071 8.54688V9.64531H5.16429V8.54688ZM19.9786 11.2219V21.1297H12.0214V11.2219H19.9786ZM7.34286 25.8875C6.16838 25.8875 5.16429 24.9235 5.16429 23.8047V22.7062H27.0071V23.8047C27.0071 24.9235 26.003 25.8875 24.8286 25.8875H7.34286ZM29.5786 20.5234C29.5786 20.6728 29.4991 20.8673 29.3749 21.0258C29.25 21.1852 29.0914 21.2937 28.9429 21.2937H21.6214V11.2219H28.9429C29.2531 11.2219 29.5786 11.5223 29.5786 11.9922V20.5234Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="0.1"
      />
    </svg>
  )
}

export default BranchIcon
