import React from 'react'
import { Button, Card, CardActions, CardContent, CardMedia, Container, Paper, Stack, Typography } from '@mui/material'

const TestView: React.FC = () => {
  return (
      <Container fixed>
          <Stack mt={4} spacing={3}>
              <Paper sx={{ p: 5 }}>
                  <Button variant="contained">Medium</Button>
                  <Button variant="contained" size="large">Large</Button>
              </Paper>

              <Typography
                  gutterBottom
                  sx={{
                    fontWeight: 'bold',
                    color: '#00FDC0'
                  }}
              >
                  Styled Text
              </Typography>

              <Paper sx={{
                p: 5,
                border: 'double 1px transparent',
                backgroundImage:
                      `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(90deg, rgba(235, 30, 46, 0.6) -10.2%,
                              rgba(155, 50, 136, 0.6) 51.5%, rgba(80, 67, 218, 0.6) 102.62%)
                          `,
                backgroundOrigin: 'border-box',
                backgroundClip: 'padding-box, border-box'
              }}>
                  <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        display: 'inline-block',
                        fontWeight: 'bold',
                        backgroundImage:
                              `
                                    linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%),
                                    linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                  `,
                        backgroundClip: 'text',
                        color: 'transparent'
                      }}
                  >
                      Heading
                  </Typography>
                  <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 'bold'
                      }}
                  >
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                      blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
                      neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                      quasi quidem quibusdam.
                  </Typography>
              </Paper>

              <Card sx={{ background: 'rgba(23, 24, 34, 0.3)', maxWidth: 446, p: 3 }}>
                  <CardMedia
                      component="img"
                      height="240"
                      image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                      alt="green iguana"
                      sx={{ borderRadius: '20px' }}
                  />
                  <CardContent sx={{ p: '18px 0 0' }}>
                      <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                          Lizard
                      </Typography>
                      <Typography variant="body2" color="text.white">
                          Lizards are a widespread group of squamate reptiles, with over 6,000
                          species, ranging across all continents except Antarctica
                      </Typography>
                  </CardContent>
              </Card>

              <Card sx={{ background: 'transparent', maxWidth: '100%', display: 'flex' }}>
                  <CardMedia
                      component="img"
                      height="360"
                      image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                      alt="green iguana"
                      sx={{ width: 'calc(100% - 475px)', borderRadius: '20px' }}
                  />
                  <CardContent sx={{ width: '475px', pl: '46px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Typography gutterBottom variant="h5" component="div" sx={{ color: '#FFEAEB', fontWeight: 'bold' }}>
                          Lizard
                      </Typography>
                      <Typography variant="body2" color="text.white">
                          Lizards are a widespread group of squamate reptiles, with over 6,000
                          species, ranging across all continents except Antarctica
                      </Typography>

                      <Button variant="outlined" color="secondary" sx={{ width: '191px', height: '56px', mt: '47px', borderRadius: '20px' }}>
                          <Typography
                              sx={{
                                fontWeight: 'bold',
                                backgroundImage:
                                      `
                                            linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                                backgroundClip: 'text',
                                color: 'transparent',
                                borderBottom: '1px solid #fcc1e1',
                                marginTop: '-4px',
                                height: '21px'
                              }}
                          >
                              See more
                          </Typography>
                      </Button>
                  </CardContent>
              </Card>

              <Card sx={{ background: 'transparent', maxWidth: 442 }}>
                  <CardMedia
                      component="img"
                      height="300"
                      image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                      alt="green iguana"
                      sx={{ borderRadius: '20px' }}
                  />
                  <CardContent sx={{ p: '18px' }}>
                      <Typography gutterBottom variant="h5" component="div" sx={{ color: '#FFEAEB', fontWeight: 'bold' }}>
                          Lizard
                      </Typography>
                      <Typography variant="body2" color="text.white">
                          Lizards are a widespread group of squamate reptiles, with over 6,000
                          species, ranging across all continents except Antarctica
                      </Typography>
                  </CardContent>

                  <CardActions sx={{ px: '18px' }}>
                      <Button variant="outlined" color="secondary" sx={{ height: '56px', px: '60px', borderRadius: '20px' }}>
                          <Typography
                              sx={{
                                fontWeight: 'bold',
                                backgroundImage:
                                      `
                                            linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                                backgroundClip: 'text',
                                color: 'transparent',
                                borderBottom: '1px solid #fcc1e1',
                                marginTop: '-4px',
                                height: '21px'
                              }}
                          >
                              See more
                          </Typography>
                      </Button>
                  </CardActions>
              </Card>
          </Stack>
      </Container>
  )
}

export default TestView
