import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import React from 'react'
import BrownBranchIcon from './Icon/BrownBranchIvon'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

interface CardItemProperties {
  title: String
  address: String
  id: Number
}

interface CardProperties {
  type: String
  item: CardItemProperties
  index: any
}

const CardActionStyle = styled(CardActions)(({ theme }) => ({
  '&': {
    '& a': {
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      }
    }
  }
}))

const LeaderBoardCard: React.FC<CardProperties> = (type) => {
  const { t, i18n } = useTranslation()
  const { item, index } = type
  return (
    <>
      <Card
        sx={{
          p: { xs: '16px', md: 3 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: { md: index % 2 === 0 ? 'initial' : 'space-between' },
          paddingBottom: '24px !important',
          backgroundColor: 'rgba(23, 24, 34, 0.3)',
          height: { xs: 'auto', md: '360px' },
          flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' },
          borderRadius: '50px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexBasis: '61%',
            // border: 'double 1px #FFD99C',
            width: { xs: '100%', md: 'auto' },
            background:
              'linear-gradient(90deg, rgba(62, 62, 77, 0.8) -1.54%, rgba(42, 43, 54, 0) 102.63%)',
            filter: 'drop-shadow(0px 27.543px 48.4377px rgba(0, 0, 0, 0.25))',
            backdropFilter: 'blur(44px)',
            borderRadius: '50px',
            padding: '36px 0 42px 0'
          }}
        >
          <BrownBranchIcon width={150} height={147} />

          {/* <CardMedia
            component="img"
            alt="gold star"
            image="/static/leaderboard-star.svg"
            sx={{
              width: { xs: '107px', md: '230px' },
              position: 'relative',
              top: '20px',
              height: { xs: '107px', md: '230px' }
            }}
          /> */}
        </Box>
        <Box sx={{ flexBasis: '35%', width: { xs: '100%', md: 'auto' }, paddingLeft: { md: index % 2 === 0 ? '0' : '40px' }, marginLeft: { xs: '0', md: index % 2 === 0 ? '34px' : '0' } }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'start'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  marginTop: { xs: '12px', md: '18px' },
                  textAlign: 'center',
                  // paddingLeft: '15px',
                  fontStyle: 'normal',
                  fontWeight: 750,
                  fontSize: { xs: '16px', md: i18n.language === 'en' ? '32px' : '24px' },
                  lineHeight: '34px',
                  background:
                    'linear-gradient(90deg, #6DFFE7 -34.13%, #FFFFFF 124.6%)',
                  webkitBackgroundClip: 'text',
                  webkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent'
                }}
                variant="body1"
                color="white"
                gutterBottom
              >
                  {item?.title}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: { xs: '13px', lg: '16px' },
                textAlign: 'start',
                paddingTop: '10px'
              }}
              color="#ffffff"
              gutterBottom
            >
                {item?.address}
            </Typography>
          </CardContent>
          <CardActionStyle>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              <NavLink to={`/${i18n.language}/branch/leaderboard/${item?.id}`}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: { xs: '100%', md: 'auto' },
                height: '56px',
                px: '32px',
                borderRadius: '20px',
                border: 'double 1px #55BEC0',
                // backgroundImage:
                //   'linear-gradient(90deg, #55BEC0 -13%, #55BEC0 108%)',
                boxSizing: 'border-box'
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  backgroundImage: `
                                            linear-gradient(131.25deg, #FFFFFF 11.5%, #FFFFFF 124.9%)
                                          `,
                  backgroundClip: 'text',
                  color: 'transparent',
                  borderBottom: '1px solid #FFFFFF',
                  marginTop: '-4px',
                  height: '21px'
                }}
              >
                  {t('leaderboard.seeMore')}
              </Typography>
            </Button>
              </NavLink>
          </CardActionStyle>
        </Box>
      </Card>
    </>
  )
}

export default LeaderBoardCard
