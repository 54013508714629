import React, { useEffect, useState } from 'react'
import { Box, Container, Stack } from '@mui/material'
import { Page } from '../services/api/page'
import CareerModal from '../components/Modal/CareerModal'
import {
  CareerPageHeaderSx,
  CareerPageSx,
  CareerStackSx
} from '../config/styles/career/CareerStyles'
import PageHeaderMobile from '../components/Page/PageHeaderMobile'
import PageHeader from '../components/Page/PageHeader'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'
import CareerCard from '../components/Career/CareerCard'
import { getCareerId, getCareerTitle } from '../helpers/CareerHelper'
// import CareerCardSkeleton from '../components/Career/CareerCardSkeleton'

const CareerView: React.FC = () => {
  const [page, setPage] = useState<any[]>([])
  const [careerModal, setCareerModal] = React.useState(false)
  const [item, setItem] = React.useState({})

  setBodyBackgroundUrl('/static/promotions-bg.jpg')

  const handleClose = (): void => {
    setCareerModal(false)
  }

  useEffect(() => {
    Page.getCareerPage()
      .then((data) => {
        setPage(data?.items)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [])

  return (
    <Box sx={CareerPageSx}>
      <PageHeaderMobile title="Career" />
      <Container
          maxWidth={false}
          sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
          disableGutters={true}
      >
        <PageHeader title="Career" sx={CareerPageHeaderSx} />
        <Stack spacing={3} sx={CareerStackSx}>
          {page?.map((item) => (
              <CareerCard
                  key={getCareerId(item)}
                  title={getCareerTitle(item)}
                  item={item}
                  setItem={() => {
                    setItem(item)
                    setCareerModal(true)
                  }}
              />
          ))}
        </Stack>
      </Container>

      <CareerModal item={item} isOpen={careerModal} onClose={handleClose} />
    </Box>
  )
}

export default CareerView
