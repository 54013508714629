import React, { useEffect, useState } from 'react'
import {
  Container,
  Box,
  Stack,
  CardMedia,
  CardContent,
  Typography,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material'
import ArrowDownIcon from '../components/Icon/ArrowDownIcon'
import { Page } from '../services/api/page'
import { useParams, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SkeletonLoad from '../components/SkeletonLoad/SkeletonLoad'

const PromotionsSingleView: React.FC = () => {
  const { i18n } = useTranslation()
  const { id } = useParams()
  const [page, setPage] = useState<any>({})
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const image = page?.images?.find((img: any) => img.type === 'cover')?.full_src

  useEffect(() => {
    Page.getPromotionPage(id)
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })

    Page.getPromotionsPage()
      .then((data) => {
        console.log(data)
        setItems(data?.filter((p: any) => p.id !== Number(id)))
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [id, i18n.language])

  return (
      <Box
          sx={{
            backgroundImage: {
              xs: 'url("/static/promotions-bg-xs.jpg")',
              md: 'url("/static/promotions-bg-xs.jpg")',
              lg: 'url("/static/promotions-bg.jpg")'
            },
            minHeight: { xs: '100vh', sm: 'initial' },
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            paddingTop: { xs: '0', md: '102px' },
            paddingBottom: { xs: '0', md: '122px' }
          }}
      >
          <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '74px'
              }}
          >
              <Typography color="white" fontWeight="bold">Promotions</Typography>
          </Box>
          <Container
              maxWidth={false}
              sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
              disableGutters={true}
          >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: { md: '40px' },
            marginBottom: '0',
            gap: '50px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', md: '854px', lg: '987px' }
            }}
          >
            <Card sx={{ background: 'transparent', width: '100%' }}>
                {loading
                  ? <Box
                      sx={{
                        height: { xs: '198px', md: '360px' },
                        borderRadius: '20px',
                        overflow: 'hidden'
                      }}
                      >
                        <SkeletonLoad/>
                        </Box>
                  : <CardMedia
                  component="img"
                  image={image}
                  alt={page?.title}
                  sx={{ height: { xs: 'auto' }, borderRadius: '20px' }}
                />}
              <CardContent sx={{ p: { xs: '20px 0', md: '36px 0' } }}>
                <Typography variant="body2" color="text.white" dangerouslySetInnerHTML={{ __html: page?.content }} />
              </CardContent>

              {page?.items?.map((item: any) => (
              <Accordion key={item?.id}>
                <AccordionSummary
                  expandIcon={<ArrowDownIcon width={24} height={24} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      color: '#71C0FF',
                      fontSize: { xs: '14px', md: '18px' },
                      fontWeight: '700'
                    }}
                  >
                    {item?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }} dangerouslySetInnerHTML={{ __html: item?.sub_title }}/>
                </AccordionDetails>
              </Accordion>
              ))}
            </Card>
          </Box>

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              width: '343px'
            }}
          >
            <Stack spacing={4}>
              {items?.map((item: any) => (
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  <NavLink to={`/${i18n.language}/promotions/offers/${item?.id}`} key={item?.id}>
              <Card sx={{ background: 'rgba(23, 24, 34, 0.3)', p: 2, borderRadius: { md: '40px' } }}>
              {loading
                ? <Box
                      sx={{
                        height: '164px',
                        borderRadius: '30px',
                        overflow: 'hidden'
                      }}
                      >
                        <SkeletonLoad/>
                        </Box>
                : <CardMedia
                  component="img"
                  height="164"
                  image={item?.image_url}
                  alt={item?.title}
                  sx={{ borderRadius: '30px', backgroundColor: 'rgba(40, 41, 55, 0.7) !important' }}
                />}
                <CardContent sx={{ p: '18px 0 0' }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontSize: '20px', fontWeight: 'bold' }}
                  >{item?.title}</Typography>
                  <Typography variant="body2" color="text.white" dangerouslySetInnerHTML={{ __html: item?.sub_title }} />
                </CardContent>
              </Card>
                  </NavLink>
              ))}
            </Stack>
          </Box>
        </Box>
      </Container>
      </Box>
  )
}

export default PromotionsSingleView
