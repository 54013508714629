export const CareerPageSx = {
  // backgroundImage: {
  //   xs: 'url("/static/home-bg-mobile.jpg")',
  //   sm: 'url("/static/promotions-bg.jpg")'
  // },
  // minHeight: { xs: '100vh', sm: 'initial' },
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
  paddingTop: { xs: '0', md: '102px' },
  paddingBottom: { xs: '80px', md: '122px' }
}

export const CareerPageHeaderSx = {
  display: { xs: 'none', md: 'inline-block' },
  fontSize: '96px',
  fontWeight: 'bold',
  backgroundImage: 'linear-gradient(90deg, #E2F2FF -34.13%, #0085FF 124.6%)',
  backgroundClip: 'text',
  color: 'transparent',
  marginTop: { xs: '0', md: '26px' }
}

export const CareerStackSx = {
  marginTop: { xs: '0', md: '44px' },
  marginBottom: { xs: '0', md: '0' }
}

export const CareerCardSx = {
  justifyContent: 'flex-start',
  height: { xs: '80px', md: '86px' },
  padding: '0 40px',
  borderRadius: '20px',
  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',

  '&:hover': {
    boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.15)'
  }
}

export const CareerCardBoxSx = {
  flexGrow: 1,
  textAlign: 'left'
}

export const CareerCardTypographySx = {
  fontSize: { xs: 16, sm: 20 },
  fontWeight: 'bold'
}
export const CareerCardRightSx = {
  flexGrow: 0,
  display: 'flex',
  alignItems: 'center'
}
