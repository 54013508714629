import React from 'react'
import { Container, Box, Typography, Button } from '@mui/material'
import PageHeaderMobile from '../components/Page/PageHeaderMobile'
import { TermsPageSx } from '../config/styles/page/PageStyles'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const TermsView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/terms-bg-mobile.jpg'
      : mediaQuerySmall
        ? '/static/terms-bg-small.jpg'
        : '/static/terms-bg.jpg'
  )

  return (
    <Box sx={TermsPageSx}>
      <PageHeaderMobile title={t('page.error.pageNotFound')} />
      <Container
        maxWidth={false}
        sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
        disableGutters={true}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '100px 0'
          }}>
            <Box sx={{
              textAlign: 'center'
            }}>
              <Typography variant="h1" sx={{
                fontSize: { xs: '116px' },
                fontWeight: '800',
                backgroundImage: 'linear-gradient(90deg, #E2F2FF -34.13%, #0085FF 124.6%)',
                backgroundClip: 'text',
                color: 'transparent'
              }}>
                404
              </Typography>
              <Typography sx={{ color: 'white', fontWeight: 'bold' }}>
                {t('page.error.pageNotFound')}
              </Typography>
              <NavLink to={`/${i18n.language}`}>
              <Button
                variant="outlined"
                sx={{
                  margin: '30px auto 0',
                  fontSize: { xs: '16px' },
                  fontWeight: { xs: '700' },
                  padding: '0 30px',
                  height: { xs: '58px' },
                  display: 'flex'
                }}
              >
                {t('page.error.buttonText')}
              </Button>
              </NavLink>
            </Box>
          </Box>
      </Container>
    </Box>
  )
}

export default TermsView
