import axios, { AxiosResponse } from 'axios'
import { getCookie } from 'typescript-cookie'
import i18n from '../../i18n/config'
let token = getCookie('token')

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    Authorization: `Bearer ${token}`,
    locale: getCookie('locale')
  }
}
let instance = axios.create(config)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setTokenOutside = (newToken: any) => {
  token = newToken
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  config.headers.Authorization = `Bearer ${token}`
  instance = axios.create(config)
}

i18n.on('loaded', () => {
  i18n.on('languageChanged', (lng) => {
    document.documentElement.setAttribute('lang', lng)
    config.headers.locale = lng
    instance = axios.create(config)
  })
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const responseBody = (response: AxiosResponse) => response.data

export const requests = {
  get: async (url: string) => await instance.get(url).then(responseBody),
  post: async (url: string, body: Object) => await instance.post(url, body).then(responseBody)
}
