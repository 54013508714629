import React from 'react'

const MessageIcon: React.FC = () => {
  return (
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.7767 11.4648C10.1077 11.4648 9.44073 11.2438 8.88273 10.8018L4.39773 7.1858C4.07473 6.9258 4.02473 6.4528 4.28373 6.1308C4.54473 5.8098 5.01673 5.7588 5.33873 6.0178L9.81973 9.62981C10.3827 10.0758 11.1757 10.0758 11.7427 9.62581L16.1787 6.0198C16.5007 5.7568 16.9727 5.8068 17.2347 6.1288C17.4957 6.4498 17.4467 6.9218 17.1257 7.1838L12.6817 10.7958C12.1197 11.2418 11.4477 11.4648 10.7767 11.4648" fill="white"/>
          <mask id="mask0_1922_198" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="20">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 0H21.4999V19.5H0V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_1922_198)">
              <path fillRule="evenodd" clipRule="evenodd" d="M5.83907 18H15.6591C15.6611 17.998 15.6691 18 15.6751 18C16.8161 18 17.8281 17.592 18.6041 16.817C19.5051 15.92 20.0001 14.631 20.0001 13.188V6.32C20.0001 3.527 18.1741 1.5 15.6591 1.5H5.84107C3.32607 1.5 1.50007 3.527 1.50007 6.32V13.188C1.50007 14.631 1.99607 15.92 2.89607 16.817C3.67207 17.592 4.68507 18 5.82507 18H5.83907ZM5.822 19.5C4.279 19.5 2.901 18.94 1.837 17.88C0.652 16.698 0 15.032 0 13.188V6.32C0 2.717 2.511 0 5.841 0H15.659C18.989 0 21.5 2.717 21.5 6.32V13.188C21.5 15.032 20.848 16.698 19.663 17.88C18.6 18.939 17.221 19.5 15.675 19.5H15.659H5.841H5.822Z" fill="white"/>
          </g>
      </svg>

  )
}

export default MessageIcon
