import React from 'react'

const EditIcon: React.FC = () => {
  return (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.5625 23.625H3.4375C3.00188 23.625 2.64062 23.2637 2.64062 22.8281C2.64062 22.3925 3.00188 22.0312 3.4375 22.0312H22.5625C22.9981 22.0312 23.3594 22.3925 23.3594 22.8281C23.3594 23.2637 22.9981 23.625 22.5625 23.625Z" fill="url(#paint0_linear_1247_101)" fillOpacity="0.6"/>
          <path d="M20.459 3.9475C18.3977 1.88625 16.379 1.83312 14.2646 3.9475L12.979 5.23312C12.8727 5.33937 12.8302 5.50937 12.8727 5.65812C13.6802 8.47375 15.9327 10.7262 18.7484 11.5337C18.7909 11.5444 18.8334 11.555 18.8759 11.555C18.9927 11.555 19.099 11.5125 19.184 11.4275L20.459 10.1419C21.5109 9.10062 22.0209 8.09125 22.0209 7.07125C22.0315 6.01937 21.5215 4.99937 20.459 3.9475Z" fill="url(#paint1_linear_1247_101)" fillOpacity="0.6"/>
          <path d="M16.8358 12.5006C16.5277 12.3519 16.2302 12.2031 15.9433 12.0331C15.7096 11.895 15.4865 11.7462 15.2633 11.5869C15.0827 11.47 14.8702 11.3 14.6683 11.13C14.6471 11.1194 14.5727 11.0556 14.4877 10.9706C14.1371 10.6731 13.744 10.2906 13.3933 9.86562C13.3615 9.84437 13.3083 9.77 13.234 9.67437C13.1277 9.54687 12.9471 9.33437 12.7877 9.09C12.6602 8.93062 12.5115 8.69687 12.3733 8.46312C12.2033 8.17625 12.0546 7.88937 11.9058 7.59187C11.8763 7.52857 11.848 7.46569 11.8208 7.40331C11.6752 7.06915 11.2402 6.9719 10.9824 7.22965L4.86147 13.3506C4.72334 13.4887 4.59584 13.7544 4.56397 13.935L3.99022 18.0044C3.88397 18.7269 4.08584 19.4069 4.53209 19.8637C4.91459 20.2356 5.44584 20.4375 6.01959 20.4375C6.14709 20.4375 6.27459 20.4269 6.40209 20.4056L10.4821 19.8319C10.6733 19.8 10.939 19.6725 11.0665 19.5344L17.1803 13.4206C17.4398 13.161 17.3418 12.7159 17.0038 12.5727C16.9487 12.5493 16.8928 12.5253 16.8358 12.5006Z" fill="url(#paint2_linear_1247_101)" fillOpacity="0.6"/>
          <defs>
              <linearGradient id="paint0_linear_1247_101" x1="-4.43006" y1="22.8356" x2="28.4568" y2="22.8356" gradientUnits="userSpaceOnUse">
                  <stop offset="1" stopColor="white"/>
              </linearGradient>
              <linearGradient id="paint1_linear_1247_101" x1="9.72885" y1="7.01115" x2="24.2758" y2="7.01115" gradientUnits="userSpaceOnUse">
                  <stop offset="1" stopColor="white"/>
              </linearGradient>
              <linearGradient id="paint2_linear_1247_101" x1="-0.739123" y1="13.6221" x2="21.1284" y2="13.6221" gradientUnits="userSpaceOnUse">
                  <stop offset="1" stopColor="white"/>
              </linearGradient>
          </defs>
      </svg>
  )
}

export default EditIcon
