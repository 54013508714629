import React, { useEffect, useState } from 'react'
import { Container, Box } from '@mui/material'
import PrivacyHeadingIcon from '../components/Icon/PrivacyHeadingIcon'
import { Page } from '../services/api/page'
import {
  PageHeroPrivacyTitleSx,
  TermsPageSx
} from '../config/styles/page/PageStyles'
import PageHeaderMobile from '../components/Page/PageHeaderMobile'
import PageHero from '../components/Page/PageHero'
import PageContent from '../components/Page/PageContent'
import { getPageContent } from '../helpers/PageHelper'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'
import useMediaQuery from '@mui/material/useMediaQuery'

const PrivacyPolicyView: React.FC = () => {
  const [page, setPage] = useState({
    title: '',
    content: ''
  })
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/terms-bg-mobile.jpg'
      : mediaQuerySmall
        ? '/static/terms-bg-small.jpg'
        : '/static/terms-bg.jpg'
  )

  useEffect(() => {
    Page.getPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [])

  return (
    <Box sx={TermsPageSx}>
      <PageHeaderMobile title="Privacy Policy" />
      <Container
          maxWidth={false}
          sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
          disableGutters={true}
      >
        <PageHero
          title="Privacy Policy"
          icon={<PrivacyHeadingIcon width={399} height={399} />}
          typographySx={PageHeroPrivacyTitleSx}
        />

        <PageContent content={getPageContent(page)} />
      </Container>
    </Box>
  )
}

export default PrivacyPolicyView
