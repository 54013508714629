import React, { useEffect, useState } from 'react'
import {
  Container,
  Box,
  CardMedia,
  CardContent,
  Typography,
  Card, Button, Stack, CardActions
} from '@mui/material'
import { Page } from '../services/api/page'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SkeletonLoad from '../components/SkeletonLoad/SkeletonLoad'
import useMediaQuery from '@mui/material/useMediaQuery'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'

const NewsSingleView: React.FC = () => {
  const { i18n, t } = useTranslation()
  const { slug } = useParams()
  const [page, setPage] = useState<any>({})
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/blog-single-bg-middle.jpg'
      : mediaQuerySmall
        ? '/static/blog-single-bg-middle.jpg'
        : '/static/blog-single-bg.jpg'
  )

  useEffect(() => {
    Page.getNewsSinglePage(slug)
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })

    Page.getNewsPage(2)
      .then((data) => {
        setItems(data?.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])

  return (
      <Box
          sx={{
            paddingTop: { xs: '0', md: '102px' }
          }}
      >
          <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '74px'
              }}
          >
              <Typography color="white" fontWeight="bold">Blog</Typography>
          </Box>
          <Container
              maxWidth={false}
              sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
              disableGutters={true}
          >
        <Box
          sx={{
            paddingY: { xs: '0', md: '30px' }
          }}
        >
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              width: '100%'
            }}
          >
              <Card sx={{ overflow: 'initial', background: 'transparent', width: '100%', display: { xs: 'block', md: 'flex' }, flexDirection: 'row-reverse', marginBottom: { xs: '10px', md: '80px' } }}>
                  {loading
                    ? <Box
                  sx={{
                    width: '100%',
                    height: { xs: '200px', md: '360px' },
                    borderRadius: '50px',
                    overflow: 'hidden'
                  }}
                  >
                    <SkeletonLoad/>
                    </Box>
                    : <CardMedia
                  component="img"
                  image={page?.desktop_image_src}
                  alt={page?.title}
                  sx={{
                    width: { xs: '100%', md: 'calc(100% - 475px)' },
                    height: { xs: 'auto', lg: '320px' },
                    borderRadius: '50px',
                    boxShadow: { md: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)' },
                    backdropFilter: 'blur(44px)',
                    backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                  }}
              />}
                  <CardContent sx={{ width: { xs: '100%', md: '475px' }, px: { xs: 0 }, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      <Typography variant="h5" component="h1" sx={{ color: '#FFEAEB', fontWeight: '800', paddingRight: { md: '16px' }, fontSize: { xs: '16px', md: '1.65rem' } }}>
                          {page?.title}
                      </Typography>

                      <Button variant="outlined" color="primary" sx={{ maxWidth: { xs: '170px', md: '241px' }, height: { xs: '33px', md: '56px' }, fontSize: { xs: '12px', md: '16px' }, mt: { xs: '20px', md: '26px' }, cursor: 'default', fontWeight: 'bold', borderRadius: { xs: '12px', md: '20px' } }}>
                          {page?.created_at}
                      </Button>
                  </CardContent>
              </Card>
          </Box>

          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
              <Box
                  sx={{
                    width: { xs: '100%', md: '878px' },
                    paddingRight: { md: '30px', lg: '0' }
                  }}
              >
                  <Typography color="white" sx={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: page?.description }}/>
              </Box>

              <Box
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    width: '343px'
                  }}
              >
                  <Stack spacing={7}>
                      {items?.map((item: any) => (
                          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                          <NavLink to={`/${i18n.language}/blog/${item?.slug}`} key={item?.id}>
                              <Card sx={{ background: 'transparent' }}>
                              {loading
                                ? <Box
                              sx={{
                                height: '198px',
                                borderRadius: '20px',
                                overflow: 'hidden'
                              }}
                              >
                                <SkeletonLoad/>
                                </Box>
                                : <CardMedia
                                component="img"
                                height="198"
                                image={item?.thumbnail_image_src}
                                alt="green iguana"
                                sx={{ borderRadius: '20px', backgroundColor: 'rgba(40, 41, 55, 0.7) !important' }}
                            />}
                                  <CardContent sx={{ p: '14px 8px 2px' }}>
                                      <Typography component="div" sx={{ fontSize: '18px', color: '#FFEAEB', fontWeight: 'bold' }}>
                                          {item?.title?.substring(0, 47)}
                                      </Typography>
                                      {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                                      <Typography fontSize="12px" color="text.white" marginTop="-8px" dangerouslySetInnerHTML={{ __html: `${item?.short_description?.substring(0, 171)}...` }} />
                                  </CardContent>

                                  <CardActions sx={{ px: '0' }}>
                                      <Button variant="outlined" color="primary" sx={{ height: '56px', px: '60px', borderRadius: '20px' }}>
                                          <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                backgroundImage:
                                                      `
                                            linear-gradient(90deg, #FFFFFF -13%, #FFFFFF 108%)
                                          `,
                                                backgroundClip: 'text',
                                                color: 'transparent',
                                                borderBottom: '1px solid #FFFFFF',
                                                marginTop: '-4px',
                                                height: '21px'
                                              }}
                                          >
                                            {t('news.item.seeMore')}
                                          </Typography>
                                      </Button>
                                  </CardActions>
                              </Card>
                          </NavLink>
                      ))}
                  </Stack>
              </Box>
          </Box>
        </Box>
      </Container>
      </Box>
  )
}

export default NewsSingleView
