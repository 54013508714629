import React from 'react'

const InstagramIcon: React.FC = () => {
  return (
      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.39719 0.769886C2.58185 0.892045 1.93697 1.21591 1.3489 1.80398C0.743786 2.40909 0.41424 3.08523 0.303445 3.93466C0.260831 4.26705 0.260831 11.733 0.303445 12.0653C0.41424 12.9148 0.743786 13.5909 1.3489 14.196C1.95401 14.8011 2.63015 15.1307 3.47958 15.2415C3.81197 15.2841 11.2779 15.2841 11.6103 15.2415C12.4597 15.1307 13.1358 14.8011 13.7409 14.196C14.3461 13.5909 14.6756 12.9148 14.7864 12.0653C14.829 11.733 14.829 4.26705 14.7864 3.93466C14.6756 3.08523 14.3461 2.40909 13.7409 1.80398C13.1358 1.19886 12.4597 0.869318 11.6103 0.758522C11.2466 0.710227 3.71822 0.72159 3.39719 0.769886ZM11.9114 2.05398C12.2324 2.16477 12.3034 2.2017 12.5676 2.38352C12.9881 2.6733 13.3205 3.125 13.4881 3.63352L13.5819 3.9233V8C13.5819 11.9006 13.579 12.0852 13.5279 12.2472C13.3375 12.8636 13.008 13.321 12.5222 13.6506C12.312 13.7926 12.1301 13.875 11.8347 13.9688L11.6216 14.0369H7.54492H3.46822L3.17844 13.9432C2.38299 13.6818 1.82617 13.0994 1.56197 12.2472C1.51083 12.0852 1.50799 11.9006 1.50799 8C1.50799 4.09943 1.51083 3.91477 1.56197 3.75284C1.76367 3.10511 2.11594 2.63352 2.64151 2.30398C2.8631 2.16477 3.24379 2.01705 3.47958 1.97727C3.5506 1.96591 5.41992 1.95739 7.63015 1.96023L11.65 1.96307L11.9114 2.05398Z" fill="#282937"/>
          <path d="M11.2947 3.18181C11.093 3.22727 10.9765 3.28977 10.8401 3.42897C10.6555 3.61931 10.5986 3.76988 10.5986 4.06534C10.5986 4.28693 10.61 4.3409 10.6753 4.46306C10.8316 4.75852 11.0958 4.93181 11.4253 4.95454C11.6668 4.96874 11.7947 4.93465 11.9964 4.79829C12.5617 4.41761 12.4964 3.55113 11.8827 3.25852C11.6725 3.15908 11.5049 3.13636 11.2947 3.18181Z" fill="#282937"/>
          <path d="M7.07919 4.39483C5.70703 4.58517 4.53658 5.53972 4.11044 6.82381C3.96555 7.26131 3.92578 7.51415 3.92578 7.99994C3.92578 8.48574 3.96555 8.73858 4.11044 9.17892C4.45419 10.2073 5.34055 11.0909 6.37464 11.4374C6.80646 11.5795 7.06214 11.6193 7.5451 11.6193C8.02805 11.6193 8.28374 11.5795 8.71555 11.4374C9.74965 11.0909 10.636 10.2073 10.9798 9.17892C11.1246 8.73858 11.1644 8.48574 11.1644 7.99994C11.1644 7.36358 11.0423 6.84369 10.7667 6.32381C10.2411 5.33801 9.33771 4.65904 8.24112 4.4346C7.9684 4.37779 7.34908 4.35506 7.07919 4.39483ZM8.27521 5.69597C8.57919 5.7954 8.66158 5.83517 8.89453 5.99142C9.3718 6.31244 9.69283 6.75847 9.87749 7.34654C9.9343 7.53119 9.94567 7.61926 9.94567 7.99994C9.94567 8.46869 9.91158 8.64199 9.74396 9.01415C9.53374 9.49142 9.03658 9.98858 8.55646 10.2016C8.18715 10.3664 8.01385 10.4005 7.5451 10.4005C7.07635 10.4005 6.90305 10.3664 6.53374 10.2016C6.05362 9.98858 5.55646 9.49142 5.34624 9.01415C5.17862 8.64199 5.14453 8.46869 5.14453 7.99994C5.14453 7.61926 5.15589 7.53119 5.21271 7.34654C5.3093 7.03972 5.39453 6.86074 5.55646 6.62494C5.87749 6.15904 6.32635 5.83801 6.87749 5.67608C7.15874 5.59369 7.20135 5.58801 7.63033 5.59938C7.9684 5.6079 8.0451 5.61926 8.27521 5.69597Z" fill="#282937"/>
      </svg>

  )
}

export default InstagramIcon
