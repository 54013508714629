import React, { useEffect, useState } from 'react'
import { Container, Paper, Stack, Typography, Box } from '@mui/material'
import { Page } from '../services/api/page'
import { styled } from '@mui/material/styles'

const FaqPaper = styled(Paper)(({ theme }) => ({
  '&': {
    padding: '30px 39px 14px',
    border: 'double 1px transparent',
    backgroundImage: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(90deg, rgba(235, 30, 46, 0.6) -10.2%,
                              rgba(155, 50, 136, 0.6) 51.5%, rgba(80, 67, 218, 0.6) 102.62%)
                          `,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',

    [theme.breakpoints.down('md')]: {
      padding: '16px 23px 2px'
    },

    '& ul': {
      marginTop: '-4px',
      paddingLeft: '23px'
    }
  }
}))

const FaqView: React.FC = () => {
  const [page, setPage] = useState<any[]>([])

  useEffect(() => {
    Page.getFaqPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [])

  return (
      <Box
          sx={{
            backgroundImage: {
              xs: 'url("/static/home-bg-mobile.jpg")',
              sm: 'url("/static/faq-bg.jpg")'
            },
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            paddingTop: { xs: '0', md: '102px' },
            paddingBottom: { xs: '50px', md: '20px' }
          }}
      >
          <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '74px'
              }}
          >
              <Typography color="white" fontWeight="bold">FAQ</Typography>
          </Box>
          <Container
              maxWidth={false}
              sx={{ maxWidth: { md: '1192px', lg: '1280px' }, paddingX: { xs: '16px', md: '40px', lg: 'initial' } }}
              disableGutters={true}
          >
        <Typography
          gutterBottom
          sx={{
            display: { xs: 'none', md: 'inline-block' },
            fontSize: '48px',
            fontWeight: 'bold',
            backgroundImage:
              'linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%)',
            backgroundClip: 'text',
            color: 'transparent'
          }}
        >
          FAQ
        </Typography>
        <Stack spacing={{ xs: '20px', md: 3 }} marginBottom={{ xs: '0', md: '100px' }}>
            {page?.map((item) => (
                <FaqPaper key={item.key}>
                    <Typography
                        gutterBottom
                        sx={{
                          display: 'inline-block',
                          fontSize: { xs: '14px', sm: '20px' },
                          fontWeight: 'bold',
                          color: 'rgba(249, 147, 230, 1)'
                        }}
                    >
                        {item?.fields?.faq_title?.value}
                    </Typography>
                    <Typography
                        dangerouslySetInnerHTML={{ __html: item?.fields?.faq_text?.value }}
                        sx={{
                          fontSize: { xs: '12px', md: '14px' },
                          fontWeight: { xs: 'normal', md: 'bold' },
                          lineHeight: '2'
                        }}
                    />
                </FaqPaper>
            ))}
        </Stack>
      </Container>
      </Box>
  )
}

export default FaqView
