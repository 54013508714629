/* eslint-disable */

import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Tooltip,
  Typography
} from '@mui/material'
import HeroCarousel from '../components/Carousel/Hero/HeroCarousel'
import { Page } from '../services/api/page'
import BranchIcon from '../components/Icon/BranchIcon'
import BranchGradientIcon from '../components/Icon/BranchGradientIcon'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LogoMobileIcon from '../components/Icon/LogoMobileIcon'
import MegaphoneIcon from '../components/Icon/MegaphoneIcon'
import QrCircleIcon from '../components/Icon/QrCircleIcon'
import AppStoreIcon from '../components/Icon/AppStoreIcon'
import GooglePlayIcon from '../components/Icon/GooglePlayIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import PhoneIcon from '../components/Icon/PhoneIcon'
import MessageIcon from '../components/Icon/MessageIcon'
import QrCodeIcon from '../components/Icon/QrCodeIcon'
import SkeletonLoad from '../components/SkeletonLoad/SkeletonLoad'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'
import { useListener } from 'react-bus'
interface NewsItem {
  id: number
  thumbnail_image_src: string
  title: string
  short_description: string
  slug: string
}
const HomeView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState<any>({})
  const [pageBlogs, setPageBlogs] = useState<any[]>([])
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))
  const matchesXs = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [loading, setLoading] = useState<boolean>(true)
  const [promoSlideIndex, setPromoSlideIndex] = useState<any>(0)
  const [promoSlideOpacity, setPromoSlideOpacity] = useState<any>(1)
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onPromoSlideChange = (event: any) => {
    setPromoSlideOpacity(0)
    setPromoSlideIndex(event?.activeIndex)
    setTimeout(() => setPromoSlideOpacity(1), 100)
  }

  useListener('onPromoSlideChange', onPromoSlideChange)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // const onPromoSlideChange = (value: any) => {
  //   alert('asd')
  // }

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/home-bg-middle.jpg'
      : mediaQuerySmall
        ? '/static/home-bg-sm.jpg'
        : '/static/home-bg.jpg'
  )

  useEffect(() => {
    Page.getHomePage()
      .then((data) => {
        setPage(data)
        setLoading(false)
        console.log(page)
      })
      .catch((err) => {
        console.log(err)
      })

    Page.getNewsPage(3)
      .then((data) => {
        setPageBlogs(data.data);
        console.log(data , 'asd');
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [])

  useEffect(() => {
    Page.getHomePage()
      .then((data) => {
        setPage(data)
        setLoading(false)
        console.log(page)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])

  return (
    <Box
      sx={{
        paddingTop: { xs: '0', md: '102px' }
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '74px'
        }}
      >
        <NavLink to={`/${i18n.language}`}>
          <LogoMobileIcon />
        </NavLink>
      </Box>

      <Box
        sx={{
          position: 'relative',
          width: '100%',
          padding: { xs: '6px 16px 0', md: '27px 0 28px' },
          background: 'rgba(38, 44, 60, 0.5)',
          backdropFilter: 'blur(17.7007px)',
          borderRadius: '0'
        }}
      >
        <Container
          sx={{ maxWidth: { md: '1112px', lg: '1280px' } }}
          disableGutters
          fixed
        >
          <Box
            display={{ xs: 'block', md: 'flex' }}
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              height: { sm: 'auto', md: 'auto', lg: '430px' },
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: 'auto' },
                paddingTop: { xs: '11px', md: '0', lg: '9px' },
                justifyContent: { xs: 'center', md: 'flex-start' }
              }}
            >
              <Typography 
                variant="h1"
                sx={{
                  fontStyle: 'normal',
                  fontWeight: i18n.language == "en" ? '800' : '650',
                  fontSize: i18n.language == "en" ? '80px' : '55px',
                  background:
                    'linear-gradient(86.25deg, #00B2EC 0%, #00EECA 52%)',
                  webkitBackgroundClip: 'text',
                  webkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                }}>
                  {t('home.forMoreFun')}
                </Typography>

              {/* <img
                src={
                  matchesXs
                    ? `/static/hero-text-mobile-${i18n.language}.svg`
                    : matches
                      ? `/static/hero-text-${i18n.language}.svg`
                      : `/static/hero-text-${i18n.language}.svg`
                }
                alt="For More Fun"
              /> */}
            </Box>

            <Box
              className="home-hero"
              sx={{
                position: { xs: 'relative', md: 'relative' },
                width: { xs: '100%', md: '780px', lg: '937px' },
                height: { xs: 'auto', md: 'auto', lg: '430px' },
                marginTop: { xs: '9px', md: '0' },
                padding: { xs: '6px 0 0', md: '0' }
              }}
            >
              <HeroCarousel
                items={page?.items?.home?.[0]?.fields?.images?.value}
                name="url"
                loop
                link={3}
                autoPlay={{ delay: 4000 }}
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        display='none'
        sx={{
          padding: {
            xs: '35px 0 0',
            md: '79px 0',
            lg: '107px 0 148px'
          },
          overflowX: 'hidden'
        }}
      >
        <Container maxWidth="lg" disableGutters fixed>
          <Box
            className="home-offers"
            sx={{
              display: { xs: 'block', md: 'flex' },
              alignItems: 'center',
              gap: { md: '53px', lg: '67px' },
              height: { xs: 'auto', md: 'auto', lg: '342px' },
              margin: { xs: '0 -16px', md: '0' },
              padding: { md: '0 58px', lg: '0 70px' }
            }}
          >
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                color: 'white',
                marginBottom: '30px'
              }}
            >
              <MegaphoneIcon />
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                  height: '16px',
                  alignSelf: 'center'
                }}
              />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 'bold'
                }}
                color="white"
              >
                {t('home.promoTitle')}
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: '100%', md: '600px', lg: '678px' },
                height: { xs: 'auto', md: '300px', lg: '343px' },
                boxShadow: { md: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)' },
                borderRadius: { md: '50px' }
              }}
            >
              <HeroCarousel
                items={page?.items?.home?.[1]?.fields?.images?.value}
                breakpoints={{
                  0: {
                    slidesPerView: 1.345,
                    spaceBetween: 12,
                    centeredSlides: true,
                    pagination: false
                  },

                  1212: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    centeredSlides: false,
                    pagination: true
                  }
                }}
                keyboard={false}
                retrieveChange="onPromoSlideChange"
                name="url"
                link={2}
              />
            </Box>
            {page?.items?.home?.[1]?.fields?.images?.value?.map(
              (item: any, index: number) => (
                <Box
                  key={index}
                  sx={{
                    margin: { lg: '-7px 0 0' },
                    display: {
                      xs: 'none',
                      md: promoSlideIndex === index ? 'block' : 'none'
                    },
                    opacity: promoSlideOpacity,
                    transition: 'all 1s ease-in-out'
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      color: 'white',
                      mt: { md: '-12px', lg: '-13px' },
                      fontSize: {
                        md: '34px',
                        lg: i18n.language === 'en' ? '40px' : '36px'
                      },
                      fontWeight: 'bold'
                    }}
                  >
                    {item?.fields?.[0]?.value}
                  </Typography>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: item?.fields?.[1]?.value
                    }}
                    sx={{
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: '400px',
                      paddingTop: { lg: '2px' },
                      width: i18n.language === 'en' ? '357px' : '400px'
                    }}
                  ></Typography>
                  {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                  <NavLink to={`/${i18n.language}/${item?.fields?.[2]?.value}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        width: '210px',
                        height: '54px',
                        fontSize: '14px',
                        mt: { md: '19px', lg: '54px' },
                        borderRadius: '20px'
                      }}
                    >
                      {t('home.promoButton')}
                    </Button>
                  </NavLink>
                </Box>
              )
            )}
          </Box>
        </Container>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        marginTop={{ xs: '30px', md: '0' }}
        paddingY={{ xs: '42px', md: '56px', lg: '64px' }}
        sx={{
          background:
            'linear-gradient(90deg, #17182263 -0.7%, #2829377d 19.75%, #3c3d5000 50.7%, #28293761 80.07%, #17182296 100%)',
          backdropFilter: 'blur(0)'
        }}
      >
        <Container maxWidth="lg" disableGutters fixed>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: { xs: '0 16px', md: '0 58px', lg: '0 70px' }
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '442px',
                  lg: i18n.language === 'en' ? '504px' : '530px'
                },
                textAlign: { xs: 'center', md: 'left' }
              }}
            >
              <Typography
                gutterBottom
                component="div"
                sx={{
                  color: 'white',
                  fontSize: { xs: '18px', md: '34px', lg: '40px' },
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  gap: '16px',
                  marginTop: { md: '-16px', lg: '-23px' }
                }}
              >
                {t('home.downloadAppTitle')}
                {!matchesXs && (
                  <Tooltip
                    title={<QrCodeIcon height={140} width={140} />}
                    placement="top"
                    arrow
                  >
                    <Button sx={{ padding: 0, minWidth: '44px' }}>
                      <QrCircleIcon />
                    </Button>
                  </Tooltip>
                )}
              </Typography>
              <Typography
                sx={{
                  color: 'white',
                  fontSize: { xs: '12px', md: '14px', lg: '16px' },
                  paddingTop: { lg: '5px' }
                }}
              >
                {t('home.downloadAppText')}
              </Typography>

              <a
                href="https://apps.apple.com/us/app/betliveclub/id1538137533"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    width: { md: '180px', lg: '200px' },
                    height: { md: '48px', lg: '54px' },
                    mt: { md: '51px', lg: '54px' },
                    borderRadius: { md: '17px', lg: '20px' }
                  }}
                >
                  <AppStoreIcon />
                </Button>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.betlive.club"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    width: { md: '180px', lg: '200px' },
                    height: { md: '48px', lg: '54px' },
                    ml: { md: '14px', lg: '20px' },
                    mt: { md: '51px', lg: '54px' },
                    borderRadius: { md: '17px', lg: '20px' }
                  }}
                >
                  <GooglePlayIcon />
                </Button>
              </a>
            </Box>

            <Box
              sx={{
                position: 'relative',
                left: { lg: '-15px' },
                width: { xs: '100%', md: '480px', lg: '548px' },
                paddingLeft: { xs: '0', md: '0' },
                paddingRight: { xs: '0', md: '0' },
                paddingTop: { xs: '33px', md: '0' }
              }}
            >
              {loading
                ? (
                <Box
                  sx={{
                    width: '100%',
                    height: { xs: '240px', md: '320px' }
                  }}
                >
                  <SkeletonLoad />
                </Box>
                  )
                : (
                <img
                  src="/static/home-app-placeholder.svg"
                  width="100%"
                  alt="#"
                />
                  )}
            </Box>

            <Box display={{ xs: 'block', md: 'none' }} width="100%">
              <a
                href="https://apps.apple.com/us/app/betliveclub/id1538137533"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: '100%',
                    height: '58px',
                    mt: '43px',
                    borderRadius: '20px'
                  }}
                >
                  <AppStoreIcon />
                </Button>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.betlive.club"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: '100%',
                    height: '58px',
                    mt: '10px',
                    borderRadius: '20px'
                  }}
                >
                  <GooglePlayIcon />
                </Button>
              </a>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        paddingY={{ md: '92px', lg: '105px' }}
        sx={{
          background:
            'linear-gradient(90deg, rgb(23 24 34 / 40%) -0.7%, #28293754 19.75%, #3c3d504d 50.7%, #2829376e 80.07%, #17182287 100%)'
        }}
      >
        <Container maxWidth="lg" disableGutters fixed>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column-reverse', md: 'row' },
              alignItems: 'center',
              padding: { xs: '62px 16px', md: '0 58px', lg: '0 70px' }
            }}
          >
            <Box
              sx={{
                width: { xs: '100%', md: '564px', lg: '646px' },
                paddingTop: { xs: '38px', md: '0' },
                paddingRight: { lg: '0' },
                height: { md: '406px', lg: '465px' }
              }}
            >
              {loading
                ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <SkeletonLoad />
                </Box>
                  )
                : (
                <img
                  src="/static/home-galery-placeholder.svg"
                  width="100%"
                  height="100%"
                  alt="#"
                />
                  )}
              <NavLink to={`/${i18n.language}/gallery`}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    fontWeight: '700',
                    width: '240px',
                    height: '54px',
                    fontSize: '14px',
                    mt: '47px',
                    mx: 'auto',
                    borderRadius: '20px'
                  }}
                >
                  {t('button.seeMore')}
                </Button>
              </NavLink>
            </Box>

            <Box
              sx={{
                position: 'relative',
                left: { md: '40px', lg: '100px' },
                width: { xs: '100%', md: '359px', lg: '394px' },
                paddingLeft: { xs: '0', md: '0' },
                textAlign: { xs: 'center', md: 'left' }
              }}
            >
              <Typography
                component="div"
                sx={{
                  color: 'white',
                  fontSize: { xs: '24px', md: '34px', lg: '40px' },
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  marginTop: { md: '34px', lg: '-18px' }
                }}
              >
                {t('gallery.title')}
              </Typography>
              <Typography
                sx={{
                  color: 'white',
                  fontSize: { xs: '12px', md: '14px' },
                  paddingTop: { xs: '9px', md: '4px' }
                }}
              >
                {t('gallery.text')}
              </Typography>

              <NavLink to={`/${i18n.language}/gallery`}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    width: '210px',
                    height: '54px',
                    fontSize: '14px',
                    fontWeight: '700',
                    mt: { md: '38px', lg: '53px' },
                    borderRadius: '20px'
                  }}
                >
                  {t('button.seeMore')}
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box>
        <Container maxWidth="lg" disableGutters fixed>
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: '12px', md: '16px' },
                paddingTop: { xs: '10px', md: '30px' },
                color: 'white'
              }}
              paddingX={{ xs: '44px', lg: '50px', md: '30px' }}
            >
            <Typography
              paddingY={{ xs: '20px', lg: '35px', md: '30px' }}
              sx={{
                fontSize: { xs: 16, md: 24, lg: 32 },
                fontWeight: 'bold'
              }}
              color="white"
            >
              {t('home.mainPageBlogs')}
            </Typography>
          </Box>

          <Grid
              // container
              // spacing={3}
              // sx={{
              //   marginBottom: '0',
              //   display: { xs: 'none', md: 'flex' }
              // }}
              container
              spacing={{ xs: '16px', md: '20px', lg: '24px'}}
              sx={{
                marginTop: { xs: '12px', md: '24px', lg: '38px' },
                paddingX: { xs: '44px', md: '86px' }
              }}
            >
            {pageBlogs?.map((item: NewsItem) => (
                <Grid item xs={12} md={4}key={item?.id}>
                  <NavLink
                    to={`/${i18n.language}/blog/${item?.slug}`}
                    key={item?.id}
                  >
                    <Card sx={{ background: 'transparent', overflow: 'initial' }}>
                          {loading
                            ? <Box
                                sx={{
                                  borderRadius: '50px',
                                  height: { xs: '200px', md: '280px' },
                                  overflow: 'hidden'
                                }}
                                >
                                  <SkeletonLoad/>
                                  </Box>
                            : <CardMedia
                            component="img"
                            image={item?.thumbnail_image_src}
                            alt="green iguana"
                            sx={{
                              borderRadius: '50px',
                              height: { xs: 'auto', md: '280px' },
                              backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                            }}
                          />}
                      <CardContent sx={{ px: { xs: '0', md: '18px' }, pb: { md: 0 } }}>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: '18px',
                            color: '#FFEAEB',
                            fontWeight: 'bold'
                          }}
                        >
                          {item?.title?.substring(0, 34)}
                        </Typography>
                        {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                          {((item?.short_description) !== null)
                            ? (
                              <Typography
                                  fontSize="14px"
                                  color="text.white"
                                  sx={{ marginTop: { md: '-10px' }, height: { md: '72px', lg: '54px' } }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${item?.short_description?.substring(
                                          0,
                                          110
                                      )}`
                                  }}
                              />
                              )
                            : (
                              <Typography
                                  fontSize="14px"
                                  color="text.white"
                                  sx={{ marginTop: { md: '-10px' }, height: { md: '88px', lg: i18n.language === 'en' ? '68px' : '90px' } }}
                              />
                              )}
                      </CardContent>

                      <CardActions sx={{ px: { xs: '0', md: '18px' } }}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            width: { lg: '100%', xs: '100%', md: 'auto' },
                            height: '56px',
                            px: '60px',
                            borderRadius: '20px',
                            marginTop: '10px',
                            backgroundImage: `
                                            linear-gradient(90deg, #6DFFE7 -10.2%, #55BEC0 51.5%, #55BEC0 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                              border: '1px solid #6DFFE7',

                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              backgroundImage: `
                                                linear-gradient(90deg, #FFFFFF -10.2%, #FFFFFF 51.5%, #FFFFFF 102.62%),
                                                linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                              `,
                              backgroundClip: 'text',
                              color: 'transparent',
                              borderBottom: '1px solid #FFFFFF',
                              marginTop: '-4px',
                              height: '21px',

                            }}
                          >
                            {t('news.item.seeMore')}
                          </Typography>
                        </Button>
                      </CardActions>
                    </Card>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        paddingY={{ xs: '44px', lg: '50px' }}
        paddingX={{ xs: '16px', md: '0' }}
      >
        <Container maxWidth="lg" disableGutters fixed>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: { xs: '12px', md: '16px' },
              paddingTop: { xs: '0', md: '0' },
              color: 'white'
            }}
          >
            <BranchIcon
              width={matchesXs ? 18 : 30}
              height={matchesXs ? 16 : 26}
            />
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: { xs: '16px', md: '30px', lg: '40px' },
                alignSelf: 'center',
                borderColor: 'rgba(255, 255, 255, 0.5)'
              }}
            />
            <Typography
              sx={{
                fontSize: { xs: 16, md: 24, lg: 32 },
                fontWeight: 'bold'
              }}
              color="white"
            >
              {t('home.branchesTitle')}
            </Typography>
          </Box>

          <Grid
            container
            spacing={{ xs: '16px', md: '20px', lg: '24px' }}
            margin={"auto"}
            sx={{
              marginTop: { xs: '12px', md: '24px', lg: '38px' },
              paddingX: { xs: '0', md: '86px' }
            }}
          >
            <Grid margin={'auto'} marginRight={0} item xs={12} md={4}>
              <Card
                sx={{
                  p: { xs: '12px', md: 0, lg: '20px 0 0' },
                  height: { xs: '194px', md: '200px', lg: '226px' },
                  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  border: 'double 1px transparent',
                  backgroundImage: `
                  linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                  linear-gradient(90deg, #6DFFE7 -17.2%,
                  #6DFFE7 51.5%, #55BEC0 102.62%)
                          `,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  borderRadius: { md: '16px', lg: '20px' }
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BranchGradientIcon
                    width={matchesXs ? 44 : matches ? 38 : 49}
                    height={matchesXs ? 40 : 44}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: { xs: 16, md: 20 },
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                    color="white"
                  >
                    {t('branch.saburtaloTitle')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      paddingX: { md: '9px', lg: 0 },
                      textAlign: 'center'
                    }}
                    color="rgba(255, 255, 255, 0.5)"
                    dangerouslySetInnerHTML={{
                      __html: t('branch.saburtaloAddress')
                    }}
                    gutterBottom
                  ></Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="secondary"
                    href="https://goo.gl/maps/JvFkVzGNAQH1izwaA"
                    target="_blank"
                    sx={{
                      height: '0',
                      px: '60px',
                      borderRadius: '20px',
                      margin: {
                        xs:
                          i18n.language === 'en'
                            ? '5px auto 0'
                            : '-15px auto 0',
                        md: '-9px auto 0',
                        lg:
                          i18n.language === 'en'
                            ? '11px auto 0'
                            : '-13px auto 0'
                      },
                      padding: '0'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '14px',
                          lg: i18n.language === 'en' ? '16px' : '14px'
                        },
                        fontWeight: i18n.language === 'en' ? 'bold' : '500',
                        backgroundImage: `
                                            linear-gradient(90deg, #6DFFE7 -10.2%, #55BEC0 51.5%, #55BEC0 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                        backgroundClip: 'text',
                        color: 'transparent',
                        borderBottom: '1px solid #6DFFE7',
                        marginTop: '-4px',
                        height: {
                          xs: '18px',
                          lg: i18n.language === 'en' ? '21px' : '19px'
                        },
                        fontFeatureSettings: "'case' on"
                      }}
                    >
                      {t('branch.seeLocation')}
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid margin={'auto'} marginLeft={0} item xs={12} md={4}>
              <Card
                sx={{
                  p: { xs: '12px', md: 0, lg: '20px 0 0' },
                  height: { xs: '194px', md: '200px', lg: '226px' },
                  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  border: 'double 1px transparent',
                  backgroundImage: `
                            linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                            linear-gradient(90deg, #6DFFE7 -17.2%,
                            #6DFFE7 51.5%, #55BEC0 102.62%)
                          `,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  borderRadius: { md: '16px', lg: '20px' }
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BranchGradientIcon
                    width={matchesXs ? 44 : matches ? 38 : 49}
                    height={matchesXs ? 40 : 44}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: { xs: 16, md: 20 },
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                    color="white"
                  >
                    {t('branch.gldaniTitle')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      paddingX: { md: '10px', lg: 0 },
                      textAlign: 'center'
                    }}
                    color="rgba(255, 255, 255, 0.5)"
                    gutterBottom
                  >
                    {t('branch.gldaniAddress')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="secondary"
                    href="https://goo.gl/maps/XTLAsKwTPpGfn2DS8"
                    target="_blank"
                    sx={{
                      height: '0',
                      px: '60px',
                      borderRadius: '20px',
                      margin: {
                        xs: '5px auto 0',
                        md: '12px auto 0',
                        lg: '11px auto 0'
                      },
                      padding: '0'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '14px',
                          lg: i18n.language === 'en' ? '16px' : '14px'
                        },
                        fontWeight: i18n.language === 'en' ? 'bold' : '500',
                        backgroundImage: `
                                            linear-gradient(90deg, #6DFFE7 -10.2%, #55BEC0 51.5%, #55BEC0 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                        backgroundClip: 'text',
                        color: 'transparent',
                        borderBottom: '1px solid #6DFFE7',
                        marginTop: '-4px',
                        height: {
                          xs: '18px',
                          lg: i18n.language === 'en' ? '21px' : '19px'
                        },
                        fontFeatureSettings: "'case' on"
                      }}
                    >
                      {t('branch.seeLocation')}
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={{ xs: '16px', md: '20px', lg: '24px' }}
            sx={{
              marginTop: { xs: '12px', md: '24px', lg: '38px' },
              paddingX: { xs: '0', md: '86px' }
            }}
          >
            <Grid margin={'auto'} marginRight={0} item xs={12} md={4}>
              <Card
                sx={{
                  p: { xs: '12px', md: 0, lg: '20px 0 0' },
                  height: { xs: '194px', md: '200px', lg: '226px' },
                  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  border: 'double 1px transparent',
                  backgroundImage: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, #6DFFE7 -17.2%,
                              #6DFFE7 51.5%, #55BEC0 102.62%)
                          `,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  borderRadius: { md: '16px', lg: '20px' }
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BranchGradientIcon
                    width={matchesXs ? 44 : matches ? 38 : 49}
                    height={matchesXs ? 40 : 44}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: { xs: 16, md: 20 },
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                    color="white"
                  >
                    {t('branch.varketiliTitle')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      paddingX: { md: '10px', lg: 0 },
                      textAlign: 'center'
                    }}
                    color="rgba(255, 255, 255, 0.5)"
                    gutterBottom
                  >
                    {t('branch.varketiliText')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="secondary"
                    href="https://goo.gl/maps/gccz6Crit3PHdYgx6"
                    target="_blank"
                    sx={{
                      height: '0',
                      px: '60px',
                      borderRadius: '20px',
                      margin: {
                        xs: '5px auto 0',
                        md: '12px auto 0',
                        lg: '11px auto 0'
                      },
                      padding: '0'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '14px',
                          lg: i18n.language === 'en' ? '16px' : '14px'
                        },
                        fontWeight: i18n.language === 'en' ? 'bold' : '500',
                        backgroundImage: `
                                            linear-gradient(90deg, #6DFFE7 -10.2%, #55BEC0 51.5%, #55BEC0 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                        backgroundClip: 'text',
                        color: 'transparent',
                        borderBottom: '1px solid #6DFFE7',
                        marginTop: '-4px',
                        height: {
                          xs: '18px',
                          lg: i18n.language === 'en' ? '21px' : '19px'
                        },
                        fontFeatureSettings: "'case' on"
                      }}
                    >
                      {t('branch.seeLocation')}
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid margin={'auto'} marginLeft={0} item xs={12} md={4}>
              <Card
                sx={{
                  p: { xs: '12px', md: 0, lg: '20px 0 0' },
                  height: { xs: '194px', md: '200px', lg: '226px' },
                  boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  border: 'double 1px transparent',
                  backgroundImage: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, #6DFFE7 -17.2%,
                              #6DFFE7 51.5%, #55BEC0 102.62%)
                          `,
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  borderRadius: { md: '16px', lg: '20px' },
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <BranchGradientIcon
                    width={matchesXs ? 44 : matches ? 38 : 49}
                    height={matchesXs ? 40 : 44}
                  />
                  <Typography
                    sx={{
                      marginTop: '12px',
                      fontSize: { xs: 16, md: 20 },
                      fontWeight: 'bold',
                      textAlign: 'center'
                    }}
                    color="white"
                  >
                    {t('branch.batumiTitle')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      paddingX: { md: '10px', lg: 0 },
                      textAlign: 'center'
                    }}
                    color="rgba(255, 255, 255, 0.5)"
                    gutterBottom
                  >
                    {t('branch.batumiText')}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="secondary"
                    href="https://maps.app.goo.gl/ugpUhqM7LprHsEU1A"
                    target="_blank"
                    sx={{
                      height: '0',
                      px: '60px',
                      borderRadius: '20px',
                      margin: {
                        xs: '5px auto 0',
                        md: '12px auto 0',
                        lg: '11px auto 0'
                      },
                      padding: '0'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '14px',
                          lg: i18n.language === 'en' ? '16px' : '14px'
                        },
                        fontWeight: i18n.language === 'en' ? 'bold' : '500',
                        backgroundImage: `
                                            linear-gradient(90deg, #6DFFE7 -10.2%, #55BEC0 51.5%, #55BEC0 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                        backgroundClip: 'text',
                        color: 'transparent',
                        borderBottom: '1px solid #6DFFE7',
                        marginTop: '-4px',
                        height: {
                          xs: '18px',
                          lg: i18n.language === 'en' ? '21px' : '19px'
                        },
                        fontFeatureSettings: "'case' on"
                      }}
                    >
                      {t('branch.seeLocation')}
                    </Typography>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: { xs: '12px', md: '16px' },
              paddingTop: { xs: 10, md: 10 },
              color: 'white'
            }}
          >
             <Typography
              sx={{
                fontSize: { xs: 16, md: 24, lg: 32 },
                fontWeight: 'bold'
              }}
              color="white"
            >
              {t('home.contactCardTitle')}
            </Typography>
          </Box>

          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'center',
              gap: '42px',
              marginTop: { md: '50px', lg: '70px' },
              marginBottom: { xs: '18px', md: '24px', lg: '30px' }
            }}
          >
            <Box
              sx={{
                mt: { xs: '24px', md: 'initial' },
                display: 'flex',
                alignItems: 'center',
                gap: { xs: '8px', lg: '16px' },
                fontSize: { xs: '16px', md: '20px' },
                color: 'white',
                fontWeight: '700',
                textDecoration: { xs: 'underline', md: 'initial' },
                justifyContent: { xs: 'center', md: 'initial' },
                height: { xs: '64px', md: 'auto' },
                boxShadow: {
                  xs: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  md: 'initial'
                },
                border: { xs: 'double 1px transparent', md: 'initial' },
                backgroundImage: {
                  xs: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                              linear-gradient(90deg, #6DFFE7 -10.2%,
                                #6DFFE7 51.5%, #55BEC0 102.62%)
                          `,
                  md: 'initial'
                },
                backgroundOrigin: { xs: 'border-box', md: 'initial' },
                backgroundClip: {
                  xs: 'padding-box, border-box',
                  md: 'initial'
                },
                borderRadius: { xs: '20px', md: 'initial' }
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '16px',
                  border: 'double 1px transparent',
                  backgroundImage:
                    'linear-gradient(90deg, #40354A 0%, #32395A 100.14%),linear-gradient(90deg, #6DFFE7 0%, #55BEC0 100.14%)',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  transform: { xs: 'scale(0.6)', md: 'initial' }
                }}
              >
                <PhoneIcon />
              </Box>
              +995 (32) 2 40 23 23
            </Box>

            <Box
              sx={{
                mt: { xs: '12px', md: 'initial' },
                display: 'flex',
                alignItems: 'center',
                gap: { xs: '8px', lg: '16px' },
                fontSize: { xs: '16px', md: '20px' },
                color: 'white',
                fontWeight: '700',
                textDecoration: { xs: 'underline', md: 'initial' },
                justifyContent: { xs: 'center', md: 'initial' },
                height: { xs: '64px', md: 'auto' },
                boxShadow: {
                  xs: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',
                  md: 'initial'
                },
                border: { xs: 'double 1px transparent', md: 'initial' },
                backgroundImage: {
                  xs: `
                  linear-gradient(90deg, #292a37 0%, #292a37 100.14%),
                  linear-gradient(90deg, #6DFFE7 -10.2%,
                    #6DFFE7 51.5%, #55BEC0 102.62%)
                          `,
                  md: 'initial'
                },
                backgroundOrigin: { xs: 'border-box', md: 'initial' },
                backgroundClip: {
                  xs: 'padding-box, border-box',
                  md: 'initial'
                },
                borderRadius: { xs: '20px', md: 'initial' }
              }}
            >
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '16px',
                  border: 'double 1px transparent',
                  backgroundImage:
                    'linear-gradient(90deg, #40354A 0%, #32395A 100.14%),linear-gradient(90deg, #6DFFE7 0%, #55BEC0 100.14%)',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  transform: { xs: 'scale(0.6)', md: 'initial' }
                }}
              >
                <MessageIcon />
              </Box>
              Info@betlive.club
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default HomeView
