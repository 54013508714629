import React from 'react'

const AppStoreIcon: React.FC = () => {
  return (
      <svg width="91" height="25" viewBox="0 0 91 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1769_17962)">
                      <path d="M13.8937 11.7737C13.9055 10.9916 14.1149 10.2252 14.5025 9.54582C14.89 8.86642 15.4432 8.29611 16.1104 7.88794C15.6884 7.28247 15.1311 6.78373 14.4827 6.43134C13.8342 6.07894 13.1126 5.88261 12.375 5.85792C10.8022 5.6896 9.27345 6.793 8.4719 6.79121C7.65444 6.78845 6.42235 5.86054 5.09731 5.88567C4.23662 5.91066 3.39712 6.15836 2.66072 6.60459C1.92432 7.05081 1.31618 7.68032 0.895651 8.43169C-0.923266 11.5655 0.417633 16.1802 2.14803 18.7198C3.01422 19.9634 4.02616 21.3534 5.35406 21.3067C6.65389 21.2553 7.14067 20.482 8.71005 20.4855C10.2644 20.489 10.7177 21.3187 12.0736 21.2908C13.468 21.2715 14.35 20.0487 15.1908 18.7967C15.8171 17.9131 16.2996 16.9361 16.6206 15.9018C15.8115 15.5574 15.1215 14.9825 14.6368 14.2488C14.1521 13.5151 13.894 12.6549 13.8946 11.7756L13.8937 11.7737Z" fill="white"/>
                      <path d="M11.3503 4.18793C12.1126 3.27673 12.4898 2.10425 12.4017 0.91947C11.2395 1.03892 10.1651 1.59185 9.39239 2.46809C9.01468 2.89618 8.72504 3.39451 8.54003 3.93459C8.35501 4.47468 8.27825 5.04593 8.31412 5.6157C8.89526 5.62305 9.47049 5.49836 9.99642 5.25103C10.5224 5.00371 10.9853 4.64021 11.3503 4.18793Z" fill="white"/>
                      <path d="M29.4732 18.3417L25.208 18.3322L24.1768 21.3544L22.3705 21.3504L26.4351 10.1696L28.3078 10.1738L32.3224 21.3726L30.4899 21.3685L29.4732 18.3417ZM25.653 16.9379L29.0343 16.9455L27.3785 12.0332L27.3317 12.0331L25.653 16.9379Z" fill="white"/>
                      <path d="M41.0613 17.3142C41.0557 19.8489 39.6952 21.478 37.6473 21.4735C37.1282 21.499 36.6125 21.3778 36.1591 21.1237C35.7057 20.8697 35.3331 20.493 35.0839 20.0369L35.0446 20.0368L35.0355 24.0773L33.3613 24.0736L33.3855 13.2171L35.0064 13.2208L35.0034 14.5776L35.0343 14.5777C35.2973 14.1252 35.678 13.7526 36.136 13.4993C36.594 13.2461 37.112 13.1218 37.6351 13.1396C39.7054 13.1442 41.067 14.7859 41.0613 17.3142ZM39.3403 17.3103C39.344 15.6585 38.4924 14.5704 37.1927 14.5675C35.9108 14.5646 35.0478 15.6714 35.0441 17.3007C35.0405 18.9441 35.8985 20.0472 37.1786 20.05C38.4811 20.0529 39.3347 18.9771 39.3384 17.3103L39.3403 17.3103Z" fill="white"/>
                      <path d="M50.0408 17.3342C50.0352 19.869 48.6738 21.4953 46.6268 21.4907C46.108 21.5165 45.5924 21.3957 45.1391 21.1422C44.6857 20.8886 44.313 20.5125 44.0634 20.057L44.0241 20.0569L44.015 24.0974L42.3408 24.0937L42.365 13.2372L43.9859 13.2408L43.9829 14.5976L44.0138 14.5977C44.2768 14.1453 44.6575 13.7726 45.1155 13.5194C45.5735 13.2661 46.0915 13.1418 46.6145 13.1596C48.6849 13.1642 50.0465 14.806 50.0408 17.3342ZM48.3188 17.3304C48.3225 15.6786 47.471 14.5905 46.1713 14.5875C44.8903 14.5847 44.0273 15.6914 44.0236 17.3208C44.02 18.9641 44.878 20.0672 46.1581 20.0701C47.4596 20.073 48.316 18.9962 48.3198 17.3304L48.3188 17.3304Z" fill="white"/>
                      <path d="M55.9724 18.3082C56.0936 19.4134 57.1697 20.149 58.6436 20.1523C60.0547 20.1555 61.0723 19.4292 61.0746 18.4282C61.0765 17.5592 60.4653 17.0382 59.0184 16.6782L57.5659 16.3266C55.5126 15.8257 54.5606 14.8619 54.5641 13.3028C54.5684 11.372 56.254 10.0499 58.6418 10.0552C61.0071 10.0605 62.625 11.39 62.676 13.321L60.9848 13.3172C60.8862 12.2008 59.9644 11.5245 58.616 11.5215C57.2677 11.5185 56.3429 12.1981 56.3406 13.1907C56.3389 13.982 56.9277 14.4487 58.369 14.8086L59.6025 15.1139C61.8973 15.6621 62.8485 16.5866 62.8448 18.2234C62.8401 20.3171 61.1704 21.6243 58.5186 21.6184C56.0297 21.6119 54.362 20.3281 54.2579 18.3044L55.9724 18.3082Z" fill="white"/>
                      <path d="M66.4736 11.3602L66.4693 13.2911L68.0199 13.2945L68.017 14.6204L66.4663 14.617L66.4563 19.1116C66.4547 19.8102 66.7648 20.1367 67.4465 20.1383C67.6306 20.1356 67.8144 20.1232 67.9972 20.1011L67.9943 21.4195C67.6876 21.4762 67.376 21.5016 67.0643 21.4952C65.4125 21.4915 64.7696 20.8692 64.7732 19.2877L64.7836 14.6132L63.5972 14.6106L63.6002 13.2846L64.7866 13.2873L64.7909 11.3565L66.4736 11.3602Z" fill="white"/>
                      <path d="M68.9096 17.3764C68.9153 14.8097 70.4312 13.1997 72.7881 13.205C75.145 13.2103 76.6546 14.827 76.6488 17.3937C76.6431 19.9603 75.1413 21.5703 72.7694 21.565C70.3975 21.5597 68.9039 19.9505 68.9096 17.3764ZM74.9427 17.3899C74.9467 15.6295 74.1418 14.5883 72.785 14.5852C71.4282 14.5822 70.6187 15.6273 70.6148 17.3802C70.6108 19.1481 71.4157 20.1808 72.7725 20.1839C74.1293 20.1869 74.9388 19.1587 74.9427 17.3899Z" fill="white"/>
                      <path d="M78.0389 13.3169L79.6363 13.3205L79.6332 14.7092L79.6716 14.7092C79.7803 14.2754 80.0351 13.8923 80.3932 13.6243C80.7513 13.3563 81.1907 13.2198 81.6376 13.2379C81.8307 13.2377 82.0232 13.2592 82.2115 13.3019L82.208 14.8694C81.9655 14.7948 81.7126 14.7601 81.4591 14.7666C81.2161 14.7559 80.9737 14.7979 80.7484 14.8897C80.5232 14.9815 80.3206 15.121 80.1543 15.2985C79.9881 15.4761 79.8623 15.6875 79.7855 15.9183C79.7087 16.1491 79.6828 16.3937 79.7095 16.6355L79.6986 21.4785L78.0206 21.4747L78.0389 13.3169Z" fill="white"/>
                      <path d="M89.9192 19.106C89.6912 20.5859 88.2459 21.5996 86.4012 21.5955C84.0275 21.5902 82.5581 19.9969 82.5638 17.4453C82.5695 14.8936 84.0546 13.2302 86.3497 13.2353C88.6064 13.2403 90.0225 14.7942 90.0169 17.27L90.0157 17.8412L84.2531 17.8283L84.2529 17.9294C84.2256 18.2297 84.2625 18.5323 84.3611 18.8171C84.4598 19.102 84.6179 19.3626 84.825 19.5817C85.0321 19.8007 85.2835 19.9732 85.5624 20.0876C85.8413 20.202 86.1414 20.2558 86.4426 20.2453C86.8382 20.2833 87.2355 20.1926 87.5754 19.9867C87.9153 19.7808 88.1797 19.4707 88.3292 19.1025L89.9192 19.106ZM84.2642 16.6578L88.3431 16.6669C88.358 16.3972 88.3172 16.1273 88.2232 15.874C88.1292 15.6207 87.984 15.3895 87.7967 15.1948C87.6093 15.0001 87.3839 14.8462 87.1344 14.7425C86.8849 14.6388 86.6168 14.5876 86.3467 14.5921C86.074 14.5899 85.8036 14.6417 85.551 14.7445C85.2984 14.8473 85.0687 14.9992 84.8751 15.1912C84.6815 15.3832 84.5279 15.6117 84.423 15.8634C84.3182 16.1152 84.2642 16.3851 84.2642 16.6578Z" fill="white"/>
                      <path d="M25.4753 1.74367C25.8263 1.7195 26.1782 1.7735 26.5058 1.90178C26.8335 2.03006 27.1285 2.22942 27.3698 2.4855C27.611 2.74157 27.7925 3.04799 27.901 3.38265C28.0096 3.71731 28.0425 4.07189 27.9975 4.42083C27.9937 6.13816 27.0635 7.12397 25.4632 7.12039L23.5212 7.11605L23.5332 1.73933L25.4753 1.74367ZM24.3581 6.35664L25.3713 6.3589C25.6222 6.37454 25.8734 6.33404 26.1066 6.24033C26.3398 6.14663 26.5492 6.00209 26.7195 5.81723C26.8898 5.63238 27.0168 5.41186 27.0911 5.17175C27.1654 4.93164 27.1852 4.67797 27.1491 4.42923C27.1836 4.18165 27.1629 3.92949 27.0885 3.69085C27.0141 3.4522 26.8879 3.23297 26.7188 3.04886C26.5497 2.86475 26.342 2.72032 26.1105 2.62592C25.879 2.53153 25.6295 2.48951 25.3799 2.50287L24.3667 2.5006L24.3581 6.35664Z" fill="white"/>
                      <path d="M28.9417 5.09713C28.9165 4.83029 28.9474 4.56113 29.0325 4.30694C29.1175 4.05275 29.2547 3.81914 29.4353 3.62112C29.616 3.4231 29.836 3.26504 30.0813 3.15709C30.3267 3.04914 30.5919 2.99369 30.8599 2.99429C31.1279 2.99488 31.3929 3.05153 31.6377 3.16057C31.8826 3.26962 32.1019 3.42866 32.2816 3.62748C32.4614 3.82631 32.5976 4.06052 32.6815 4.31509C32.7653 4.56966 32.795 4.83895 32.7687 5.10569C32.7939 5.37253 32.7629 5.64169 32.6779 5.89588C32.5929 6.15007 32.4557 6.38368 32.275 6.5817C32.0944 6.77972 31.8744 6.93778 31.629 7.04573C31.3837 7.15368 31.1185 7.20913 30.8505 7.20853C30.5825 7.20793 30.3175 7.15129 30.0727 7.04225C29.8278 6.9332 29.6085 6.77416 29.4287 6.57534C29.249 6.37651 29.1128 6.1423 29.0289 5.88773C28.9451 5.63316 28.9153 5.36387 28.9417 5.09713ZM31.9447 5.10384C31.9466 4.22458 31.5573 3.70963 30.8597 3.70807C30.1621 3.70651 29.7705 4.21972 29.7685 5.09898C29.7666 5.97825 30.1568 6.49694 30.8535 6.4985C31.5501 6.50006 31.9427 5.98686 31.9447 5.10384Z" fill="white"/>
                      <path d="M37.8512 7.14809L37.0206 7.14624L36.1845 4.15635L36.1256 4.15621L35.2836 7.14235L34.4605 7.14052L33.3516 3.08066L34.1634 3.08247L34.8831 6.18072L34.9431 6.18086L35.7862 3.0861L36.554 3.08781L37.3805 6.1863L37.4441 6.18645L38.1739 3.09143L38.9746 3.09322L37.8512 7.14809Z" fill="white"/>
                      <path d="M39.9148 3.09532L40.6855 3.09704L40.6841 3.74127L40.744 3.74141C40.8457 3.50968 41.0172 3.31544 41.2345 3.18569C41.4518 3.05594 41.7041 2.99714 41.9564 3.01748C42.1539 3.00298 42.3521 3.03313 42.5364 3.1057C42.7207 3.17827 42.8862 3.2914 43.0208 3.43669C43.1554 3.58198 43.2556 3.75571 43.3139 3.94498C43.3722 4.13426 43.3871 4.33423 43.3576 4.53007L43.3517 7.16038L42.5511 7.15859L42.5565 4.73335C42.558 4.07788 42.2778 3.75607 41.6832 3.75474C41.5489 3.74812 41.4149 3.77063 41.2902 3.82075C41.1655 3.87086 41.0531 3.94738 40.9608 4.04505C40.8685 4.14271 40.7984 4.25921 40.7554 4.38653C40.7124 4.51384 40.6975 4.64897 40.7117 4.7826L40.7064 7.15447L39.9058 7.15268L39.9148 3.09532Z" fill="white"/>
                      <path d="M44.6406 1.52148L45.4412 1.52327L45.4286 7.165L44.628 7.16321L44.6406 1.52148Z" fill="white"/>
                      <path d="M46.5467 5.13647C46.5223 4.86997 46.5537 4.60131 46.6391 4.34768C46.7245 4.09404 46.8619 3.86103 47.0425 3.66355C47.2231 3.46607 47.4429 3.30847 47.6879 3.20085C47.933 3.09322 48.1978 3.03794 48.4654 3.03854C48.733 3.03913 48.9975 3.0956 49.2421 3.20432C49.4866 3.31304 49.7057 3.47162 49.8855 3.6699C50.0652 3.86819 50.2015 4.10181 50.2858 4.35582C50.37 4.60984 50.4003 4.87864 50.3746 5.14502C50.3991 5.41152 50.3676 5.68018 50.2822 5.93381C50.1969 6.18744 50.0595 6.42046 49.8789 6.61794C49.6983 6.81542 49.4784 6.97302 49.2334 7.08064C48.9884 7.18827 48.7236 7.24355 48.456 7.24295C48.1884 7.24235 47.9238 7.18589 47.6793 7.07717C47.4347 6.96845 47.2156 6.80987 47.0359 6.61159C46.8562 6.4133 46.7198 6.17967 46.6356 5.92566C46.5513 5.67165 46.5211 5.40285 46.5467 5.13647ZM49.5497 5.14318C49.5517 4.26391 49.1623 3.74896 48.4647 3.7474C47.7671 3.74585 47.3755 4.25905 47.3735 5.13831C47.3716 6.01758 47.7618 6.53628 48.4585 6.53783C49.1552 6.53939 49.5477 6.02619 49.5497 5.14318Z" fill="white"/>
                      <path d="M51.215 6.02994C51.2166 5.29956 51.7616 4.8794 52.7272 4.82163L53.8257 4.76134L53.8265 4.41114C53.8274 3.98414 53.5452 3.74005 52.9993 3.73883C52.5526 3.73783 52.2423 3.90101 52.1555 4.18829L51.3755 4.18654C51.4595 3.48912 52.1159 3.04393 53.0364 3.04599C54.0533 3.04826 54.6271 3.55613 54.6252 4.41292L54.619 7.18556L53.8474 7.18383L53.8487 6.61358L53.785 6.61343C53.656 6.8174 53.4753 6.98353 53.2612 7.0949C53.0471 7.20628 52.8074 7.25892 52.5663 7.24746C52.3958 7.26502 52.2235 7.24662 52.0605 7.19345C51.8976 7.14029 51.7476 7.05354 51.6203 6.9388C51.4929 6.82406 51.3911 6.6839 51.3212 6.52735C51.2514 6.3708 51.2152 6.20135 51.215 6.02994V6.02994ZM53.8236 5.6893L53.8244 5.35033L52.8335 5.41179C52.2716 5.44799 52.0212 5.63752 52.0204 5.99522C52.0196 6.35292 52.3356 6.57368 52.7682 6.57464C52.8958 6.58791 53.0248 6.57536 53.1474 6.53775C53.27 6.50013 53.3839 6.43822 53.4821 6.35569C53.5803 6.27317 53.6609 6.17173 53.7191 6.0574C53.7773 5.94307 53.8119 5.81821 53.8208 5.69023L53.8236 5.6893Z" fill="white"/>
                      <path d="M55.6755 5.15688C55.6784 3.87496 56.3357 3.06458 57.3657 3.06688C57.6193 3.05571 57.8709 3.11695 58.091 3.24347C58.3111 3.36998 58.4906 3.55654 58.6086 3.78132L58.6685 3.78145L58.6735 1.55285L59.4741 1.55464L59.4615 7.19637L58.6927 7.19465L58.6941 6.55322L58.6305 6.55308C58.5033 6.77652 58.3172 6.96078 58.0926 7.08576C57.8679 7.21075 57.6132 7.27167 57.3563 7.26189C56.3263 7.25959 55.6727 6.44253 55.6755 5.15688ZM56.5024 5.15872C56.5004 6.01926 56.9047 6.53799 57.5836 6.53951C58.2625 6.54102 58.6766 6.01663 58.6785 5.16733C58.6804 4.31803 58.2602 3.78803 57.5898 3.78653C56.9193 3.78503 56.5043 4.30568 56.5024 5.15872Z" fill="white"/>
                      <path d="M62.7776 5.17276C62.7525 4.90591 62.7834 4.63675 62.8684 4.38256C62.9534 4.12837 63.0906 3.89477 63.2713 3.69675C63.4519 3.49873 63.672 3.34067 63.9173 3.23272C64.1626 3.12477 64.4278 3.06931 64.6958 3.06991C64.9639 3.07051 65.2288 3.12715 65.4737 3.23619C65.7185 3.34524 65.9378 3.50428 66.1176 3.70311C66.2973 3.90193 66.4335 4.13615 66.5174 4.39071C66.6013 4.64528 66.631 4.91458 66.6046 5.18131C66.6298 5.44815 66.5989 5.71731 66.5139 5.9715C66.4288 6.2257 66.2916 6.4593 66.111 6.65732C65.9303 6.85534 65.7103 7.0134 65.465 7.12135C65.2196 7.2293 64.9545 7.28475 64.6864 7.28416C64.4184 7.28356 64.1534 7.22692 63.9086 7.11787C63.6638 7.00883 63.4444 6.84978 63.2647 6.65096C63.0849 6.45213 62.9487 6.21792 62.8649 5.96335C62.781 5.70878 62.7513 5.43949 62.7776 5.17276ZM65.7806 5.17947C65.7826 4.3002 65.3933 3.78525 64.6956 3.78369C63.998 3.78213 63.6064 4.29534 63.6045 5.1746C63.6025 6.05387 63.9927 6.57257 64.6894 6.57412C65.3861 6.57568 65.7786 6.06248 65.7806 5.17947Z" fill="white"/>
                      <path d="M67.6834 3.15736L68.454 3.15908L68.4526 3.80331L68.5125 3.80345C68.6143 3.57173 68.7857 3.37748 69.003 3.24773C69.2203 3.11798 69.4726 3.05919 69.7249 3.07952C69.9224 3.06502 70.1207 3.09517 70.3049 3.16774C70.4892 3.24032 70.6548 3.35344 70.7894 3.49873C70.924 3.64402 71.0241 3.81775 71.0824 4.00703C71.1407 4.1963 71.1556 4.39628 71.1261 4.59211L71.1202 7.22242L70.3196 7.22063L70.325 4.79539C70.3265 4.13992 70.0463 3.81811 69.4517 3.81678C69.3175 3.81016 69.1834 3.83268 69.0587 3.88279C68.934 3.9329 68.8217 4.00942 68.7294 4.10709C68.6371 4.20475 68.567 4.32125 68.524 4.44857C68.481 4.57589 68.4661 4.71101 68.4802 4.84464L68.4749 7.21651L67.6743 7.21472L67.6834 3.15736Z" fill="white"/>
                      <path d="M75.6567 2.16482L75.6544 3.19485L76.5337 3.19681L76.5322 3.87101L75.6529 3.86904L75.6482 5.95437C75.6473 6.37949 75.822 6.56623 76.2209 6.56712C76.3229 6.56693 76.4249 6.56091 76.5262 6.54907L76.5247 7.21578C76.3809 7.24127 76.2352 7.25472 76.0892 7.25601C75.1987 7.25402 74.8445 6.93953 74.8462 6.15765L74.8514 3.86632L74.2071 3.86488L74.2086 3.19161L74.8529 3.19305L74.8552 2.16303L75.6567 2.16482Z" fill="white"/>
                      <path d="M77.6309 1.59521L78.4249 1.59699L78.4199 3.83308L78.4827 3.83322C78.5896 3.59983 78.7659 3.4051 78.9876 3.27557C79.2092 3.14603 79.4654 3.08799 79.7212 3.10935C79.9177 3.09893 80.114 3.13203 80.2962 3.20627C80.4784 3.28051 80.642 3.39407 80.7752 3.53883C80.9084 3.68359 81.008 3.85598 81.0669 4.0437C81.1258 4.23141 81.1425 4.42981 81.1159 4.62473L81.11 7.24661L80.3085 7.24482L80.3139 4.82052C80.3153 4.17254 80.0146 3.8432 79.4481 3.84193C79.3103 3.83027 79.1717 3.84888 79.0419 3.89646C78.9121 3.94404 78.7943 4.01943 78.6968 4.11736C78.5992 4.21529 78.5243 4.33337 78.4772 4.46333C78.4301 4.59329 78.412 4.73198 78.4242 4.86967L78.4189 7.23873L77.6183 7.23694L77.6309 1.59521Z" fill="white"/>
                      <path d="M85.7834 6.15961C85.6736 6.53051 85.4368 6.85095 85.1145 7.06475C84.7921 7.27856 84.4048 7.37209 84.0204 7.32896C83.7531 7.33523 83.4877 7.28329 83.2426 7.17672C82.9974 7.07016 82.7783 6.91153 82.6006 6.71184C82.4229 6.51216 82.2907 6.27619 82.2133 6.02032C82.1359 5.76445 82.1151 5.4948 82.1523 5.23008C82.1177 4.96454 82.1404 4.69465 82.2189 4.43864C82.2975 4.18262 82.43 3.94642 82.6076 3.74598C82.7852 3.54554 83.0037 3.38552 83.2484 3.27671C83.4931 3.1679 83.7583 3.11283 84.0261 3.11522C85.1553 3.11774 85.8353 3.89085 85.8325 5.16527L85.8318 5.44618L82.9665 5.43978L82.9664 5.48473C82.9538 5.63343 82.9724 5.78313 83.021 5.92424C83.0695 6.06535 83.1471 6.19476 83.2485 6.30419C83.35 6.41363 83.4732 6.50067 83.6102 6.55976C83.7473 6.61884 83.8951 6.64867 84.0444 6.64732C84.2359 6.67075 84.43 6.63673 84.6021 6.54958C84.7742 6.46243 84.9165 6.32609 85.0109 6.15788L85.7834 6.15961ZM82.9678 4.84518L85.0166 4.84975C85.0269 4.7137 85.0086 4.57701 84.9629 4.44844C84.9173 4.31986 84.8453 4.20225 84.7515 4.10313C84.6578 4.004 84.5443 3.92556 84.4185 3.87283C84.2927 3.82009 84.1572 3.79423 84.0208 3.7969C83.8823 3.79483 83.7449 3.82049 83.6165 3.87238C83.4881 3.92427 83.3714 4.00133 83.2733 4.09903C83.1751 4.19672 83.0976 4.31307 83.0451 4.44122C82.9926 4.56936 82.9664 4.70671 82.9678 4.84518Z" fill="white"/>
              </g>
              <defs>
                      <clipPath id="clip0_1769_17962">
                              <rect width="23.1082" height="90" fill="white" transform="translate(0 24) rotate(-89.872)"/>
                      </clipPath>
              </defs>
      </svg>

  )
}

export default AppStoreIcon
