import React, { ReactElement } from 'react'
import { Paper, Typography } from '@mui/material'
import { PageContentInterface } from '../../interfaces/page/PageContentInterface'
import { PageContentPaperSx, PageContentTypographySx } from '../../config/styles/page/PageStyles'

/**
 *
 * @param title
 * @constructor
 */
const PageContent = ({ content }: PageContentInterface): ReactElement => {
  return (
      <Paper sx={PageContentPaperSx}>
          <Typography
              color="white"
              fontWeight="normal"
              dangerouslySetInnerHTML={{ __html: content }}
              sx={PageContentTypographySx}
          />
      </Paper>
  )
}

export default PageContent
