import React from 'react'

const FacebookIcon: React.FC = () => {
  return (
      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.80337 0.220661C2.7284 0.292696 2.03021 0.675034 1.70329 1.36213C1.47056 1.85529 1.42069 2.18222 1.42069 3.17408V4.05512L0.772379 4.07174L0.118526 4.08837L0.101903 5.04144L0.0908203 6.00006H0.755755H1.42069V8.90915V11.8182H2.69515H3.96961V8.90915V6.00006H4.82848C5.67073 6.00006 5.68736 6.00006 5.70952 5.87261C5.73723 5.7507 5.909 4.22135 5.909 4.11607C5.909 4.08283 5.53775 4.06066 4.93376 4.06066H3.95853L3.98069 3.29599C4.00286 2.37062 4.04718 2.27088 4.46831 2.18222C4.62346 2.14897 5.01134 2.12127 5.32718 2.12127H5.909V1.15157V0.181873L4.98363 0.192955C4.46831 0.198497 3.9419 0.209579 3.80337 0.220661Z" fill="#282937"/>
      </svg>

  )
}

export default FacebookIcon
