import React, { useEffect, useState } from 'react'
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Stack
} from '@mui/material'
import { Page } from '../services/api/page'
import GetClubCardModal from '../components/Modal/GetClubCardModal'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { getCookie } from 'typescript-cookie'
import { useBus } from 'react-bus'

const ContentBox = styled(Box)(({ theme }) => ({
  '& p': {
    margin: 0,
    padding: 0
  },

  '& ul': {
    margin: '0 0 0 -17px'
  }
}))

const TermsPaper = styled(Paper)(({ theme }) => ({
  '&': {
    padding: '30px 39px 14px',
    border: 'double 1px transparent',
    backgroundImage: `
                              linear-gradient(90deg, #292a37 0%, #292a37 100.14%), 
                              linear-gradient(90deg, #6DFFE7 -34.13%, #FFFFFF 124.6%)
                          `,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    boxShadow: '0px 27.543px 48.4377px rgba(0, 0, 0, 0.25)',

    [theme.breakpoints.down('md')]: {
      padding: '16px 23px 2px'
    },

    '& ul': {
      marginTop: '-14px',
      paddingLeft: '23px',

      '& li': {
        lineHeight: '24px'
      }
    }
  }
}))

const TermsBox = styled(Box)(({ theme }) => ({
  '&.show-character::before': {
    content: '""',
    display: 'block',
    width: '590px',
    height: '609px',
    // background: 'url("/static/club-card-character.svg") no-repeat',
    position: 'absolute',
    top: '-609px',
    right: '0',

    [theme.breakpoints.down('lg')]: {
      // background: 'url("/static/club-card-character-small.svg") no-repeat',
      width: '408px',
      height: '491px',
      top: '-491px',
      right: '0'
    },

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}))

const ClubCardView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const bus = useBus()
  const [page, setPage] = useState<any>({})
  const loggedIn = getCookie('token') !== undefined
  const [getClubCardModal, setGetClubCardModal] = React.useState(false)
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/club-card-bg-mobile.jpg'
      : mediaQuerySmall
        ? '/static/club-card-bg.jpg'
        : '/static/club-card-bg.jpg'
  )

  useEffect(() => {
    Page.getClubCardPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [])

  useEffect(() => {
    Page.getClubCardPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setGetClubCardModal(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOpenGetClubCardModal = () => {
    setGetClubCardModal(true)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOpenLoginModal = () => {
    bus.emit('onEmitLoginModal')
  }

  return (
    <>
      <Box
        sx={{
          paddingTop: { xs: '0', md: '102px' },
          paddingBottom: { xs: '0', md: '0' }
        }}
      >
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '74px'
          }}
        >
          <Typography color="white" fontWeight="bold">
            Club Card
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: { xs: '236px', md: '485px' },
            padding: { xs: '18px 0', md: '34px 0' },
            backgroundColor: 'rgba(38, 44, 60, 0.5)',
            backgroundFilter: 'blur(25px)',
            '&::before': {
              content: '""',
              display: mediaQueryMobile ? 'none' : 'block',
              width: '590px',
              height: '609px',
              // background: 'url("/static/club-card-queen.svg") no-repeat',
              position: 'absolute',
              bottom: mediaQuerySmall ? 'initial' : '-37px',
              top: mediaQuerySmall ? '0' : 'initial',
              left: mediaQuerySmall ? '-80px' : '0',
              backgroundSize: mediaQuerySmall ? '207px' : 'initial'
            }
          }}
        >
          <Container
            maxWidth={false}
            sx={{ maxWidth: { md: '1192px', lg: '1280px' } }}
            disableGutters={true}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingX={{ xs: '16px', md: '40px', lg: 0 }}
            >
              <Typography
                sx={{
                  display: { xs: 'none', md: 'inline-block' },
                  width: '39.06%',
                  fontSize: '128px',
                  fontWeight: '750',
                  lineHeight: '128px',
                  backgroundImage:
                    'linear-gradient(90deg, #6DFFE7 -34.13%, #FFFFFF 124.6%)',
                  backgroundClip: 'text',
                  color: 'transparent'
                }}
              >
                Club Card
              </Typography>

              <Box
                sx={{
                  width: { xs: '100%', md: 'auto' },
                  height: { xs: '200px', md: '417px' },
                  marginRight: { md: '124px', lg: '134px' }
                }}
              >
                <img
                  src="/static/club-card-large.svg"
                  width="100%"
                  height="100%"
                  alt="#"
                />
              </Box>
            </Box>
          </Container>
        </Box>
        <Container
          maxWidth={false}
          sx={{ maxWidth: { md: '1192px', lg: '1280px' } }}
          disableGutters={true}
        >
          <ContentBox
            paddingY={{ xs: '34px', md: '59px' }}
            paddingX={{ xs: '16px', md: '40px', lg: 0 }}
          >
            <Typography
              color="white"
              fontWeight="normal"
              lineHeight={{ lg: '32px' }}
              fontSize={{ xs: '14px', md: '16px' }}
              dangerouslySetInnerHTML={{ __html: page?.content }}
            />

            <Button
              onClick={loggedIn ? handleOpenGetClubCardModal : handleOpenLoginModal}
              variant="contained"
              size="large"
              sx={{
                margin: { xs: '30px auto', md: '60px 0 61px' },
                fontSize: { xs: '16px', md: '24px' },
                fontWeight: { xs: '700', md: i18n.language === 'en' ? '750' : '600' },
                padding: 0,
                width: { xs: '170px', md: i18n.language === 'en' ? '246px' : '267px' },
                height: { xs: '58px', md: '80px' },
                display: 'flex'
              }}
            >
                {t('clubCard.getCardTitle')}
            </Button>
          </ContentBox>
        </Container>

        <TermsBox
            position="relative"
          display="flex"
          alignItems="center"
          paddingY={{ xs: '34px', md: '68px' }}
            className={page?.terms?.length > 0 ? 'show-character' : '' }
          sx={{
            background:
              'linear-gradient(90deg, rgb(23 24 34 / 40%) -0.7%, #28293754 19.75%, #3c3d504d 50.7%, #2829376e 80.07%, #17182287 100%)',
            backdropFilter: 'blur(37.5px)'
          }}
        >
          <Container
            maxWidth={false}
            sx={{ maxWidth: { md: '1192px', lg: '1280px' } }}
            disableGutters={true}
          >
            <Typography
              sx={{
                display: 'table',
                margin: { xs: '0 auto 33px', md: '0 auto 58px' },
                fontSize: { xs: '18px', md: '32px' },
                color: 'white',
                fontWeight: i18n.language === 'en' ? '900' : '700'
              }}
            >
                {page.title}
            </Typography>
            <Stack
              spacing={3}
              sx={{
                paddingX: { xs: '16px', md: '40px', lg: '55px' },
                marginBottom: '32px'
              }}
            >
              {page?.terms?.map((item: any) => (
                <TermsPaper
                  key={item?.name}
                >
                  <Typography
                    sx={{
                      display: 'inline-block',
                      fontSize: { xs: '16px', md: '24px' },
                      fontWeight: 'bold',
                      backgroundImage: `
                      linear-gradient(90deg, #6DFFE7 -34.13%, #FFFFFF 124.6%)
                                  `,
                      backgroundClip: 'text',
                      color: 'transparent'
                    }}
                  >
                    {item?.[0]?.value}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', md: '16px' },
                      fontWeight: { xs: 'normal', md: 'bold' },
                      lineHeight: { xs: '24px', md: '38px' },
                      marginTop: '-12px'
                    }}
                    dangerouslySetInnerHTML={{ __html: item?.[1]?.value }}
                  />
                </TermsPaper>
              ))}
            </Stack>
          </Container>
        </TermsBox>
      </Box>
      <GetClubCardModal isOpen={getClubCardModal} onClose={handleClose} />
    </>
  )
}

export default ClubCardView
