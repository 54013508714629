import React, { ReactElement } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ArrowRightIcon from '../Icon/ArrowRightIcon'
import { CareerCardInterface } from '../../interfaces/career/CareerCardInterface'
import {
  CareerCardBoxSx,
  CareerCardRightSx,
  CareerCardSx,
  CareerCardTypographySx
} from '../../config/styles/career/CareerStyles'

/**
 *
 * @param title
 * @param item
 * @param setItem
 * @constructor
 */
const CareerCard = ({ title, item, setItem }: CareerCardInterface): ReactElement => {
  return (
    <Button
      variant="outlined"
      color="info"
      sx={CareerCardSx}
      onClick={() => {
        setItem(item)
      }}
    >
      <Box sx={CareerCardBoxSx}>
        <Typography
          sx={CareerCardTypographySx}
          color="white"
        >
          {title}
        </Typography>
      </Box>

      <Box sx={CareerCardRightSx}>
        <ArrowRightIcon width={28} height={28} />
      </Box>
    </Button>
  )
}

export default CareerCard
