import React, { useEffect, useState } from 'react'
import { Container, Box, Typography } from '@mui/material'
import HeroCarousel from '../components/Carousel/Hero/HeroCarousel'
import { Page } from '../services/api/page'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'

const GalleryView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState<any[]>([])
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  useEffect(() => {
    Page.getGalleryPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [])

  return (
    <Box
      sx={{
        backgroundImage: {
          xs: 'url("/static/gallery-mobile.jpg")',
          md: 'url("/static/gallery-xl.webp")',
          lg: 'url("/static/gallery-xl.webp")',
          xl: 'url("/static/gallery-xl.webp")'
        },
        height: { xs: 'calc(100vh - 316px)', sm: 'initial' },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: { xs: '0', md: '102px' }
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '140px'
        }}
      >
        <Typography color="white" fontSize="16px" fontWeight="700">
            {t('gallery.title')}
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: { xs: 'auto', md: 'auto', lg: '556px' }
        }}
      >
        <Box
          sx={{
            background: 'rgba(38, 44, 60, 0.5)',
            height: { xs: 'auto', md: '500px', lg: '556px' },
            '&::before': {
              content: '""',
              display: mediaQueryMobile ? 'none' : 'block',
              width: mediaQuerySmall ? '100%' : mediaQueryMobile ? '100%' : '50%',
              height: mediaQuerySmall ? '696px' : mediaQueryMobile ? '657px' : '657px',
              background: mediaQuerySmall ? 'url("/static/gallery-left-sm.svg") no-repeat' : mediaQueryMobile ? 'url("/static/gallery-left.svg") no-repeat' : 'url("/static/gallery-left.svg") no-repeat',
              backgroundSize: 'cover',
              position: 'absolute',
              bottom: '0',
              left: '-40px'
            },

            '&::after': {
              content: '""',
              display: mediaQuerySmall ? 'none' : 'block',
              width: '404px',
              height: '657px',
              background: 'url("/static/gallery-right.svg") no-repeat',
              backgroundSize: 'cover',
              position: 'absolute',
              bottom: '0',
              right: '0'
            }
          }}
        >
          <Container
            maxWidth={false}
            sx={{ maxWidth: { md: '1192px', lg: '1280px' } }}
            disableGutters={true}
          >
            <Box
              height={{ xs: 'auto', md: '575px', lg: '579px' }}
              sx={{
                display: { xs: 'block', md: 'flex' },
                padding: { xs: '16px', md: '40px', lg: '40px 0 0 0' },
                paddingBottom: { xs: '0', md: '109px' },
                marginBottom: { xs: '94px', md: '0px' },
                justifyContent: 'space-between',
                position: 'relative',
                zIndex: 2
              }}
            >
              <Box
                sx={{
                  width: { md: '285px', lg: '320px' },
                  height: { md: '160px' },
                  display: { xs: 'none', md: 'block' },
                  marginRight: '23px'
                }}
              >
                <Typography
                  sx={{
                    marginTop: '52px',
                    marginBottom: '24px',
                    fontFamily: 'Helvetica Neue LT GEO',
                    fontStyle: 'normal',
                    fontWeight: i18n.language === 'en' ? '750' : '600',
                    fontSize: 48,
                    lineHeight: '110%'
                  }}
                  color="white"
                >
                    {t('gallery.title')}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Helvetica',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: '145.5%',
                    width: { md: '285px', lg: '320px' },
                    height: { md: '85px' }
                  }}
                  color="white"
                >
                    {t('gallery.text')}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: { xs: '100%', md: '806px', lg: '937px' },
                  height: { xs: 'auto', md: '370px', lg: '430px' },
                  borderRadius: '45px',
                  overflow: 'hidden',
                  backgroundColor: 'transparent !important'
                }}
              >
                <HeroCarousel items={page} name="full_src" />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default GalleryView
