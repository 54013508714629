import React from 'react'

const GeorgiaCircleIcon: React.FC = () => {
  return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.99984 18.3333C14.5832 18.3333 18.3332 14.5833 18.3332 9.99996C18.3332 5.41663 14.5832 1.66663 9.99984 1.66663C5.4165 1.66663 1.6665 5.41663 1.6665 9.99996C1.6665 14.5833 5.4165 18.3333 9.99984 18.3333Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M6.4585 10.0001L8.81683 12.3584L13.5418 7.64172" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}

export default GeorgiaCircleIcon
