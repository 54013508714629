import { getLink } from './LinkHelper'

/**
 *
 * @param item
 */
export const getPromotionId = (item: any): string => item?.id

/**
 *
 * @param item
 */
export const getPromotionTo = (item: any): string =>
  getLink(`promotions/offers/${getPromotionId(item)}`)

/**
 *
 * @param item
 */
export const getPromotionImage = (item: any): string => item?.image_url

/**
 *
 * @param item
 */
export const getPromotionTitle = (item: any): string =>
  item?.title?.length > 25
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    ? `${item?.title?.substring(0, 25)} ...`
    : item?.title

/**
 *
 * @param item
 */
export const getPromotionShortDescription = (item: any): string =>
  item?.sub_title?.length > 110
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    ? `${item?.sub_title?.substring(0, 100)} ...`
    : item?.sub_title

/**
 *
 * @param item
 */
export const getPromotionShortDescriptionHtml = (item: any): object => {
  return { __html: getPromotionShortDescription(item) }
}
