import React from 'react'
import HomeView from '../views/HomeView'
import TestView from '../views/TestView'
import AboutView from '../views/AboutView'
import GalleryView from '../views/GalleryView'
import CareerView from '../views/CareerView'
import ClubCardView from '../views/ClubCardView'
import FaqView from '../views/FaqView'
import PrivacyPolicyView from '../views/PrivacyPolicyView'
import TermsView from '../views/TermsView'
import PromotionsListView from '../views/PromotionsListView'
import PromotionsSingleView from '../views/PromotionsSingleView'
import NewsListView from '../views/NewsListView'
import NewsSingleView from '../views/NewsSingleView'
import ProfileView from '../views/Account/ProfileView'
import ProfileOffersView from '../views/Account/OffersView'
import MyCardView from '../views/Account/MyCardView'
import LeaderBoardListView from '../views/LeaderBoardListView'
import LeaderBordSingle from '../views/LeaderBordSingle'
import JobView from '../views/JobView'
import StreamsView from '../views/StreamsView'
import StreamsSingleView from '../views/StreamsSingleView'
import AppView from '../views/AppView'
import NotFound from '../views/NotFound'

const routes = [
  {
    path: '',
    name: 'Home',
    element: <HomeView />
  },

  {
    path: 'test',
    name: 'Test',
    element: <TestView />
  },

  {
    path: 'about-us',
    name: 'About',
    element: <AboutView />
  },

  {
    path: 'gallery',
    name: 'Gallery',
    element: <GalleryView />
  },

  {
    path: 'careers',
    name: 'Career',
    element: <CareerView />
  },

  {
    path: 'club-card',
    name: 'Club Card',
    element: <ClubCardView />
  },

  {
    path: 'faq',
    name: 'Faq',
    element: <FaqView />
  },

  {
    path: 'privacy',
    name: 'Privacy Policy',
    element: <PrivacyPolicyView />
  },

  {
    path: 'terms-conditions',
    name: 'Terms',
    element: <TermsView />
  },

  {
    path: 'promotions/offers',
    name: 'Promotions',
    element: <PromotionsListView />
  },

  {
    path: 'promotions/offers/:id',
    name: 'Promotions Single',
    element: <PromotionsSingleView />
  },

  {
    path: 'blog',
    name: 'News',
    element: <NewsListView />
  },

  {
    path: 'blog/:slug',
    name: 'News Single',
    element: <NewsSingleView />
  },

  {
    path: 'profile/account',
    name: 'My Account',
    element: <ProfileView />
  },

  {
    path: 'profile/offers',
    name: 'My Offers',
    element: <ProfileOffersView />
  },

  {
    path: 'profile/card',
    name: 'My Card',
    element: <MyCardView />
  },

  {
    path: 'branch/leaderboard',
    name: 'Leaderboard',
    element: <LeaderBoardListView />
  },

  {
    path: 'branch/leaderboard/:id',
    name: 'Leaderboard Branch',
    element: <LeaderBordSingle />
  },

  {
    path: 'jobs',
    name: 'Job',
    element: <JobView />
  },

  {
    path: 'streams',
    name: 'Streams',
    element: <StreamsView />
  },

  {
    path: 'streams/:id',
    name: 'StreamsSingle',
    element: <StreamsSingleView />
  },

  {
    path: 'app',
    name: 'App',
    element: <AppView />
  },

  {
    path: '*',
    name: 'notFound',
    element: <NotFound />
  }
]

export default routes
