import i18n from 'i18next'
import translation from './en.json'
// import { initReactI18next } from 'react-i18next'
// import I18NextHttpBackend from 'i18next-http-backend'
import HttpApi from 'i18next-http-backend'

export const resources = {
  en: {
    translation
  }
} as const

void i18n.use(HttpApi).init({
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  lng: 'en',
  fallbackLng: 'en',
  preload: ['en', 'ka'],
  // ns: ['account'],
  // defaultNS: 'account',
  backend: {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    loadPath: `${process.env.REACT_APP_API_URL}/{{lng}}/translations`,
    parse: (data) => {
      const json = JSON.parse(data)
      return json
    }
  }
  // lng: 'en',
  // interpolation: {
  //   escapeValue: false // not needed for react as it escapes by default
  // },
  // resources
}, (err: any, t: (...params: any[]) => string) => {
  // if (err) return console.error(err)
  console.log(err)
})

export default i18n
