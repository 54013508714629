import React from 'react'

const UserIcon: React.FC = () => {
  return (
      <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.84455 20.6619C4.15273 20.6619 1 20.0874 1 17.7867C1 15.4859 4.13273 13.3619 7.84455 13.3619C11.5364 13.3619 14.6891 15.4653 14.6891 17.7661C14.6891 20.0659 11.5564 20.6619 7.84455 20.6619Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M7.83725 10.1737C10.26 10.1737 12.2236 8.21002 12.2236 5.7873C12.2236 3.36457 10.26 1.40002 7.83725 1.40002C5.41452 1.40002 3.44998 3.36457 3.44998 5.7873C3.4418 8.20184 5.3918 10.1655 7.80634 10.1737C7.81725 10.1737 7.82725 10.1737 7.83725 10.1737Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}

export default UserIcon
