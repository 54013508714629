import React from 'react'

const MenuIcon: React.FC = () => {
  return (
      <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="14" width="22" height="2" rx="1" fill="white"/>
          <rect width="22" height="2" rx="1" fill="white"/>
          <rect y="7" width="22" height="2" rx="1" fill="white"/>
      </svg>

  )
}

export default MenuIcon
