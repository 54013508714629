import React from 'react'

const BackIcon: React.FC = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_b_607_7061)">
              <rect width="30" height="30" rx="10" fill="#BCBBC6" fillOpacity="0.1"/>
              <rect x="0.5" y="0.5" width="29" height="29" rx="9.5" stroke="#BCBBC6" strokeOpacity="0.2"/>
          </g>
          <path d="M12.9753 9.94165L7.91699 15L12.9753 20.0583" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M22.0836 15H8.05859" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          <defs>
              <filter id="filter0_b_607_7061" x="-4" y="-4" width="38" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_607_7061"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_607_7061" result="shape"/>
              </filter>
          </defs>
      </svg>

  )
}

export default BackIcon
