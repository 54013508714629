import React, { ReactElement } from 'react'
import { PageHeaderInterface } from '../../interfaces/page/PageHeaderInterface'
import { Box, Typography } from '@mui/material'
import { PageHeaderMobileSx } from '../../config/styles/page/PageStyles'

/**
 *
 * @param title
 * @constructor
 */
const PageHeaderMobile = ({ title }: PageHeaderInterface): ReactElement => {
  return (
    <Box sx={PageHeaderMobileSx}>
      <Typography color="white" fontWeight="bold">{title}</Typography>
    </Box>
  )
}

export default PageHeaderMobile
