import React from 'react'

const ArrowDownGradientIcon: React.FC = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="url(#paint0_linear_607_6681)" strokeOpacity="0.7"/>
          <defs>
              <linearGradient id="paint0_linear_607_6681" x1="-3.06122" y1="15" x2="30.7872" y2="15" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#EB1E2E"/>
                  <stop offset="0.546875" stopColor="#9B3288"/>
                  <stop offset="1" stopColor="#5043DA"/>
              </linearGradient>
          </defs>
      </svg>
  )
}

export default ArrowDownGradientIcon
