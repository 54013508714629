import React from 'react'

const GeorgiaCircleIcon: React.FC = () => {
  return (
      <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="35.4667" height="28" rx="4" fill="white"/>
          <path d="M35.3166 12.1739H20.0465L20.0464 0.118508C19.2892 0.0406875 18.5173 0 17.7333 0C16.9494 0 16.1774 0.0406875 15.4203 0.118508V12.1738L0.15011 12.1739C0.0515375 12.7717 0 13.3811 0 14C0 14.619 0.0515375 15.2283 0.15011 15.8261H15.4202L15.4202 27.8815C16.1774 27.9593 16.9494 28 17.7333 28C18.5173 28 19.2892 27.9594 20.0464 27.8815V15.8262L35.3166 15.8261C35.4151 15.2283 35.4667 14.619 35.4667 14C35.4667 13.3811 35.4151 12.7717 35.3166 12.1739Z" fill="#D80027"/>
          <path d="M9.33345 6.53333V4.66663H7.4668V6.53333H5.6001V8.39998H7.4668V10.2666H9.33345V8.39998H11.2001V6.53333H9.33345Z" fill="#D80027"/>
          <path d="M27.9999 6.53333V4.66663H26.1333V6.53333H24.2666V8.39998H26.1333V10.2666H27.9999V8.39998H29.8666V6.53333H27.9999Z" fill="#D80027"/>
          <path d="M9.33345 19.5999V17.7333H7.4668V19.5999H5.6001V21.4666H7.4668V23.3333H9.33345V21.4666H11.2001V19.5999H9.33345Z" fill="#D80027"/>
          <path d="M27.9999 19.5999V17.7333H26.1333V19.5999H24.2666V21.4666H26.1333V23.3333H27.9999V21.4666H29.8666V19.5999H27.9999Z" fill="#D80027"/>
      </svg>
  )
}

export default GeorgiaCircleIcon
