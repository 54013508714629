import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  Grid,
  IconButton
} from '@mui/material'
import { Page } from '../../services/api/page'
import { useTranslation } from 'react-i18next'
import BonusBalanceCircleIcon from '../../components/Icon/BonusBalanceCircleIcon'
import BalanceCircleIcon from '../../components/Icon/BalanceCircleIcon'
import BarBalanceCircleIcon from '../../components/Icon/BarBalanceCircleIcon'
import PromoCodeIcon from '../../components/Icon/PromoCodeIcon'
import ProfileNav from '../../components/Profile/ProfileNav'
import FilterIcon from '../../components/Icon/FilterIcon'
import MyCardFilterModal from '../../components/Modal/MyCardFilterModal'
import useMediaQuery from '@mui/material/useMediaQuery'
import { setBodyBackgroundUrl } from '../../helpers/BodyHelper'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      width={{ xs: '100%', md: 'calc(100% - 240px)' }}
      {...other}
    >
      {value === index && (
        <Box
          paddingY={{ xs: '16px', md: '0' }}
          paddingX={{ xs: '16px', md: '40px' }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

const MyCardView: React.FC = () => {
  const { t } = useTranslation()
  const [filterModal, setFilterModal] = React.useState(false)
  const [value, setValue] = React.useState(1)
  const [customer, setCustomer] = useState<any>({})
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/profile-bg-mobile.jpg'
      : mediaQuerySmall
        ? '/static/profile-bg-small.jpg'
        : '/static/profile-bg.jpg'
  )

  useEffect(() => {
    fetchCustomer()
    return () => {}
  }, [])

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setFilterModal(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOpenFilter = () => {
    setFilterModal(true)
  }

  const fetchCustomer = (): any => {
    Page.getCustomer()
      .then((data) => {
        setCustomer(data?.customer)
        void Page.fetchCustomerPageInfo()
      })
      .catch((err) => {
        alert(err)
      })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onPage = (page: any) => {
    setFilterModal(false)
    setValue(page)
  }

  return (
    <Box
      sx={{
        paddingTop: { xs: '0', md: '102px' },
        paddingBottom: { xs: '50px', md: '0' }
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '74px'
        }}
      >
        <Typography color="white" fontWeight="bold">
          {t('myAccount.title')}
        </Typography>
      </Box>
      <Container maxWidth="xl" sx={{ padding: '0' }}>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            paddingTop: '60px',
            textAlign: 'center'
          }}
        >
          <Typography
            gutterBottom
            sx={{
              display: 'inline-block',
              fontSize: '64px',
              fontWeight: 'bold',
              color: 'white'
            }}
          >
            My Account
          </Typography>
        </Box>

        <Box paddingX={{ xs: '16px', md: '24px' }}>
          <ProfileNav active="card" initialSlide={0} />
        </Box>

        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '12px',
            padding: '0 16px',
            height: '56px'
          }}
        >
          <Typography color="white" fontSize="14px">
            {value === 1 ? <>{t('balances.title')}</> : <>Promo Code</>}
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleOpenFilter}
          >
            <FilterIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            background:
              'linear-gradient(90deg, rgba(249, 147, 230, 0.1) -10.2%, rgba(186, 180, 255, 0.1) 102.62%)',
            display: 'flex',
            minHeight: { xs: 'auto', md: '620px' },
            width: { xs: '100%', md: '970px' },
            marginTop: { xs: '0', md: '60px' },
            marginBottom: { xs: '0', md: '170px' },
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: { xs: '0', md: '50px' }
          }}
        >
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            sx={{
              display: { xs: 'none', md: 'block' },
              borderRight: 1,
              borderColor: 'rgba(255, 219, 222, 0.1)',
              width: '240px'
            }}
          >
            <Tab
              label={t('myCard.title')}
              sx={{
                height: '113px',
                borderBottom: '1px solid rgba(255, 219, 222, 0.1)',
                color: 'white',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            />
            <Tab
              label={t('account.balances')}
              sx={{
                color: 'white',
                fontSize: '14px',
                minHeight: '44px'
              }}
            />
            <Tab
              label="Promo Code"
              sx={{
                color: 'white',
                fontSize: '14px',
                minHeight: '44px',
                display:
                  process.env.REACT_APP_PROMO_CODE === 'true' ? 'flex' : 'none'
              }}
            />
          </Tabs>

          <TabPanel value={value} index={1}>
            <Box
              sx={{
                height: { xs: '60px', md: '113px' },
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center'
              }}
            >
              <Typography color="white" fontSize="14px" fontWeight="bold">
                {t('account.balances')}
              </Typography>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={12} lg={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'flex-start', lg: 'center' },
                    background: 'rgba(40, 41, 55, 0.4)',
                    width: '100%',
                    height: '112px',
                    padding: { xs: '0 0 0 16px', lg: '0' },
                    backdropFilter: 'blur(25px)',
                    borderRadius: '20px'
                  }}
                >
                  <BalanceCircleIcon />

                  <Box sx={{ paddingLeft: '10px' }}>
                    <Typography color="white" fontWeight="bold">
                      {t('headerAccount.yourBalance')}
                    </Typography>
                    <Typography color="white">
                      {customer?.balances?.gel?.amount ??
                        customer?.balances?.gel}
                      ₾
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'flex-start', lg: 'center' },
                    background: 'rgba(40, 41, 55, 0.4)',
                    width: '100%',
                    height: '112px',
                    padding: { xs: '0 0 0 16px', lg: '0' },
                    backdropFilter: 'blur(25px)',
                    borderRadius: '20px'
                  }}
                >
                  <BonusBalanceCircleIcon />

                  <Box sx={{ paddingLeft: '10px' }}>
                    <Typography color="white" fontWeight="bold">
                      {t('headerAccount.bonusBalance')}
                    </Typography>
                    <Typography color="white">
                      {customer?.balances?.point?.amount ??
                        customer?.balances?.point}
                      ₾
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'flex-start', lg: 'center' },
                    background: 'rgba(40, 41, 55, 0.4)',
                    width: '100%',
                    height: '112px',
                    padding: { xs: '0 0 0 16px', lg: '0' },
                    backdropFilter: 'blur(25px)',
                    borderRadius: '20px'
                  }}
                >
                  <BarBalanceCircleIcon />

                  <Box sx={{ paddingLeft: '10px' }}>
                    <Typography color="white" fontWeight="bold">
                      {t('headerAccount.barBalance')}
                    </Typography>
                    <Typography color="white">
                      {customer?.balances?.bar_point?.amount ??
                        customer?.balances?.bar_point}
                      ₾
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Box
              sx={{
                height: '113px',
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center'
              }}
            >
              <Typography color="white" fontSize="14px" fontWeight="bold">
                Promo Code
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                background: { xs: 'transparent', md: 'rgba(40, 41, 55, 0.2)' },
                width: '100%',
                height: '346px',
                padding: { xs: '0', md: '42px' },
                backdropFilter: 'blur(25px)',
                borderRadius: '20px'
              }}
            >
              <PromoCodeIcon />
              <Typography
                color="white"
                fontSize="16px"
                paddingY="30px"
                fontWeight="bold"
              >
                Enter Your Promo Code
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                sx={{
                  color: 'white',
                  border: 'double 1px transparent',
                  backgroundImage:
                    'linear-gradient(90deg, #624257 0%, #5d4059 100.14%), linear-gradient(90deg, #FFA4AB 0%, #E05EC9 100.14%)',
                  backgroundOrigin: 'border-box',
                  backgroundClip: 'padding-box, border-box',
                  height: '48px',
                  borderRadius: '12px'
                }}
              />
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: '48px',
                  borderRadius: '12px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginTop: '16px'
                }}
              >
                Confirm
              </Button>
            </Box>
          </TabPanel>
        </Box>
      </Container>

      <MyCardFilterModal
        isOpen={filterModal}
        showPromoCode={process.env.REACT_APP_PROMO_CODE === 'true'}
        onClose={handleClose}
        onPage={onPage}
      />
    </Box>
  )
}

export default MyCardView
