import React, { useEffect } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import { Page } from '../../services/api/page'
import { useTranslation } from 'react-i18next'

interface ModalProperties {
  isOpen: any
  onClose?: any
  onSent: any
}

const VerifyButtonModal: React.FC<ModalProperties> = ({ isOpen, onSent, onClose }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(isOpen)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSubmit = () => {
    Page.getCustomer()
      .then((data) => {
        Page.resendVerifyCode({
          phone_number: data?.customer?.phone_number,
          id: data?.customer?.id
        })
          .then(async (d) => {
            if (d?.code === 200) {
              onSent()
            }
          })
          .catch((err) => {
            console.log(err)
            // setErrors(err?.response?.data)
          })
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }

  useEffect(() => {
    setOpen(isOpen)
  })

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', md: '50%' },
          bottom: { xs: '0', md: 'initial' },
          left: { xs: '0', md: '50%' },
          transform: { xs: 'initial', md: 'translate(-50%, -50%) scale(0.8)' },
          width: { xs: '100%', md: '735px' },
          backgroundColor: 'rgba(40, 41, 55, 1)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', md: '20px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '65px', md: '98px' },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <Typography
            sx={{ color: 'white', fontWeight: '900', fontSize: { xs: '18px', md: '24px' } }}
          >
            {t('verify.confirmation')}
          </Typography>
        </Box>

        <Box sx={{ padding: { xs: '22px 12px 50px', md: '32px 59px 60px' }, textAlign: 'center' }}>
          <Button
            variant="contained"
            fullWidth
            onClick={onSubmit}
            sx={{
              height: { xs: '58px', md: '74px' },
              marginTop: { xs: '25px', md: '37px' },
              fontWeight: 'bold',
              borderRadius: '20px'
            }}
          >
            {t('verify.sendCode')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default VerifyButtonModal
