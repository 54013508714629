import React from 'react'

const CloseIcon: React.FC = () => {
  return (
      <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.6">
              <path d="M2 20L20 2" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M20 20L2 2" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
      </svg>
  )
}

export default CloseIcon
