import React, { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import {
  PageHeroBoxSx,
  PageHeroIcon,
  PageHeroSx
} from '../../config/styles/page/PageStyles'
import { PageHeroInterface } from '../../interfaces/page/PageHeroInterface'

/**
 *
 * @param title
 * @param icon
 * @param typographySx
 * @constructor
 */
const PageHero = ({ title, icon, typographySx }: PageHeroInterface): ReactElement => {
  return (
    <Box sx={PageHeroSx}>
      <Box display="flex" alignItems="center" sx={PageHeroBoxSx}>
        <PageHeroIcon>{icon}</PageHeroIcon>

        <Typography sx={typographySx}>{title}</Typography>
      </Box>
    </Box>
  )
}

export default PageHero
