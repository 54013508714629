import React, { useEffect, useState } from 'react'
import {
  Container,
  Box,
  CardContent,
  Typography,
  Card
} from '@mui/material'
import { Page } from '../services/api/page'
import { useTranslation } from 'react-i18next'
import SkeletonLoad from '../components/SkeletonLoad/SkeletonLoad'
import HeroCarousel from '../components/Carousel/Hero/HeroCarousel'

const AppView: React.FC = () => {
  const { i18n } = useTranslation()
  const [content, setContent] = useState<any>('')
  const [slider, setSlider] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    Page.getAppPage()
      .then((data) => {
        setContent(
          data?.find((p: { key: string }) => p.key === 'content')?.fields
            ?.description?.value
        )
        setSlider(
          data?.find((p: { key: string }) => p.key === 'slider')?.fields
            ?.images?.value
        )
        console.log(data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])

  return (
    <Box
      sx={{
        backgroundImage: {
          xs: 'url("/static/promotions-bg-xs.jpg")',
          md: 'url("/static/promotions-bg-xs.jpg")',
          lg: 'url("/static/promotions-bg.jpg")'
        },
        minHeight: { xs: '100vh', sm: 'initial' },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        paddingTop: { xs: '0', md: '102px' },
        paddingBottom: { xs: '0', md: '122px' }
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '74px'
        }}
      >
        <Typography color="white" fontWeight="bold">
          App
        </Typography>
      </Box>
      <Container
        maxWidth={false}
        sx={{
          maxWidth: { md: '1192px', lg: '1280px' },
          paddingX: { xs: '16px', md: '40px', lg: 'initial' }
        }}
        disableGutters={true}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: { md: '40px' },
            marginBottom: '0',
            gap: '50px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%' }
            }}
          >
            <Card sx={{ background: 'transparent', width: '100%' }}>
              {loading
                ? (
                <Box
                  sx={{
                    height: { xs: '198px', md: '360px' },
                    borderRadius: '20px',
                    overflow: 'hidden'
                  }}
                >
                  <SkeletonLoad />
                </Box>
                  )
                : (
                  <Box
                    className="app-hero"
                    sx={{
                      position: { xs: 'relative', md: 'relative' },
                      width: { xs: '100%' },
                      height: { xs: 'auto', md: 'auto', lg: '360px' },
                      marginTop: { xs: '9px', md: '0' },
                      padding: { xs: '6px 0 0', md: '0' }
                    }}
                  >
                <HeroCarousel
                  items={slider}
                  name="full_src"
                />
                  </Box>
                  )}
              <CardContent sx={{ p: { xs: '20px 0', md: '36px 0' } }}>
                <Typography
                  variant="body2"
                  color="text.white"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default AppView
