import React from 'react'

interface IconProperties {
  width: number
  height: number
}

const BrownBranchIcon: React.FC<IconProperties> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M3.59727 25.5478C3.42595 25.5332 3.25501 25.4447 3.12231 25.3143C2.97663 25.1713 2.88273 24.9834 2.88273 24.8V14.2C2.88273 14.0143 2.9786 13.7747 3.12701 13.5803C3.27636 13.3848 3.46707 13.25 3.64727 13.25H12.3536V25.35H3.64727H3.59727V25.4V25.5478ZM36.6864 20.4V18.6V14.2C36.6864 12.7715 35.4413 11.55 33.9891 11.55H33.6318V10.4C33.6318 7.97154 31.5722 5.95 29.1018 5.95H27.099C25.8585 2.94552 22.5897 0.95 18.92 0.95C15.2492 0.95 11.9822 2.94621 10.5403 5.75H8.53455C6.06415 5.75 4.00455 7.77154 4.00455 10.2V11.55H3.64727C2.19506 11.55 0.95 12.7715 0.95 14.2V24.6C0.95 26.0285 2.19506 27.25 3.64727 27.25H4.00455V28.6C4.00455 31.0285 6.06415 33.05 8.53455 33.05H29.3055C31.7759 33.05 33.8355 31.0285 33.8355 28.6V27.25H34.1927C34.9144 27.25 35.538 27.0475 35.9818 26.6628C36.4266 26.2774 36.6864 25.713 36.6864 25V20.4ZM18.92 2.85C21.5196 2.85 23.9167 4.01328 25.1427 5.95H12.6973C13.9233 4.01328 16.3204 2.85 18.92 2.85ZM5.93727 10.2C5.93727 8.82846 7.13585 7.65 8.53455 7.65H29.3055C30.7041 7.65 31.9027 8.82846 31.9027 10.2V11.55H5.93727V10.2ZM23.5536 13.45V25.55H14.0827V13.45H23.5536ZM8.53455 31.35C7.13585 31.35 5.93727 30.1715 5.93727 28.8V27.45H31.9027V28.8C31.9027 30.1715 30.7041 31.35 29.3055 31.35H8.53455ZM34.9573 24.8C34.9573 24.9857 34.8614 25.2253 34.713 25.4197C34.5636 25.6152 34.3729 25.75 34.1927 25.75H25.4864V13.45H34.1927C34.5662 13.45 34.9573 13.821 34.9573 14.4V24.8Z"
        fill="url(#paint0_linear_607_27038)"
        stroke="url(#paint1_linear_607_27038)"
        strokeWidth="0.1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_607_27038"
          x1="1.87059"
          y1="7.98592"
          x2="40.6931"
          y2="45.9009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#55BEC0" />
          <stop offset="1" stopColor="#55BEC0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_607_27038"
          x1="1.87059"
          y1="7.98592"
          x2="40.6931"
          y2="45.9009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#55BEC0" />
          <stop offset="1" stopColor="#55BEC0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default BrownBranchIcon
