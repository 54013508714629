import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Snackbar
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import { Page } from '../../services/api/page'
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert'
import { useTranslation } from 'react-i18next'
import ProfileNav from '../../components/Profile/ProfileNav'
import { setBodyBackgroundUrl } from '../../helpers/BodyHelper'
import useMediaQuery from '@mui/material/useMediaQuery'
import SkeletonLoad from '../../components/SkeletonLoad/SkeletonLoad'
import { getPromotionShortDescription, getPromotionTitle, getPromotionTo } from '../../helpers/PromotionsHelper'

const ProfileView: React.FC = () => {
  const { t } = useTranslation()
  const [offerData, setOfferData] = useState({
    offers: []
  })
  const [loading] = useState(false)
  const [skeletLoad, setSkeletLoad] = useState<boolean>(true)
  const [snackbarItem] = useState({
    status: false,
    type: 'success',
    message: ''
  })
  const mediaQuerySmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg')
  )
  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert (
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  const fetchOffers = (): any => {
    Page.getOffers()
      .then((data) => {
        setOfferData(data)
        void Page.fetchCustomerPageInfo()
        setSkeletLoad(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    fetchOffers()
    return () => {}
  }, [])

  // const activateOffer = (offerId: any): any => {
  //   console.log(offerId)
  //   setLoading(true)
  //   Page.activateOffer(offerId)
  //     .then((response) => {
  //       if (response.data !== undefined) {
  //         setSnackbarItem({
  //           status: true,
  //           type: 'success',
  //           message: response.message
  //         })
  //         fetchOffers()
  //       } else {
  //         setSnackbarItem({
  //           status: true,
  //           type: 'error',
  //           message: response.message
  //         })
  //       }
  //       setLoading(false)
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //       setSnackbarItem({
  //         status: true,
  //         type: 'error',
  //         message: err.message
  //       })
  //     })
  // }

  setBodyBackgroundUrl(
    mediaQueryMobile
      ? '/static/profile-bg-mobile.jpg'
      : mediaQuerySmall
        ? '/static/profile-bg-small.jpg'
        : '/static/profile-bg.jpg'
  )

  return (
    <Box
      sx={{
        paddingTop: { xs: '0', md: '60px' },
        paddingBottom: { xs: '50px', md: '0' }
      }}
    >
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '74px'
        }}
      >
        <Typography color="white" fontWeight="bold">
          My Account
        </Typography>
      </Box>
      <Container
        maxWidth={false}
        sx={{ maxWidth: { md: '1192px', lg: '1280px' } }}
        disableGutters={true}
      >
        <Snackbar open={snackbarItem.status} autoHideDuration={6000}>
          <Alert
            severity={
              snackbarItem.type !== ''
                ? (snackbarItem.type.toString() as AlertColor)
                : 'success'
            }
            sx={{ width: '100%' }}
          >
            {snackbarItem?.message}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            paddingTop: '60px',
            textAlign: 'center'
          }}
        >
          <Typography
            gutterBottom
            sx={{
              display: 'inline-block',
              fontSize: '48px',
              fontWeight: 'bold',
              color: 'white'
            }}
          >
            {t('account.myAccountTitle')}
          </Typography>
        </Box>

        <ProfileNav active="offers" initialSlide={2} />

        <Grid
          container
          spacing={3}
          sx={{
            marginTop: '40px',
            marginBottom: { xs: '0', md: '120px' },
            padding: { xs: '0 16px', md: '0' }
          }}
        >
          {offerData.offers.map((item: any) => (
            <Grid item xs={12} md={4} key={item?.id}>
              <NavLink to={getPromotionTo(item)}>
                <Card
                  sx={{
                    background: 'rgba(23, 24, 34, 0.3)',
                    height: { xs: 'auto', md: '450px' },
                    p: '20px',
                    borderRadius: '40px'
                  }}
                >
                  {skeletLoad
                    ? <Box
                  sx={{
                    borderRadius: '40px',
                    height: { xs: '180px', sm: '240px' },
                    overflow: 'hidden'
                  }}
                  >
                    <SkeletonLoad/>
                    </Box>
                    : <CardMedia
                     component="img"
                     image={item.image_url}
                     alt="#"
                     sx={{
                       borderRadius: '40px',
                       height: { xs: 'auto', md: '240px' },
                       backgroundColor: 'rgba(40, 41, 55, 0.7) !important'
                     }}
                   />}
                  <CardContent sx={{ p: '18px 0 0' }}>
                    <Typography
                      component="div"
                      sx={{
                        backgroundImage:
                          'linear-gradient(131.25deg, #FFD99C 11.5%, #DE753D 124.9%)',
                        backgroundClip: 'text',
                        color: 'transparent',
                        fontSize: { xs: '18px', md: '20px' },
                        fontWeight: 'bold'
                      }}
                    >
                        {getPromotionTitle(item)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.white"
                      dangerouslySetInnerHTML={{
                        __html: getPromotionShortDescription(item)
                      }}
                      sx={{
                        marginTop: '-10px',
                        height: { md: '54px' },
                        overflow: { md: 'hidden' }
                      }}
                    />
                    <Button
                      disabled={loading}
                      variant="outlined"
                      color="warning"
                      sx={{
                        width: '100%',
                        height: '54px',
                        marginTop: '20px',
                        px: '60px',
                        fontWeight: '600',
                        borderRadius: '50px'
                      }}
                    >
                      {t('account.offer.seeMore')}
                    </Button>
                  </CardContent>
                </Card>
              </NavLink>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default ProfileView
