import React from 'react'

interface IconProperties {
  width: number
  height: number
}

const ArrowDownIcon: React.FC<IconProperties> = ({ width, height }) => {
  return (
      <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.9196 8.95001L13.3996 15.47C12.6296 16.24 11.3696 16.24 10.5996 15.47L4.07959 8.95001" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}

export default ArrowDownIcon
