import React, { useEffect } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import GeorgiaBoxIcon from '../Icon/GeorgiaBoxIcon'
import EnglishBoxIcon from '../Icon/EnglishBoxIcon'
import CheckedIcon from '../Icon/CheckedIcon'
import { useTranslation } from 'react-i18next'

interface ModalProperties {
  isOpen: any
  onClose: any
}

const MyCardFilterModal: React.FC<ModalProperties> = ({ isOpen, onClose }) => {
  const [open, setOpen] = React.useState(isOpen)
  const { i18n, t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setOpen(false)
    onClose(false)
  }

  const defaultStyle = {
    background: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    justifyContent: 'space-between',
    height: '54px',
    fontSize: '15px',
    padding: '0 16px',
    gap: '12px',
    borderRadius: '10px'
  }

  const activeStyle = {
    justifyContent: 'space-between',
    height: '54px',
    fontSize: '15px',
    padding: '0 16px',
    gap: '12px',
    borderRadius: '10px'
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSetLanguage = () => {
    void i18n.changeLanguage(i18n.language === 'en' ? 'ka' : 'en')
  }

  useEffect(() => {
    setOpen(isOpen)
  })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', lg: '50%' },
          bottom: { xs: '0', lg: 'initial' },
          left: { xs: '0', lg: '50%' },
          transform: { xs: 'initial', lg: 'translate(-50%, -50%)' },
          width: { xs: '100%', lg: '735px' },
          backgroundColor: 'rgba(40, 41, 55, 0.7)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          backdropFilter: 'blur(25px)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', lg: '20px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '62px', lg: '97px' },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: '900',
              fontSize: { xs: '18px', lg: '24px' }
            }}
          >
            {t('language.modal.title')}
          </Typography>
        </Box>

        <Box sx={{ padding: { xs: '20px 16px' } }}>
          <Button variant="outlined" fullWidth sx={i18n.language === 'en' ? defaultStyle : activeStyle} onClick={handleSetLanguage}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '12px'
              }}
            >
              <GeorgiaBoxIcon />
              {t('language.modal.ka')}
            </Box>
              {i18n.language === 'ka' && (<CheckedIcon />)}
          </Button>

          <Button
            variant="outlined"
            fullWidth
            sx={i18n.language === 'en' ? { ...activeStyle, marginTop: '14px' } : { ...defaultStyle, marginTop: '14px' }}
            onClick={handleSetLanguage}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '12px'
              }}
            >
              <EnglishBoxIcon />
              {t('language.modal.en')}
            </Box>
              {i18n.language === 'en' && (<CheckedIcon />)}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default MyCardFilterModal
