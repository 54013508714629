import React, { useEffect } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import OtpInput from 'react-otp-input'
import { useTimer } from 'use-timer'
import { Page } from '../../services/api/page'
import { sizePro } from '../../helpers/PageHelper'
import { useTranslation } from 'react-i18next'

interface ModalProperties {
  isOpen: any
  onClose: any
}

const SignInModal: React.FC<ModalProperties> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(isOpen)
  const [otp, setOtp] = React.useState('')
  const [errors, setErrors] = React.useState({
    errors: {
      code: ''
    },
    message: ''
  })
  const [showResend, setShowResend] = React.useState(false)
  const { start, time } = useTimer({
    autostart: true,
    initialTime: 120,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: () => {
      setShowResend(true)
    }
  })

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChange = (otp: any) => setOtp(otp)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleResend = () => {
    start()
    setShowResend(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSubmit = () => {
    setErrors({
      errors: {
        code: ''
      },
      message: ''
    })

    Page.getCustomer()
      .then((data) => {
        Page.verifyCode({
          phone_number: data?.customer?.phone_number,
          id: data?.customer?.id,
          code: otp
        })
          .then(async (d) => {
            if (d?.data?.access_token?.length > 0) {
              onClose()
            }
          })
          .catch((err) => {
            console.log(err)
            setErrors(err?.response?.data)
          })
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }

  useEffect(() => {
    setOpen(isOpen)
  })

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', md: '50%' },
          bottom: { xs: '0', md: 'initial' },
          left: { xs: '0', md: '50%' },
          transform: { xs: 'initial', md: 'translate(-50%, -50%) scale(0.8)' },
          width: { xs: '100%', md: '735px' },
          backgroundColor: 'rgba(40, 41, 55, 1)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', md: '20px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '65px', md: '98px' },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <Typography
            sx={{ color: 'white', fontWeight: '900', fontSize: { xs: '18px', md: '24px' } }}
          >
            {t('verify.confirmation')}
          </Typography>
        </Box>

        <Box sx={{ padding: { xs: '22px 12px 50px', md: '32px 59px 60px' }, textAlign: 'center' }}>
          {((errors?.message) !== '') && (
            <Typography
              sx={{
                color: 'rgba(235, 30, 46, 1)',
                paddingBottom: sizePro(24),
                fontSize: sizePro(20),
                fontWeight: 'bold'
              }}
            >
              {errors?.message}
            </Typography>
          )}

            <Typography
                gutterBottom
                sx={{
                  fontSize: { xs: '16px', md: '20px' },
                  fontWeight: 'bold',
                  textAlign: 'center',
                  backgroundImage:
                        'linear-gradient(90deg, #FFDBDE -34.13%, #BAB4FF 124.6%)',
                  backgroundClip: 'text',
                  color: 'transparent',
                  marginBottom: '8px'
                }}
            >
              {t('verify.sentSuccess')}
            </Typography>

            <Typography
                gutterBottom
                sx={{
                  fontSize: { xs: '14px', md: '16px' },
                  color: 'white',
                  textAlign: 'center'
                }}
            >
              {t('verify.text')}
            </Typography>

            <Typography
                gutterBottom
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  color: 'white',
                  textAlign: 'center',
                  paddingTop: '37px',
                  paddingBottom: { xs: '9px', md: '19px' }
                }}
            >
              {t('verify.phoneCode')}
            </Typography>

            <OtpInput
                value={otp}
                containerStyle="otp"
                inputStyle="otp-input"
                onChange={handleChange}
            />

          {((errors?.errors?.code) !== '') && (
            <Typography
              sx={{
                color: 'rgba(235, 30, 46, 1)',
                paddingY: sizePro(24),
                fontSize: sizePro(20),
                fontWeight: 'bold'
              }}
            >
              {errors?.errors?.code}
            </Typography>
          )}

            <Typography
                gutterBottom
                sx={{
                  fontSize: { xs: '12px', md: '16px' },
                  color: 'white',
                  textAlign: 'center',
                  paddingTop: { xs: '26px', md: '56px' }
                }}
            >
              {t('verify.resendText')} {!showResend ? (`${time} ${t('verify.seconds')}`) : t('verify.confirmation')}
            </Typography>

            {showResend && (
                <Button
                    color="secondary"
                    sx={{
                      borderRadius: '20px',
                      margin: '18px auto'
                    }}
                    onClick={handleResend}
                >
                    <Typography
                        sx={{
                          fontSize: { xs: '14px', md: '16px' },
                          fontWeight: 'bold',
                          backgroundImage: `
                                            linear-gradient(90deg, #FFDBDE -10.2%, #F993E6 51.5%, #BAB4FF 102.62%),
                                            linear-gradient(0deg, #FFFFFF, #FFFFFF)
                                          `,
                          backgroundClip: 'text',
                          color: 'transparent',
                          borderBottom: '1px solid #fcc1e1',
                          marginTop: '-16px',
                          height: { xs: '19px', md: '21px' }
                        }}
                    >
                      {t('verify.resend')}
                    </Typography>
                </Button>
            )}

          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            sx={{
              height: { xs: '58px', md: '74px' },
              marginTop: { xs: '25px', md: '37px' },
              fontWeight: 'bold',
              borderRadius: '20px'
            }}
          >
            {t('verify.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default SignInModal
