import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { Page } from '../services/api/page'
import { CareerPageSx } from '../config/styles/career/CareerStyles'
import PageHeaderMobile from '../components/Page/PageHeaderMobile'
// import PageHeader from '../components/Page/PageHeader'
import { setBodyBackgroundUrl } from '../helpers/BodyHelper'
import FileIcon from '../components/Icon/FileIcon'
import { styled } from '@mui/material/styles'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useTranslation } from 'react-i18next'
import ArrowDownGradientIcon from '../components/Icon/ArrowDownGradientIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
// import CareerCard from '../components/Career/CareerCard'
// import { getCareerId, getCareerTitle } from '../helpers/CareerHelper'
// import CareerCardSkeleton from '../components/Career/CareerCardSkeleton'

interface State {
  career_id: string
  email: string
  full_name: string
  subject: string
  birth_date: string
  content: string
  phone: string
  files?: string[]
}

const CssTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-multiline': {
    height: 'auto !important'
  },

  '& label': {
    fontSize: '15px',
    padding: '0 6px',
    transform: 'translate(23px, 21px) scale(1)',

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      transform: 'translate(18px, 20px) scale(1)'
    }
  },
  '& label.Mui-focused': {
    fontSize: '15px',
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& label.MuiFormLabel-filled': {
    fontSize: '15px',
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 0%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& .MuiOutlinedInput-root': {
    border: 'double 1px transparent',
    backgroundImage:
      'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '16.5px 14px',
    height: '64px',
    borderRadius: '20px',

    [theme.breakpoints.down('lg')]: {
      height: '58px',
      fontSize: '12px',
      padding: '0 10px'
    },

    '& .MuiInputBase-input': {
      fontWeight: 'bold'
    }
  }
}))

const CssSelectControl = styled(FormControl)(({ theme }) => ({
  '&': {
    border: 'double 1px transparent',
    backgroundImage:
      'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '0 14px',
    height: '64px',
    borderRadius: '20px',

    [theme.breakpoints.down('lg')]: {
      height: '58px'
    }
  },

  '& label': {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.6)',
    padding: '0 6px',
    transform: 'translate(23px, 22px) scale(1)',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      transform: 'translate(20px, 19px) scale(1)'
    }
  },

  '& label.Mui-error': {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.6)',
    padding: '0 6px',
    transform: { xs: 'translate(24px, 18px) scale(1)' },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& label.Mui-focused': {
    fontSize: '15px',
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& label.MuiFormLabel-filled': {
    fontSize: '15px',
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '& .MuiInputBase-root': {
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker label': {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.6)',
    padding: '0 6px',
    transform: 'translate(10px, 22px) scale(1)',

    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      transform: 'translate(6px, 18px) scale(1)'
    }
  },

  '&.datepicker label.Mui-error': {
    fontSize: '15px',
    color: 'rgba(255, 255, 255, 0.6)',
    padding: '0 6px',
    transform: { xs: 'translate(10px, 26px) scale(1)' },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      transform: 'translate(6px, 18px) scale(1)'
    }
  },

  '&.datepicker label.Mui-focused': {
    fontSize: '15px',
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(10px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker label.MuiFormLabel-filled': {
    fontSize: '15px',
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(10px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px'
    }
  },

  '&.datepicker .MuiInputBase-input': {
    padding: '0 14px'
  }
}))

const CareerView: React.FC = () => {
  const [page, setPage] = useState<any>([])
  // const [careerModal, setCareerModal] = React.useState(false)
  // const [item, setItem] = React.useState({})
  const { t, i18n } = useTranslation()
  const [errors, setErrors] = React.useState<State>()
  const [values, setValues] = React.useState<State>({
    career_id: '',
    email: '',
    full_name: '',
    subject: '',
    birth_date: '',
    content: '',
    phone: ''
  })
  const [file, setFile] = React.useState<any[]>([])
  const [fileName, setFileName] = React.useState('')
  const [success, setSuccess] = React.useState(false)

  const mediaQueryMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  setBodyBackgroundUrl(
    mediaQueryMobile ? '/static/career-bg-mobile.jpg' : '/static/promotions-bg.jpg'
  )

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onFileChange = async (file: any) => {
    await new Promise((resolve) => {
      const reader = new FileReader()

      reader.readAsDataURL(file?.target?.files?.[0])
      reader.onload = () => {
        setFile([
          {
            file: reader.result
          }
        ])
        setFileName(file?.target?.files?.[0]?.name)
        resolve(reader.result)
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleApplyCareer = () => {
    Page.applyCareer({
      ...values,
      files: {
        ...file
      }
    })
      .then((data) => {
        setValues({
          career_id: '',
          email: '',
          full_name: '',
          subject: '',
          birth_date: '',
          content: '',
          phone: ''
        })
        setFile([])
        setFileName('')
        setSuccess(true)
      })
      .catch((err) => {
        setErrors(err?.response?.data?.errors)
        console.log(errors)
      })
    return () => {}
  }

  useEffect(() => {
    Page.getCareerPage()
      .then((data) => {
        setPage(data)
      })
      .catch((err) => {
        console.log(err)
      })
    return () => {}
  }, [i18n.language])

  return (
    <Box sx={CareerPageSx}>
      <PageHeaderMobile title="Career" />
      <Container
        maxWidth={false}
        sx={{
          maxWidth: { md: '1192px', lg: '1280px' },
          paddingX: { xs: '16px', md: '40px', lg: 'initial' }
        }}
        disableGutters={true}
      >
        <Box
          sx={{
            display: { xs: 'block', md: 'flex' },
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: '820px' },
              paddingRight: { md: '30px', lg: '0' }
            }}
          >
            <Typography
              color="white"
              sx={{ margin: 0 }}
              dangerouslySetInnerHTML={{ __html: page?.content }}
            />
          </Box>

          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              width: { xs: '100%', md: '430px' },
              alignItems: 'flex-start'
            }}
          >
            <Box
              sx={{
                width: { xs: '100%' },
                backgroundColor: 'rgba(40, 41, 55, 0.7)',
                boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
                backdropFilter: 'blur(25px)',
                border: 'none',
                outline: 'none',
                borderRadius: { xs: '20px 20px 0 0', md: '20px' },
                marginTop: { xs: '40px', md: '0' }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: { xs: '62px', md: '72px' },
                  borderBottom: '1px solid #FFA4AB'
                }}
              >
                <Typography
                  sx={{
                    color: 'white',
                    fontWeight: '900',
                    fontSize: { xs: '18px' }
                  }}
                >
                  {t('job.form.heading')}
                </Typography>
              </Box>

              <Box sx={{ padding: { xs: '20px' }, height: '100%' }}>
                {success
                  ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: '15px'
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#00FDC0',
                        paddingBottom: '20px',
                        fontSize: '13px',
                        fontWeight: 'bold'
                      }}
                    >
                      {t('job.success')}
                    </Typography>
                  </Box>
                    )
                  : (
                  <>
                    <CssSelectControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t('job.type')}
                      </InputLabel>
                      <Select
                        value={values.career_id}
                        autoWidth
                        IconComponent={ArrowDownGradientIcon}
                        id="careerId"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            career_id: e.target.value,
                            subject: page?.items?.find(
                              (v: any) => v.id === e.target.value
                            )?.title
                          })
                        }}
                      >
                        {page?.items?.map((item: any) => (
                          <MenuItem value={item.id} key={item.id} sx={{
                            whiteSpace: 'pre-wrap'
                          }}>
                            {item?.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </CssSelectControl>

                    <Typography
                      sx={{
                        color: 'rgba(235, 30, 46, 1)',
                        paddingY: '10px',
                        fontSize: '13px',
                        fontWeight: 'bold'
                      }}
                    >
                      {errors?.career_id}
                    </Typography>

                    <CssTextField
                      label={t('job.fullName')}
                      value={values.full_name}
                      onChange={handleChange('full_name')}
                      fullWidth
                      id="custom-css-outlined-input"
                    />

                    <Typography
                      sx={{
                        color: 'rgba(235, 30, 46, 1)',
                        paddingY: '10px',
                        fontSize: '13px',
                        fontWeight: 'bold'
                      }}
                    >
                      {errors?.full_name}
                    </Typography>

                    <CssSelectControl className="datepicker" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label={t('job.birthDate')}
                          value={values.birth_date}
                          inputFormat="DD/MM/YYYY"
                          disableOpenPicker={true}
                          onChange={(newValue: any) => {
                            setValues({ ...values, birth_date: newValue })
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              sx={{ height: { xs: '58px', md: '64px' } }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </CssSelectControl>

                    <Typography
                      sx={{
                        color: 'rgba(235, 30, 46, 1)',
                        paddingY: '10px',
                        fontSize: '13px',
                        fontWeight: 'bold'
                      }}
                    >
                      {errors?.birth_date}
                    </Typography>

                    <CssTextField
                      label={t('job.address')}
                      value={values.content}
                      onChange={handleChange('content')}
                      fullWidth
                      id="custom-css-outlined-input"
                    />

                    <Typography
                      sx={{
                        color: 'rgba(235, 30, 46, 1)',
                        paddingY: '10px',
                        fontSize: '13px',
                        fontWeight: 'bold'
                      }}
                    >
                      {errors?.content}
                    </Typography>

                    <CssTextField
                      label={t('job.email')}
                      value={values.email}
                      onChange={handleChange('email')}
                      fullWidth
                      id="custom-css-outlined-input"
                    />

                    <Typography
                      sx={{
                        color: 'rgba(235, 30, 46, 1)',
                        paddingY: '10px',
                        fontSize: '13px',
                        fontWeight: 'bold'
                      }}
                    >
                      {errors?.email}
                    </Typography>

                    <CssTextField
                      label={t('job.phone')}
                      value={values.phone}
                      onChange={handleChange('phone')}
                      fullWidth
                      id="custom-css-outlined-input"
                    />

                    <Typography
                      sx={{
                        color: 'rgba(235, 30, 46, 1)',
                        paddingY: '10px',
                        fontSize: '13px',
                        fontWeight: 'bold'
                      }}
                    >
                      {errors?.phone}
                    </Typography>

                    <Button
                      variant="contained"
                      component="label"
                      fullWidth
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        background:
                          'linear-gradient(90deg, rgba(255, 164, 171, 0.1) -10.2%, rgba(224, 94, 201, 0.1) 51.5%, rgba(158, 149, 255, 0.1) 102.62%)',
                        border: '1px dashed #FFA4AB',
                        height: '129px',
                        marginTop: '10px',
                        fontWeight: 'bold',
                        borderRadius: '20px'
                      }}
                    >
                      <FileIcon />
                      {fileName !== '' ? fileName : t('job.upload')}
                      <input
                        hidden
                        accept=".pdf"
                        type="file"
                        id="fileInput"
                        onChange={onFileChange}
                      />
                    </Button>

                    <Typography
                      sx={{
                        color: 'rgba(235, 30, 46, 1)',
                        paddingY: '10px',
                        fontSize: '13px',
                        fontWeight: 'bold'
                      }}
                    >
                      {errors?.files}
                    </Typography>

                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        height: '74px',
                        marginTop: '16px',
                        fontWeight: 'bold',
                        borderRadius: '20px'
                      }}
                      onClick={handleApplyCareer}
                    >
                      {t('job.send')}
                    </Button>
                  </>
                    )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default CareerView
