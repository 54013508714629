import React from 'react'

const LinkedinIcon: React.FC = () => {
  return (
      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.30159 0.326646C0.926048 0.5019 0.757054 0.677154 0.588059 1.0527C0.350215 1.5722 0.444101 2.13551 0.844681 2.58617C1.49562 3.32473 2.70988 3.18078 3.16053 2.31077C3.486 1.69112 3.36082 0.983847 2.84132 0.545713C2.39067 0.151393 1.83361 0.0762842 1.30159 0.326646Z" fill="#282937"/>
          <path d="M0.456055 7.81195V11.8177H1.89564H3.33522V7.81195V3.80616H1.89564H0.456055V7.81195Z" fill="#282937"/>
          <path d="M4.83789 7.81195V11.8177H6.27121H7.7108L7.72957 9.42053C7.74835 7.15475 7.75461 7.01705 7.87979 6.74791C8.21152 6.02812 9.10031 5.75272 9.5447 6.23467C9.88269 6.59144 9.87643 6.54136 9.89521 9.26405L9.91399 11.8177H11.3849H12.8495V9.63334C12.8495 7.27993 12.7932 6.41618 12.5929 5.75898C12.33 4.90149 11.7854 4.25055 11.1032 3.98141C10.8215 3.86875 10.6338 3.84371 10.1268 3.84997C8.96887 3.85623 8.25534 4.1817 7.79842 4.90775C7.73583 5.0079 7.72332 4.91401 7.72332 4.41328L7.71706 3.80616H6.27747H4.83789V7.81195Z" fill="#282937"/>
      </svg>

  )
}

export default LinkedinIcon
