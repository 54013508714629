import React from 'react'
import ReactDOM from 'react-dom/client'
import './i18n/config'
import { Provider as BusProvider } from 'react-bus'
import Default from './layouts/Default'
import { BrowserRouter as Router } from 'react-router-dom'
import { SkeletonTheme } from 'react-loading-skeleton'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
    <BusProvider>
        <Router>
            <SkeletonTheme baseColor="rgba(23, 24, 34, 0.8)" highlightColor="rgba(23, 24, 34, 1)">
                <Default />
            </SkeletonTheme>
        </Router>
    </BusProvider>
)
