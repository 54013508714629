import React, { useEffect } from 'react'
import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { styled } from '@mui/material/styles'
import FileIcon from '../Icon/FileIcon'
// import useMediaQuery from '@mui/material/useMediaQuery'

interface ModalProperties {
  isOpen: any
  onClose: any
  item: any
}

const CssTextField = styled(TextField)(({ theme }) => ({
  '&': {
    marginTop: '10px'
  },

  '& .MuiInputBase-multiline': {
    height: 'auto !important'
  },

  '& label': {
    fontSize: '15px',
    padding: '0 6px',
    transform: 'translate(23px, 21px) scale(1)',

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      transform: 'translate(18px, 20px) scale(1)'
    }
  },
  '& label.Mui-focused': {
    fontSize: '15px',
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 40%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& label.MuiFormLabel-filled': {
    fontSize: '15px',
    background:
      'linear-gradient(360deg, rgba(40,41,55,1) 0%, rgba(251,251,251,0) 100%)',
    color: 'rgba(255, 255, 255, 0.6)',
    transform: 'translate(24px, -9px) scale(0.75)',
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  '& .MuiOutlinedInput-root': {
    border: 'double 1px transparent',
    backgroundImage:
        'linear-gradient(90deg, rgba(40, 41, 55, 1) 0%, rgba(40, 41, 55, 1) 100.14%), linear-gradient(90deg, rgba(235, 30, 46, 0.5) 0%, rgba(80, 67, 218, 0.5) 100.14%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
    padding: '16.5px 14px',
    height: '64px',
    borderRadius: '20px',

    [theme.breakpoints.down('lg')]: {
      height: '58px',
      fontSize: '12px',
      padding: '0 10px'
    },

    '& .MuiInputBase-input': {
      fontWeight: 'bold'
    }
  }
}))

interface State {
  career_id: string
  content: string
  email: string
  files: string[]
  full_name: string
  subject: string
}

const hasWindow = typeof window !== 'undefined'
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getWindowDimensions = () => {
  const width = hasWindow ? window.innerWidth : 0
  const height = hasWindow ? window.innerHeight : 0
  return {
    width,
    height
  }
}

const MyCardFilterModal: React.FC<ModalProperties> = ({ isOpen, item, onClose }) => {
  const [open, setOpen] = React.useState(isOpen)
  const [isForm, setIsForm] = React.useState(false)
  const [errors] = React.useState<State>()
  const [values, setValues] = React.useState<State>({
    career_id: '',
    content: '',
    email: '',
    files: [],
    full_name: '',
    subject: ''
  })
  // const matches = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setOpen(false)
    onClose(false)
  }

  useEffect(() => {
    setOpen(isOpen)
  })

  useEffect(() => {
    if (hasWindow) {
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="career-modal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', md: '50%' },
          bottom: { xs: '0', md: 'initial' },
          left: { xs: '0', md: '50%' },
          transform: { xs: 'initial', md: 'translate(-50%, -50%) scale(0.85)' },
          width: { xs: '100%', md: '735px' },
          backgroundColor: 'rgba(40, 41, 55, 1)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', md: '20px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '62px', md: '97px' },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: '900',
              fontSize: { xs: '18px', md: '24px' }
            }}
          >
            {!isForm ? item?.title : 'Send CV'}
          </Typography>
        </Box>

        <Box sx={{ padding: { xs: '40px 16px 20px', md: '40px' } }}>
          {!isForm
            ? (
            <>
              <SimpleBar style={{ maxHeight: windowDimensions?.height < 810 ? '240px' : '480px' }} autoHide={false}>
                <Typography color="white" fontSize="16px" paddingRight="46px" dangerouslySetInnerHTML={{ __html: item?.content }} />
              </SimpleBar>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: '74px',
                  marginTop: '40px',
                  fontWeight: 'bold',
                  borderRadius: '20px'
                }}
                onClick={() => {
                  setIsForm(true)
                }}
              >
                Send CV
              </Button>
            </>
              )
            : (
            <>
              <SimpleBar style={{ maxHeight: windowDimensions?.height < 810 ? '400px' : '100%' }} autoHide={false}>
                <CssTextField
                    label="Full Name"
                    value={values.full_name}
                    onChange={handleChange('full_name')}
                    fullWidth
                    id="custom-css-outlined-input"
                />
                <Typography
                    sx={{
                      color: 'rgba(235, 30, 46, 1)',
                      paddingTop: '10px',
                      fontSize: '13px',
                      fontWeight: 'bold'
                    }}
                >
                  {errors?.full_name}
                </Typography>

                <CssTextField
                    label="Email"
                    value={values.email}
                    onChange={handleChange('email')}
                    fullWidth
                    id="custom-css-outlined-input"
                />
                <Typography
                    sx={{
                      color: 'rgba(235, 30, 46, 1)',
                      paddingTop: '10px',
                      fontSize: '13px',
                      fontWeight: 'bold'
                    }}
                >
                  {errors?.email}
                </Typography>

                <CssTextField
                    label="Subject"
                    value={values.subject}
                    onChange={handleChange('subject')}
                    fullWidth
                    id="custom-css-outlined-input"
                />
                <Typography
                    sx={{
                      color: 'rgba(235, 30, 46, 1)',
                      paddingTop: '10px',
                      fontSize: '13px',
                      fontWeight: 'bold'
                    }}
                >
                  {errors?.subject}
                </Typography>

                <CssTextField
                    label="Description"
                    value={values.content}
                    onChange={handleChange('content')}
                    fullWidth
                    multiline
                    rows={4}
                    id="custom-css-outlined-input"
                />
                <Typography
                    sx={{
                      color: 'rgba(235, 30, 46, 1)',
                      paddingTop: '10px',
                      fontSize: '13px',
                      fontWeight: 'bold'
                    }}
                >
                  {errors?.content}
                </Typography>

                <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      background:
                          'linear-gradient(90deg, rgba(255, 164, 171, 0.1) -10.2%, rgba(224, 94, 201, 0.1) 51.5%, rgba(158, 149, 255, 0.1) 102.62%)',
                      border: '1px dashed #FFA4AB',
                      height: '129px',
                      marginTop: '10px',
                      fontWeight: 'bold',
                      borderRadius: '20px'
                    }}
                >
                  <FileIcon />
                  Upload CV
                  <input hidden accept=".pdf" multiple type="file" />
                </Button>

                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      height: '74px',
                      marginTop: '40px',
                      fontWeight: 'bold',
                      borderRadius: '20px'
                    }}
                >
                  Send CV
                </Button>
              </SimpleBar>
            </>
              )}
        </Box>
      </Box>
    </Modal>
  )
}

export default MyCardFilterModal
