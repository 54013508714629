import React, { useEffect } from 'react'
import { Box, Modal, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ModalProperties {
  isOpen: any
  onClose: any
  onPage: any
  showPromoCode: boolean
}

const MyCardFilterModal: React.FC<ModalProperties> = ({ isOpen, showPromoCode, onClose, onPage }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(isOpen)
  const [value, setValue] = React.useState(1)

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setOpen(false)
    setValue(1)
    onClose(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onPage(newValue)
    setValue(newValue)
  }

  useEffect(() => {
    setOpen(isOpen)
  })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 'initial', lg: '50%' },
          bottom: { xs: '0', lg: 'initial' },
          left: { xs: '0', lg: '50%' },
          transform: { xs: 'initial', lg: 'translate(-50%, -50%)' },
          width: { xs: '100%', lg: '735px' },
          backgroundColor: 'rgba(40, 41, 55, 0.7)',
          boxShadow: '0px 38px 71px rgba(0, 0, 0, 0.25)',
          backdropFilter: 'blur(25px)',
          border: 'none',
          outline: 'none',
          borderRadius: { xs: '20px 20px 0 0', lg: '20px' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: { xs: '62px', lg: '97px' },
            borderBottom: '1px solid #FFA4AB'
          }}
        >
          <Typography
            sx={{ color: 'white', fontWeight: '900', fontSize: { xs: '18px', lg: '24px' } }}
          >
            {t('myCard.title')}
          </Typography>
        </Box>

        <Box sx={{ padding: { xs: '20px 0' } }}>
            <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                sx={{
                  borderRight: 0,
                  width: '100%'
                }}
            >
                <Tab
                    label={t('myCard.title')}
                    sx={{
                      display: 'none'
                    }}
                />
                <Tab
                    label={t('balances.title')}
                    sx={{
                      color: 'white',
                      fontSize: '14px',
                      width: '100%',
                      maxWidth: '100%',
                      minHeight: '54px',
                      fontWeight: 'bold'
                    }}
                />
                <Tab
                    label="Promo Code"
                    sx={{
                      color: 'white',
                      fontSize: '14px',
                      width: '100%',
                      maxWidth: '100%',
                      minHeight: '54px',
                      fontWeight: 'bold',
                      display: showPromoCode ? 'flex' : 'none'
                    }}
                />
            </Tabs>
        </Box>
      </Box>
    </Modal>
  )
}

export default MyCardFilterModal
