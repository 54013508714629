import React from 'react'

const GeorgiaCircleIcon: React.FC = () => {
  return (
      <svg width="37" height="28" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" width="35.4667" height="28" rx="4" fill="white"/>
          <path d="M36.391 12.1738L20.7328 12.1739L20.7327 0.118508C20.066 0.0406875 19.3864 0 18.6962 0C18.006 0 17.3263 0.0406875 16.6597 0.118508V12.1738L1.002 12.1333C0.915214 12.7311 1.00152 13.381 1.00152 14C1.00152 14.619 0.914709 15.2689 1.0015 15.8667L16.6596 15.8261L16.6597 27.8815C17.3263 27.9593 18.006 28 18.6962 28C19.3864 28 20.066 27.9594 20.7327 27.8815V15.8262L36.391 15.8667C36.4778 15.2689 36.391 14.619 36.391 14C36.391 13.381 36.4778 12.7716 36.391 12.1738Z" fill="#D80027"/>
      </svg>

  )
}

export default GeorgiaCircleIcon
