import React from 'react'

interface IconProperties {
  width: number
  height: number
}

const PrivacyHeadingIcon: React.FC<IconProperties> = ({ width, height }) => {
  return (
      <svg width={width} height={height} viewBox="0 0 399 399" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M200 366.667C291.667 366.667 366.667 291.667 366.667 200C366.667 108.333 291.667 33.3334 200 33.3334C108.333 33.3334 33.3334 108.333 33.3334 200C33.3334 291.667 108.333 366.667 200 366.667Z" stroke="url(#paint0_linear_1079_5540)" strokeWidth="20" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M200 133.333V216.667" stroke="url(#paint1_linear_1079_5540)" strokeWidth="20" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M199.908 266.667H200.058" stroke="url(#paint2_linear_1079_5540)" strokeWidth="20" strokeLinecap="round" strokeLinejoin="round"/>
          <defs>
              <linearGradient id="paint0_linear_1079_5540" x1="148.442" y1="421.374" x2="148.441" y2="24.4275" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0193FD"/>
                  <stop offset="1" stopColor="#00FDC0"/>
              </linearGradient>
              <linearGradient id="paint1_linear_1079_5540" x1="200.345" y1="230.344" x2="200.345" y2="131.107" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0193FD"/>
                  <stop offset="1" stopColor="#00FDC0"/>
              </linearGradient>
              <linearGradient id="paint2_linear_1079_5540" x1="199.96" y1="286.069" x2="199.96" y2="266.221" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0193FD"/>
                  <stop offset="1" stopColor="#00FDC0"/>
              </linearGradient>
          </defs>
      </svg>
  )
}

export default PrivacyHeadingIcon
