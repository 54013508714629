import React from 'react'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './balance-carousel.css'
import BalanceCircleIcon from '../../Icon/BalanceCircleIcon'
import BonusBalanceCircleIcon from '../../Icon/BonusBalanceCircleIcon'
import BarBalanceCircleIcon from '../../Icon/BarBalanceCircleIcon'

interface CarouselProperties {
  balances?: any
}

const BalanceCarousel: React.FC<CarouselProperties> = ({ balances }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      className="balance-carousel"
      navigation
      pagination={{ clickable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <div className="balance">
          <BalanceCircleIcon />

          <div className="balance-content">
            <div className="balance-title">Your Balance</div>
            <div className="balance-value">{((balances.gel.amount ?? balances.gel))} ₾</div>
            {/* <div className="balance-small">Free Spins : 0₾</div> */}
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="balance">
          <BonusBalanceCircleIcon />

          <div className="balance-content">
            <div className="balance-title">Bonus Balance</div>
            <div className="balance-value">{((balances.point.amount ?? balances.point))} ₾</div>
            {/* <div className="balance-small">Free Spins : 0₾</div> */}
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="balance">
          <BarBalanceCircleIcon />

          <div className="balance-content">
            <div className="balance-title">Bar Bonus</div>
            <div className="balance-value">{((balances.bar_point.amount ?? balances.bar_point))} ₾</div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default BalanceCarousel
